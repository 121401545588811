/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import get from "lodash/get";

import { GiftStoryButton } from "../molecules/gift-story-button";
import { checkIfStoryPublic } from "../../access-type-utils";

import {
  FaFacebookF,
  FaXTwitter,
  FaWhatsapp,
  FaClipboard,
} from "react-icons/fa6";

import "./social-share-template.m.css";

const SocialShareTemplate = (props) => {
  const { member, fetchSubscription } = useSelector((state) => state);
  const story = useSelector((state) =>
    get(state, ["qt", "data", "story"], null)
  );
  // const isStoryPublic = checkIfStoryPublic(props.story);
  const tags = story.sections.map((tag) => tag.name).join(",");
  const hasActiveSubscription = useSelector((state) =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  const pushToDataLayer = (event, platform) => {
    const obj = {
      event: event,
      content_name: story.headline,
      content_creator: story["author-name"],
      content_category: tags,
      platform: platform,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find((sub) => sub.active === true).id
        : "null",
    };

    window.dataLayer.push(obj);
  };

  const CopyText = () => {
    // document.execCommand("copy");
    const textArea = document.createElement("textarea");
    document.body.appendChild(textArea);
    textArea.value = props.fullUrl;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    pushToDataLayer("article_share", "copylink");
    window.alert("Link copied");
  };

  return (
    <ul styleName="social-link-list">
      <li onClick={() => pushToDataLayer("article_share", "facebook")}>
        <a
          href={props.fbUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share this article on facebook"
        >
          <FaFacebookF />
          {/* <FbIcon /> */}
        </a>
      </li>
      <li onClick={() => pushToDataLayer("article_share", "twitter")}>
        <a
          href={props.twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share this article on twitter"
        >
          <FaXTwitter />
          {/* <TwitterIcon /> */}
        </a>
      </li>
      <li onClick={() => pushToDataLayer("article_share", "whatsapp")}>
        <a
          href={props.whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share this article on whatsapp"
        >
          <FaWhatsapp />
          {/* <img src={assetify(whatsapp)} alt="Share this article on whatsapp" /> */}
        </a>
      </li>
      <li>
        <button
          styleName="copy-link-button"
          onClick={() => CopyText()}
          aria-label="click to copy story link and share it"
        >
          <FaClipboard />
          {/* <img
            style={{ cursor: "pointer" }}
            src={`${assetify(Clipboard)}`}
            alt="copy link"
          /> */}
        </button>
      </li>
      {hasActiveSubscription && !checkIfStoryPublic(story) && (
        <li>
          <GiftStoryButton story={story} isSocialShare={true} />
        </li>
      )}
    </ul>
  );
};

SocialShareTemplate.propTypes = {
  fbUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  whatsappUrl: PropTypes.string,
  fullUrl: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    currentHostUrl: get(state, ["qt", "currentHostUrl"], ""),
  };
};

export default connect(mapStateToProps, null)(SocialShareTemplate);
