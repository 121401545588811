import React from "react";
import PropTypes from "prop-types";
import { Link, ResponsiveImage } from "@quintype/components";
import get from "lodash/get";
import { useSelector } from "react-redux";

import "./also-read.m.css";

export const AlsoRead = ({
  element,
  story,
  externalLink = false,
  count,
  setCount
}) => {
  const linkedStories = get(story, ["linked-stories"], {});
  const linkedStoryIds = story["linked-story-ids"];

  const domainSlug = useSelector(state =>
    get(state, ["qt", "domainSlug"], null)
  );
  // const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  // const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);

  if (count === 1) {
    return (
      <React.Fragment>
        <span styleName="also-read-text">
          {!domainSlug ? "Also see" : "और पढ़ें :"}{" "}
        </span>
        <hr
          style={{
            border: "1px solid var(--separator-neutral)",
            margin: "10px 0px 0px 0px"
          }}
        />
        {linkedStoryIds &&
          linkedStoryIds.map(id => {
            return (
              <Link key={id} href={linkedStories[id].slug}>
                <div styleName="also-read-wrapper">
                  <ResponsiveImage
                    slug={linkedStories[id]["hero-image-s3-key"]}
                    metadata={linkedStories[id]["hero-image-metadata"]}
                    aspectRatio={[16, 9]}
                    defaultWidth={160}
                    widths={[120, 160]}
                    imgParams={{ auto: ["format", "compress"] }}
                    sizes="(max-width: 500px) 20%, (max-width: 768px) 35%, 50%"
                    styleName="also-read-left"
                    alt="article image"
                  />
                  <span styleName="also-read-right also-read-link">
                    {linkedStories[id].headline}
                  </span>
                </div>
              </Link>
            );
          })}
      </React.Fragment>
    );
  } else {
    return null;
  }
};

AlsoRead.propTypes = {
  element: PropTypes.shape({
    text: PropTypes.string
  }),
  story: PropTypes.object,
  externalLink: PropTypes.string
};
