/* eslint-disable react/prop-types */
import React from "react";
import "./sub-avatar.m.css";
import assetify from "@quintype/framework/assetify";
import Disruptor from "../../../../assets/images/nl-images/disruptor.png";
import Gamechanger from "../../../../assets/images/nl-images/gamechanger.png";
import { COMPRESS_PARAMS_EXTRA_SMALL } from "../../../image-constants";

export const SubAvatar = ({ groupId }) => {
  return (
    <img
      src={assetify(
        groupId === 114
          ? Disruptor
          : groupId === 805 || 113
          ? Gamechanger
          : `https://images.assettype.com/newslaundry/2020-06/1b7ec40d-85fd-4785-beac-3450b7d8c8c4/soldier.png${COMPRESS_PARAMS_EXTRA_SMALL}`
      )}
      styleName="wrapper"
    />
  );
};
