/* eslint-disable react/prop-types */
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { checkIfStoryPublic } from "../../../access-type-utils";
import { Authors } from "../../atoms/author";
import { Headline } from "../../atoms/headline";
import { HeroImage } from "../../atoms/hero-image";
import { SectionName } from "../../atoms/section-tag";
import { Subheadline } from "../../atoms/subheadline";
// import assetify from "@quintype/framework/assetify";
import { isDesktopSizeViewport } from "../../../utils";
import { ImageCaption } from "../../atoms/image-caption";
import { StoryTags } from "../../atoms/social-tags";
import { CommentWidget } from "../../comment-widget";
import { StoryCard } from "../../molecules/story-card";
// import { RequestSubscription } from "../../pages/subscription-page/request-subscription";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import { socialShareSupport } from "../../molecules/social-share";
import StopPress from "../../molecules/stop-press";
import { SubscriptionCTA } from "../../molecules/subscription-cta";
import { Paywall } from "../../pages/subscription-page/paywall";
// import LoadingIcon from "../../../../assets/images/loader.svg";
import Loader from "../../atoms/loader";
import { ElectionFundAppeal } from "../../atoms/election-fund-appeal";
import { GiftStoryButton } from "../../molecules/gift-story-button";
import "./listicle.m.css";

export const ListicleStoryTemplate = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  let cardIndex = props.story["bullet-type"] === "123" ? 1 : 0;
  const cardsToBeNumbered = get(props, ["story", "cards"], []).filter(
    card => card["card-type"] !== "introduction"
  ).length;

  const BulletType = card => {
    if (card["card-type"] !== "introduction") {
      if (props.story["bullet-type"] === "123") {
        return <div styleName="bullet-ordering">{cardIndex++}.</div>;
      } else if (props.story["bullet-type"] === "bullets") {
        return <div styleName="bullet-type"></div>;
      } else if (props.story["bullet-type"] === "321") {
        return (
          <div styleName="bullet-ordering">
            {cardsToBeNumbered - cardIndex++}.
          </div>
        );
      }
    }
    return null;
  };

  const isStoryPublic = checkIfStoryPublic(props.story);
  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(story, ["cards"], []);

  function getStoryCards(
    cards = [],
    story = {},
    config,
    hasActiveSubscription
  ) {
    return (
      cards &&
      cards.map((card, index) => {
        const getSubscriptionCTA = get(
          card,
          ["metadata", "attributes", "subscriptioncta", 0],
          "No"
        );
        const getNewsletter = get(
          card,
          ["metadata", "attributes", "newsletter", 0],
          null
        );
        if (getSubscriptionCTA === "Yes") {
          return (
            <React.Fragment>
              <div styleName="bullets-wrapper" key={index}>
                <div>{BulletType(card, index)}</div>
                <StoryCard card={card} story={story} config={config} />
              </div>
              <div style={{ position: "relative" }}>
                {/* <SubscriptionAppealCTA isPressFreedom={true} story={story} /> */}
              </div>
              {getNewsletter && (
                <div className="newsletter-grid story-tags">
                  <StopPress planName={getNewsletter} />
                </div>
              )}
              {/* {!hasActiveSubscription ? (
                <div className="story-tags">
                  {" "}
                  <SubscriptionCTA url={story.slug} />{" "}
                </div>
              ) : null} */}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={index}>
            <div styleName="bullets-wrapper" key={index}>
              <div>{BulletType(card, index)}</div>
              <StoryCard
                card={card}
                story={story}
                config={config}
                cardIndex={index}
              />
            </div>
            {getNewsletter && (
              <div className="newsletter-grid story-tags">
                <StopPress planName={getNewsletter} />
              </div>
            )}
          </React.Fragment>
        );
      })
    );
  }

  function showStoryCards(storyCards, story, config, hasActiveSubscription) {
    return (
      <>
        {getStoryCards(storyCards, story, config, hasActiveSubscription)}
        {/* <div style={{ position: "relative" }}>
          <ElectionFundAppeal/>
        </div> */}
        <div className="story-tags">
          <StoryTags story={story} />
        </div>
        {!isStoryPublic && hasActiveSubscription ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "var(--space-2xl)",
              marginBottom: "var(--space-4xl)",
              backgroundColor: "var(--component-background-accent-default)",
              padding: "20px",
              borderRadius: "var(--space-xxs)",
              border: "1px solid var(--border-accent-default)",
              textAlign: "center",
            }}
          >
            <p
              style={{
                lineHeight: "var(--typography-heading-line-height-h4)",
                fontWeight: "var(--typography-font-weight-semibold)",
                letterSpacing: " var(--typography-letter-spacing-2xl)",
                fontSize: "var(--typography-font-size-2xl)",
                color: "inherit",
                marginRight: "var(--space-2xl)",
              }}
            >
              Liked this story? Consider gifting it to a friend.{"  "}
            </p>
            <GiftStoryButton story={story} />
          </div>
        ) : null}
        {/* {!hasActiveSubscription && checkIfStoryPublic(story) && (
          <div className="story-tags">
            {<SubscriptionCTA url={story.slug} />}
          </div>
        )} */}
        <div className="metype-widget">
          <CommentWidget story={story} />
        </div>
      </>
    );
  }

  function getIfNoAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    const noOfVisibleCards = get(config, [
      "layout",
      "no-of-visible-cards-in-a-blocked-story"
    ]);
    console.log(member !== null && hasActiveSubscription === false);
    const eligibleCards = storyCards.slice(0, noOfVisibleCards);
    return (
      <>
        <div>
          {getStoryCards(eligibleCards, story, config, hasActiveSubscription)}
        </div>
        {member !== null && hasActiveSubscription === false ? (
          <div
            style={{
              display: "flex",
              height: "100px",
              flexDirection: "row",
              alignContent: "strech",
              justifyContent: "space-around"
            }}
          >
            <Loader color="#ec2227" />
          </div>
        ) : (
          <Paywall url={story.slug} {...props} />
        )}
      </>
    );
  }

  function getIfHasAccess(storyCards, config, story, hasActiveSubscription) {
    return showStoryCards(storyCards, story, config, hasActiveSubscription);
  }

  function handleAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    if (hasActiveSubscription) {
      return getIfHasAccess(storyCards, config, story, hasActiveSubscription);
    }
    return getIfNoAccess(
      storyCards,
      config,
      story,
      hasActiveSubscription,
      member
    );
  }

  return (
    <div styleName="listicle-wrapper">
      <div styleName="story-header">
        <div styleName="section">
          <SectionName story={story} />
        </div>
        <Headline story={story} isStoryPage />
        <Subheadline story={story} />
        <div>
          {isStoryPublic || hasActiveSubscription ? (
            <AmazonPolly story={story} />
          ) : null}
        </div>
        <div styleName="author">
          <Authors story={story} />
          {!isStoryPublic && hasActiveSubscription ? (
            <GiftStoryButton story={story} />
          ) : null}
        </div>
      </div>
      <div className={`${deviceWidth}`}>
        <HeroImage story={story} />
      </div>
      <ImageCaption story={story} />
      <div styleName="story-content">
        <div styleName="social-share-icons">
          <div styleName="sticky">{socialShareSupport(story)}</div>
        </div>
        <div styleName="story-cards">
          {isStoryPublic
            ? showStoryCards(
                storyCards,
                story,
                props.config,
                hasActiveSubscription
              )
            : handleAccess(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                props.member
              )}
        </div>
      </div>

    </div>
  );
};

ListicleStoryTemplate.propTypes = {
  story: PropTypes.object,
  config: PropTypes.config,
  member: PropTypes.member,
  hasSubscription: PropTypes.bool
};

class ListicleStoryAccessWrapper extends React.Component {
  render() {
    return (
      <div>
        {/* {(this.props.member && this.props.hasSubscription=== null && !checkIfStoryPublic(this.props.story)) && <div styleName="modal">
              <div styleName="modal-content">
                <img src={assetify(LoadingIcon)} alt="loading" />
              </div>
            </div>} */}
        <ListicleStoryTemplate {...this.props} />
      </div>
    );
  }
}

class ListicleStoryBase extends React.Component {
  render() {
    return <ListicleStoryAccessWrapper {...this.props} />;
  }
}

ListicleStoryBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
});

export const Listicle = connect(mapStateToProps)(ListicleStoryBase);
