import React from "react";
import PropTypes from "prop-types";
import { SocialShare } from "@quintype/components";
import SocialShareTemplate from "../../story-templates/social-share-template";
import isEmpty from "lodash/isEmpty";
import { getStoryHeading } from "../../../utils";

export function socialShareSupport(story = {}) {
  const headline = getStoryHeading(story);
  const socialShareMessage = !isEmpty(story.summary) ? story.summary : headline;
  return (
    <>
      <SocialShare
        fullUrl={story.url}
        title={socialShareMessage}
        services={["facebook", "twitter", "whatsapp"]}
        story={story}
        template={SocialShareTemplate}
      />
    </>
  );
}

socialShareSupport.propTypes = {
  story: PropTypes.shape({
    summary: PropTypes.string,
    url: PropTypes.string,
    tags: PropTypes.array,
    headline: PropTypes.string
  })
};
