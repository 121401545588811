import React from "react";
import PropTypes from "prop-types";
// import { ResponsiveImage } from "@quintype/components";
import "./image.m.css";

export const ImageElement = ({ element }) => {
  return (
    <div styleName="image-element">
      <figure>
        <img
          src={`https://gumlet.assettype.com/${element["image-s3-key"]}`}
          alt={element.title|| "" + `imageby :${element["image-attribution"]||""}`}
          loading="eager"
          style={{ height: "100%", width: "100%" }}
        />
        {/* <ResponsiveImage
          slug={element["image-s3-key"]}
          metadata={element.metadata}
          aspect-ratio={[16, 9]}
          defaultWidth={480}
          widths={[250, 480, 640, 800]}
          imgParams={{ auto: ["format", "compress"] }}
          alt={element.title}
        /> */}
      </figure>
      <div styleName="wrapper" aria-hidden="true">
        {element.title && <p styleName="image-title"> {element.title}</p>}
        {element["image-attribution"] && (
          <div styleName="attribution">{element["image-attribution"]}</div>
        )}
      </div>
    </div>
  );
};
ImageElement.propTypes = {
  element: PropTypes.shape({
    "image-s3-key": PropTypes.string,
    metadata: PropTypes.object,
    title: PropTypes.string,
    "image-attribution": PropTypes.string
  })
};
