import React, { Component } from "react";
import { StoryElement, ImageGalleryElement } from "@quintype/components";
import GlideSlider from "../../molecules/glide-slider";
// import get from "lodash/get";
import PropTypes from "prop-types";

import LightBox from "../../molecules/lightbox";
import "./image-gallery.m.css";

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryItemIndex: 0,
      slideshowItems: [],
      showPopup: {},
    };
    this.galleryItems = [];
  }

  togglePopup = (id, isPopupOpen, index = 0) => {
    this.setState({
      showPopup: {
        [id]: isPopupOpen,
      },
      galleryItemIndex: index,
    });
  };

  getElements = (element) => {
    if (element.metadata.type === "slideshow") {
      return element["story-elements"];
    }
    return [];
  };

  getStoryElement = (element, key, story, card) => {
    if (element.metadata.type === "gallery") {
      return (
        <div key={key} styleName="wrapper">
          {element.type === "composite" ? (
            <ImageGalleryElement
              story={story}
              element={element}
              key={element.id}
              imageAspectRatio={[1, 1]}
              onClickHandler={(key) => this.togglePopup(element.id, true, key)}
            />
          ) : (
            <StoryElement story={story} element={element} />
          )}
          <div>
            {this.state.showPopup[element.id] ? (
              <LightBox
                element={element["story-elements"][this.state.galleryItemIndex]}
                closePopup={() => this.togglePopup(element.id, false)}
              />
            ) : null}
          </div>
        </div>
      );
    } else if (element.metadata.type === "slideshow") {
      return (
        <GlideSlider
          story={this.props.story}
          totalItems={this.getElements(element).length}
          slideshowItems={this.getElements(element)}
        />
      );
    }
  };

  render() {
    return (
      <div>
        {this.getStoryElement(
          this.props.element,
          this.props.story,
          this.props.card
        )}
      </div>
    );
  }
}

ImageGallery.propTypes = {
  card: PropTypes.object,
  story: PropTypes.object,
  element: PropTypes.array,
};

export { ImageGallery };
