import React from "react";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import { get } from "lodash";
import {
  COMPRESS_PARAMS_MEDIUM,
  PEOPLE_CARRYING_NL_CLIP_UPHILL
} from "../../image-constants";
import "./register-wall.m.css";

const RegisterWall = () => {
  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const params = parseUrl(currentPath);
  const callbackUrl = get(
    params,
    ["query", "callback-url"],
    global.location && global.location.origin
  );
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    global && global.location && global.location.href
  );
  const authURL = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;

  return (
    <>
      <div styleName="cta">
        <h2 styleName="paywall-title"></h2>
        <br />
      </div>
      <div styleName="wrapper">
        <div styleName={"head-wrapper"}>
          <img
            styleName="cta-image"
            src={`${PEOPLE_CARRYING_NL_CLIP_UPHILL}${COMPRESS_PARAMS_MEDIUM}`}
            alt="paywall image"
          />
        </div>
        <div styleName="body-wrapper">
          <h2 styleName="title">Sign up to read this story for free</h2>
          <p styleName="description">
            Make an account to continue reading this story. For free! We will
            email you a weekly newsletter written by our reporters, linking our
            best stories.
          </p>
          <a
            onClick={() => {
              window.location = authURL;
            }}
            styleName="cta-btn"
          >
            Sign up for free
          </a>
          <p styleName="login-text">
            Already have an account?{" "}
            <a
              onClick={() => {
                window.location = authURL;
              }}
              styleName="login-cta"
            >
              Login
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export { RegisterWall };
