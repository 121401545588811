/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import get from "lodash/get";
// import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { HeroImage } from "../../atoms/hero-image";
import { Authors } from "../../atoms/author";
import { ImageCaption } from "../../atoms/image-caption";
import { isDesktopSizeViewport } from "../../../utils";
// import { SaveButton } from "../../molecules/save-button";
// import assetify from "@quintype/framework/assetify";
import { socialShareSupport } from "../../molecules/social-share";
import {
  checkIfStoryPublic,
  handleAccess,
  showStoryCards
} from "../../../access-type-utils";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { GiftStoryButton } from "../../molecules/gift-story-button";

import "./text-story.m.css";
import { Link } from "@quintype/components";
import { StoryPageReadInAppNudge } from "../utils/read-in-app";

// import LoadingIcon from "../../../../assets/images/loader.svg";
// import Container from "../../atoms/container";

const TextSToryTempate = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  const isStoryPublic = checkIfStoryPublic(props.story);
  // console.log({ isStoryPublic });
  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;

  const storyCards = get(story, ["cards"], []);
  const sections = get(story, ["sections", "0"], {});

  return (
    <div styleName="text-story-wrapper">
      <div styleName="story-header">
        <div styleName="section">
          <Link href={sections["section-url"]}>
            <p styleName="section-badge">
              {sections["display-name"] || sections.name}
            </p>
          </Link>
          {/* <SectionName story={story} /> */}
        </div>
        <Headline story={story} isStoryPage />
        <Subheadline story={story} />
        <div>
          {isStoryPublic || hasActiveSubscription ? (
            <AmazonPolly story={story} />
          ) : null}
        </div>
        <div styleName="authorWithTimeStamp">
          <Authors story={story} />
          {/* <SaveButton story={story} /> */}
          {/* <span style={{ verticalAlign: "middle" }}> */}
          {/* &nbsp;&nbsp;&nbsp; */}
          {/* </span> */}
          {!isStoryPublic && hasActiveSubscription ? (
            <GiftStoryButton story={story} />
          ) : null}
        </div>
      </div>
      <div className={`${deviceWidth}`}>
        <HeroImage story={story} />
      </div>
      <div aria-hidden="true">
        <ImageCaption story={story} />
      </div>
      <div styleName="story-content">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "1rem"
          }}
        >
          <div styleName="social-share-icons">
            <div
              styleName="sticky"
              aria-label="Share this article on social media:"
            >
              {socialShareSupport(story)}
            </div>
          </div>
          <StoryPageReadInAppNudge />
        </div>
        <div styleName="story-cards">
          {isStoryPublic
            ? showStoryCards(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                -1,
                props.member
              )
            : handleAccess(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                null,
                props.member
              )}
        </div>
      </div>
    </div>
  );
};

TextSToryTempate.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  member: PropTypes.object,
  hasSubscription: PropTypes.bool
};
class TextStoryAccessWrapper extends React.Component {
  render() {
    return (
      <div>
        <TextSToryTempate {...this.props} />
      </div>
    );
  }
}

class TextStoryBase extends React.Component {
  render() {
    return <TextStoryAccessWrapper {...this.props} />;
  }
}

TextStoryBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
});

export const TextSTory = connect(mapStateToProps)(TextStoryBase);
TextSTory.propTypes = {
  story: PropTypes.shape({
    card: PropTypes.object
  })
};
