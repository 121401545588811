/* eslint-disable react/prop-types */
import { Link, StoryElement } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { checkIfStoryPublic } from "../../../access-type-utils";
import { isDesktopSizeViewport } from "../../../utils";
import { Authors } from "../../atoms/author";
import { Headline } from "../../atoms/headline";
import { HeroImage } from "../../atoms/hero-image";
import Loader from "../../atoms/loader";
// import { SectionName } from "../../atoms/section-tag";
import { StoryTags } from "../../atoms/social-tags";
import { Subheadline } from "../../atoms/subheadline";
import { CommentWidget } from "../../comment-widget";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import { GiftStoryButton } from "../../molecules/gift-story-button";
// import { SaveButton } from "../../molecules/save-button";
import { socialShareSupport } from "../../molecules/social-share";
import StopPress from "../../molecules/stop-press";
import { StoryCard } from "../../molecules/story-card";
import { SubscriptionCTA } from "../../molecules/subscription-cta";
import { Paywall } from "../../pages/subscription-page/paywall";
// import LoadingIcon from "../../../../assets/images/loader.svg";
import { ElectionFundAppeal } from "../../atoms/election-fund-appeal";

// import { SubscriptionAppealCTA } from "../../atoms/subscription-appeal";
import "./video-story.m.css";
import { StoryPageReadInAppNudge } from "../utils/read-in-app";

const VideoStoryTemplate = (props) => {
  const hasActiveSubscription = useSelector((state) =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);

  const firstVideo =
    (props.story.cards &&
      props.story.cards
        .reduce((acc, element) => {
          acc = acc.concat(element["story-elements"]);
          return acc;
        }, [])
        .find(
          ({ type, subtype }) =>
            type === "youtube-video" ||
            (type === "jsembed" && subtype === "dailymotion-video") ||
            (type === "jsembed" && subtype === "facebook-video") ||
            (type === "jsembed" && subtype === "vimeo-video")
        )) ||
    {};

  const isStoryPublic = checkIfStoryPublic(props.story);
  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(story, ["cards"], []);

  const HeaderVideo = (props) => {
    //console.log({firstVideo});
    return (
      <div styleName="header-videos">
        {/* <StoryElement element={firstVideo} key={firstVideo.id} story={story} /> */}
        <iframe style={{ width: "100%", aspectRatio: "16/9", borderRadius: "4px" }} src={firstVideo['embed-url']}  title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
      </div>
    );
  };
  function getStoryCards(cards = [], story, config, hasActiveSubscription) {
    return cards.map((card, index) => {
      const getSubscriptionCTA = get(
        card,
        ["metadata", "attributes", "subscriptioncta", 0],
        "No"
      );
      const getNewsletter = get(
        card,
        ["metadata", "attributes", "newsletter", 0],
        null
      );
      const getNewsletterStoryCard = () => {
        return getNewsletter ? (
          <div className="newsletter-grid">
            <StoryCard
              key={index}
              card={card}
              story={story}
              config={config}
              cardIndex={index}
              firstVideoElementID={firstVideoId}
            />
            <div style={{ position: "relative" }}>
              {/* <SubscriptionAppealCTA isPressFreedom={true} story={story} /> */}
            </div>
            <div className="story-card">
              <StopPress planName={getNewsletter} />
            </div>
          </div>
        ) : (
          <StoryCard
            key={index}
            card={card}
            story={story}
            config={config}
            cardIndex={index}
            firstVideoElementID={firstVideoId}
          />
        );
      };
      if (getSubscriptionCTA === "Yes") {
        return (
          <React.Fragment>
            {getNewsletterStoryCard()}
            {/* {!hasActiveSubscription ? (
              <div className="story-card">
                <SubscriptionCTA url={story.slug} />{" "}
              </div>
            ) : null}*/}
          </React.Fragment>
        );
      }
      return getNewsletterStoryCard();
    });
  }

  function showStoryCards(storyCards, config, story, hasActiveSubscription) {
    return (
      <>
        {getStoryCards(storyCards, story, config, hasActiveSubscription)}
        {/* <div style={{ position: "relative" }}>
          <ElectionFundAppeal />
        </div> */}
        <div className="story-tags">
          <StoryTags story={story} />
        </div>
        {!isStoryPublic && hasActiveSubscription ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "var(--space-2xl)",
              marginBottom: "var(--space-4xl)",
              backgroundColor: "var(--component-background-accent-default)",
              padding: "20px",
              borderRadius: "var(--space-xxs)",
              border: "1px solid var(--border-accent-default)",
              textAlign: "center",
            }}
          >
            <p
              style={{
                lineHeight: "var(--typography-heading-line-height-h4)",
                fontWeight: "var(--typography-font-weight-semibold)",
                letterSpacing: " var(--typography-letter-spacing-2xl)",
                fontSize: "var(--typography-font-size-2xl)",
                color: "inherit",
                marginRight: "var(--space-2xl)",
              }}
            >
              Liked this story? Consider gifting it to a friend.{"  "}
            </p>
            <GiftStoryButton story={story} />
          </div>
        ) : null}
        {/* {!hasActiveSubscription && checkIfStoryPublic(story) && (
          <div className="story-subscription">
            {<SubscriptionCTA url={story.slug} />}
          </div>
        )} */}
        <div className="metype-widget">
          <CommentWidget story={story} />
        </div>
      </>
    );
  }

  function getIfNoAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    const noOfVisibleCards = get(config, [
      "layout",
      "no-of-visible-cards-in-a-blocked-story",
    ]);
    // console.log(member !== null && hasActiveSubscription === null);
    const eligibleCards = storyCards.slice(0, noOfVisibleCards);
    return (
      <>
        {getStoryCards(eligibleCards, story, config, hasActiveSubscription)}
        {member !== null && hasActiveSubscription === null ? (
          <div
            style={{
              display: "flex",
              height: "100px",
              flexDirection: "row",
              alignContent: "strech",
              justifyContent: "space-around",
            }}
          >
            <Loader color="#ec2227" />
          </div>
        ) : (
          <Paywall url={story.slug} {...props} />
        )}
      </>
    );
  }

  function getIfHasAccess(storyCards, config, story, hasActiveSubscription) {
    return showStoryCards(storyCards, config, story, hasActiveSubscription);
  }

  function handleAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    if (hasActiveSubscription) {
      return getIfHasAccess(storyCards, config, story, hasActiveSubscription);
    }
    return getIfNoAccess(
      storyCards,
      config,
      story,
      hasActiveSubscription,
      member
    );
  }

  const { id: firstVideoId = -1 } = firstVideo;
  const sections = get(story, ["sections", "0"], {});

  return (
    <div styleName="video-story-wrapper">
      <div className={`${deviceWidth}`}>
        {isStoryPublic ? (
          <HeaderVideo story={story} />
        ) : !props.member || !hasActiveSubscription ? (
          <HeroImage story={story} />
        ) : (
          <HeaderVideo story={story} />
        )}
      </div>
      <div styleName="story-heading">
        <div styleName="section">
          <Link href={sections["section-url"]}>
            <p styleName="section-badge">
              {sections["display-name"] || sections.name}
            </p>
          </Link>
          {/* <SectionName story={story} /> */}
        </div>
        <Headline story={story} isStoryPage />
        <Subheadline story={story} />
        <div>
          {isStoryPublic || hasActiveSubscription ? (
            <AmazonPolly story={story} />
          ) : null}
        </div>
        <div
          style={{
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            gridGap: "0.5rem",
            gap: "0.5rem",
          }}
        >
          <Authors story={story} />
          <span style={{ verticalAlign: "middle" }}>
            &nbsp;&nbsp;&nbsp;
            {/* <SaveButton story={story} /> */}
          </span>
          {!isStoryPublic && hasActiveSubscription ? (
            <GiftStoryButton story={story} />
          ) : null}
        </div>
      </div>
      <div styleName="story-content">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "1rem"
          }}
        >
          <div styleName="social-share-icons">
            <div
              styleName="sticky"
              aria-label="Share this article on social media:"
            >
              {socialShareSupport(story)}
            </div>
          </div>
          <StoryPageReadInAppNudge />
        </div>
        <div styleName="story-cards">
          {isStoryPublic
            ? showStoryCards(
                storyCards,
                props.config,
                story,
                hasActiveSubscription
              )
            : handleAccess(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                props.member
              )}
        </div>
      </div>
    </div>
  );
};

VideoStoryTemplate.propTypes = {
  story: PropTypes.shape({
    cards: PropTypes.array,
  }),
  member: PropTypes.object,
  config: PropTypes.object,
  hasSubscription: PropTypes.bool,
};

class VideoStoryAccessWrapper extends React.Component {
  render() {
    return (
      <div>
        <VideoStoryTemplate {...this.props} />
      </div>
    );
  }
}

class VideoStoryBase extends React.Component {
  render() {
    return <VideoStoryAccessWrapper {...this.props} />;
  }
}

VideoStoryBase.propTypes = {
  config: PropTypes.object,
  member: PropTypes.object,
};

const mapStateToProps = (state) => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(
    state,
    ["isSubscribedUser", "userHasSubscription"],
    null
  ),
});

export const VideoStory = connect(mapStateToProps)(VideoStoryBase);
