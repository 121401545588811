import get from "lodash/get";
import React from "react";
import { StoryTags } from "../isomorphic/components/atoms/social-tags";
import { CommentWidget } from "../isomorphic/components/comment-widget";
import { StoryCard } from "../isomorphic/components/molecules/story-card";
// import { SubscriptionCTA } from "../isomorphic/components/molecules/subscription-cta";
// import { RequestSubscription } from "../isomorphic/components/pages/subscription-page/request-subscription";
import StopPress from "../isomorphic/components/molecules/stop-press";
import Loader from "./components/atoms/loader";
// import { SubscriptionAppealCTA } from "./components/atoms/subscription-appeal";
import { Paywall } from "./components/pages/subscription-page/paywall";
import { PhotoPaywall } from "./components/story-templates/paywall-comics/photo-paywall";
// import { SubscriptionAppealCTA } from "./components/atoms/subscription-appeal";
// import { ElectionFundAppeal } from "./components/atoms/election-fund-appeal";
import { GiftStoryButton } from "./components/molecules/gift-story-button";
import { RegisterWall } from "./components/register-wall";

export function checkIfStoryPublic(story) {
  const access = get(story, ["access"], "subscribed");
  const isAGiftStory = story.isAGiftStory;

  return (
    isAGiftStory || access === "public" || access === "login" || access === null
  );
}

// export function getStoryAccessLevel(story) {
//   const access = get(story, ["access"], "public"); // "subscription" | "login" | "public"

//   return access;
// }

function getStoryCards(
  cards = [],
  story,
  config,
  hasSubscription,
  firstSlideShowId
) {
  // console.log({ storycards: cards });
  return cards.map((card, index) => {
    const getSubscriptionCTA = get(
      card,
      ["metadata", "attributes", "subscriptioncta", 0],
      "No"
    );
    const getNewsletter = get(
      card,
      ["metadata", "attributes", "newsletter", 0],
      null
    );
    const getNewsletterStoryCard = () => {
      return getNewsletter ? (
        <div className="newsletter-grid">
          {/* {index === 1 && !hasSubscription ? (
            <div className="story-card subscription-cta">
              <SubscriptionCTA url={story.slug} />
            </div>
          ) : null} */}
          <StoryCard
            key={index}
            card={card}
            story={story}
            config={config}
            cardIndex={index}
            firstVideoElementID={firstSlideShowId}
            hasSubscription={hasSubscription}
          />
          <div className="story-card">
            <StopPress planName={getNewsletter} />
          </div>
        </div>
      ) : (
        <StoryCard
          key={index}
          card={card}
          story={story}
          config={config}
          cardIndex={index}
          firstVideoElementID={firstSlideShowId}
        />
      );
    };
    if (getSubscriptionCTA === "Yes") {
      return (
        <React.Fragment key={index}>
          {getNewsletterStoryCard()}
          {/* {!hasSubscription ? (
            <div className="story-card subscription-cta">
              <SubscriptionCTA url={story.slug} />
            </div>
          ) : null} */}
        </React.Fragment>
      );
    }
    return getNewsletterStoryCard();
  });
}

export function showStoryCards(
  storyCards,
  config,
  story,
  hasSubscription,
  firstSlideShowId,
  member
) {
  const access = get(story, ["access"], "public"); // "subscription" | "login" | "public"

  // console.log({ access, member });

  if (access === "login" && (member === null || member === undefined)) {
    const noOfVisibleCards = get(config, [
      "layout",
      "no-of-visible-cards-in-a-blocked-story"
    ]);
    const eligibleCards = storyCards.slice(0, noOfVisibleCards);

    return (
      <>
        {getStoryCards(
          eligibleCards,
          story,
          config,
          hasSubscription,
          firstSlideShowId
        )}
        <RegisterWall />
      </>
    );
  }

  return (
    <>
      {getStoryCards(
        storyCards,
        story,
        config,
        hasSubscription,
        firstSlideShowId
      )}
      {/* <div style={{ position: "relative" }}>
        <ElectionFundAppeal />
      </div> */}
      <div className="story-tags">
        <StoryTags story={story} />
      </div>
      {!checkIfStoryPublic(story) && hasSubscription ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "var(--space-2xl)",
            marginBottom: "var(--space-4xl)",
            backgroundColor: "var(--component-background-accent-default)",
            padding: "20px",
            borderRadius: "var(--space-xxs)",
            border: "1px solid var(--border-accent-default)",
            textAlign: "center"
          }}
        >
          <p
            style={{
              lineHeight: "var(--typography-heading-line-height-h4)",
              fontWeight: "var(--typography-font-weight-semibold)",
              letterSpacing: " var(--typography-letter-spacing-2xl)",
              fontSize: "var(--typography-font-size-2xl)",
              color: "inherit",
              marginRight: "var(--space-2xl)"
            }}
          >
            Liked this story? Consider gifting it to a friend. {"  "}
          </p>
          <GiftStoryButton story={story} />
        </div>
      ) : null}
      {/* {!hasSubscription && checkIfStoryPublic(story) && (
        <div className="story-subscription">
          {<SubscriptionCTA url={story.slug} />}
        </div>
      )} */}

      <div className="metype-widget">
        <CommentWidget story={story} />
      </div>
    </>
  );
}

function getIfNoAccess(
  storyCards,
  config,
  story,
  hasSubscription,
  firstSlideShowId,
  member
) {
  const noOfVisibleCards = get(config, [
    "layout",
    "no-of-visible-cards-in-a-blocked-story"
  ]);
  const eligibleCards = storyCards.slice(0, noOfVisibleCards);

  // console.log(member !== null && hasSubscription === null);
  // console.log({ hasSubscription });

  return (
    <>
      {getStoryCards(
        eligibleCards,
        story,
        config,
        hasSubscription,
        firstSlideShowId
      )}
      {/* {member === null ? (
        <RegisterWall />
      ) : member !== null && hasSubscription === null ? ( */}
      {member !== null && hasSubscription === null ? (
        <div
          style={{
            display: "flex",
            height: "100px",
            flexDirection: "row",
            alignContent: "strech",
            justifyContent: "space-around"
          }}
        >
          <Loader color="#ec2227" />
        </div>
      ) : (
        <Paywall url={story.slug} />
      )}
      {/* {member !== null && hasSubscription === null ? (
        <div
          style={{
            display: "flex",
            height: "100px",
            flexDirection: "row",
            alignContent: "strech",
            justifyContent: "space-around"
          }}
        >
          <Loader color="#ec2227" />
        </div>
      ) : (
        <Paywall url={story.slug} />
      )} */}
      <div style={{ position: "relative" }}>
        {/* <SubscriptionAppealCTA story={story} /> */}
      </div>
    </>
  );
}

function getIfHasAccess(
  storyCards,
  config,
  story,
  hasSubscription,
  firstSlideShowId
) {
  return showStoryCards(
    storyCards,
    config,
    story,
    hasSubscription,
    firstSlideShowId
  );
}

export function handleAccess(
  storyCards,
  config,
  story,
  hasSubscription,
  firstSlideShowId,
  member
) {
  if (hasSubscription) {
    return getIfHasAccess(
      storyCards,
      config,
      story,
      hasSubscription,
      firstSlideShowId
    );
  }
  return getIfNoAccess(
    storyCards,
    config,
    story,
    hasSubscription,
    firstSlideShowId,
    member
  );
}

const getIfNoAccessPaywallComics = (
  storyCards,
  story,
  hasSubscription,
  member
) => {
  return (
    <>
      {member !== null && hasSubscription === null ? (
        <div
          style={{
            display: "flex",
            height: "100px",
            flexDirection: "row",
            alignContent: "strech",
            justifyContent: "space-around"
          }}
        >
          <Loader color="#ec2227" />
        </div>
      ) : (
        <PhotoPaywall
          story={story}
          member={member}
          storyCards={storyCards}
          hasSubscription={hasSubscription}
        />
      )}
    </>
  );
};

export const handleAccessPaywallComics = (
  storyCards,
  story,
  hasSubscription,
  member
) => {
  return getIfNoAccessPaywallComics(storyCards, story, hasSubscription, member);
};
