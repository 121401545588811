/* eslint-disable react/prop-types */
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { checkIfStoryPublic } from "../../../access-type-utils";
import { Authors } from "../../atoms/author";
import { Headline } from "../../atoms/headline";
import Loader from "../../atoms/loader";
// import { SectionName } from "../../atoms/section-tag";
import { StoryTags } from "../../atoms/social-tags";
import { Subheadline } from "../../atoms/subheadline";
import { CommentWidget } from "../../comment-widget";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import { SaveButton } from "../../molecules/save-button";
import { socialShareSupport } from "../../molecules/social-share";
import StopPress from "../../molecules/stop-press";
import { StoryCard, getJSEmbedElement } from "../../molecules/story-card";
import { SubscriptionCTA } from "../../molecules/subscription-cta";
import { Paywall } from "../../pages/subscription-page/paywall";
// import LoadingIcon from "../../../../assets/images/loader.svg";
import { ElectionFundAppeal } from "../../atoms/election-fund-appeal";

// import { SubscriptionAppealCTA } from "../../atoms/subscription-appeal";
import { GiftStoryButton } from "../../molecules/gift-story-button";
import "./podcast.m.css";
import { Link } from "@quintype/components";
import { Tabs } from "../../../ui/components";
import { StoryPageReadInAppNudge } from "../utils/read-in-app";

const PodcastStoryTemplate = props => {
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const firstPodcast =
    (props.story.cards &&
      props.story.cards
        .reduce((acc, element) => {
          acc = acc.concat(element["story-elements"]);
          return acc;
        }, [])
        .find(
          ({ type, subtype }) => type === "jsembed" || subtype === "jsembed"
        )) ||
    {};

  const isStoryPublic = checkIfStoryPublic(props.story);

  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(story, ["cards"], []);

  // console.log({ storyCards });

  const HeaderPlayer = props => {
    return getJSEmbedElement(isStoryPublic, firstPodcast, firstPodcast.id);
  };

  function getStoryCards(cards = [], story, config, hasActiveSubscription) {
    return cards.map((card, index) => {
      const getSubscriptionCTA = get(
        card,
        ["metadata", "attributes", "subscriptioncta", 0],
        "No"
      );
      const getNewsletter = get(
        card,
        ["metadata", "attributes", "newsletter", 0],
        null
      );
      const getNewsletterStoryCard = () => {
        return getNewsletter ? (
          <div className="newsletter-grid">
            <StoryCard
              key={index}
              card={card}
              story={story}
              config={config}
              cardIndex={index}
              firstVideoElementID={firstPodcastId}
            />
            <div style={{ position: "relative" }}>
              {/* <SubscriptionAppealCTA isPressFreedom={true} story={story} /> */}
            </div>
            <div className="story-card">
              <StopPress planName={getNewsletter} />
            </div>
          </div>
        ) : (
          <StoryCard
            key={index}
            card={card}
            story={story}
            config={config}
            cardIndex={index}
            firstVideoElementID={firstPodcastId}
          />
        );
      };
      if (getSubscriptionCTA === "Yes") {
        return (
          <React.Fragment>
            {getNewsletterStoryCard()}
            {/* {!hasActiveSubscription ? (
              <div className="story-card">
                <SubscriptionCTA url={story.slug} />{" "}
              </div>
            ) : null} */}
          </React.Fragment>
        );
      }
      return getNewsletterStoryCard();
    });
  }

  function showStoryCards(storyCards, config, story, hasActiveSubscription) {
    return (
      <>
        {getStoryCards(storyCards, story, config, hasActiveSubscription)}
        {/* <div style={{ position: "relative" }}>
          <ElectionFundAppeal />
        </div> */}
        <div className="story-tags">
          <StoryTags story={story} />
        </div>
        {!isStoryPublic && hasActiveSubscription ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "var(--space-2xl)",
              marginBottom: "var(--space-4xl)",
              backgroundColor: "var(--component-background-accent-default)",
              padding: "20px",
              borderRadius: "var(--space-xxs)",
              border: "1px solid var(--border-accent-default)",
              textAlign: "center"
            }}
          >
            <p
              style={{
                lineHeight: "var(--typography-heading-line-height-h4)",
                fontWeight: "var(--typography-font-weight-semibold)",
                letterSpacing: " var(--typography-letter-spacing-2xl)",
                fontSize: "var(--typography-font-size-2xl)",
                color: "inherit",
                marginRight: "var(--space-2xl)"
              }}
            >
              Liked this story? Consider gifting it to a friend.{"  "}
            </p>
            <GiftStoryButton story={story} />
          </div>
        ) : null}
        {/* {!hasActiveSubscription && checkIfStoryPublic(story) && (
          <div className="story-subscription">
          {<SubscriptionCTA url={story.slug} />}
          </div>
        )} */}
        <div className="metype-widget">
          <CommentWidget story={story} />
        </div>
      </>
    );
  }

  function getIfNoAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    const noOfVisibleCards = get(config, [
      "layout",
      "no-of-visible-cards-in-a-blocked-story"
    ]);
    // console.log(member !== null && hasActiveSubscription === null);
    const eligibleCards = storyCards.slice(0, noOfVisibleCards);
    return (
      <>
        <div>
          {getStoryCards(eligibleCards, story, config, hasActiveSubscription)}
        </div>
        {member !== null && hasActiveSubscription === null ? (
          <div
            style={{
              display: "flex",
              height: "100px",
              flexDirection: "row",
              alignContent: "strech",
              justifyContent: "space-around"
            }}
          >
            <Loader color="#ec2227" />
          </div>
        ) : (
          <Paywall url={story.slug} {...props} />
        )}
      </>
    );
  }

  function getIfHasAccess(storyCards, config, story, hasActiveSubscription) {
    return showStoryCards(storyCards, config, story, hasActiveSubscription);
  }

  function handleAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    if (hasActiveSubscription) {
      return getIfHasAccess(storyCards, config, story, hasActiveSubscription);
    }
    return getIfNoAccess(
      storyCards,
      config,
      story,
      hasActiveSubscription,
      member
    );
  }

  const { id: firstPodcastId = -1 } = firstPodcast;
  const sections = get(story, ["sections", "0"], {});

  const findPodcastTranscriptCard = () => {
    const transcriptCard = storyCards.find(sc => {
      const metadataAttributes = get(sc, ["metadata", "attributes"], null);

      if (
        metadataAttributes.transcriptcard &&
        metadataAttributes.transcriptcard.includes("yes")
      ) {
        return true;
      }

      return false;
    });

    if (transcriptCard) {
      return {
        restCards: storyCards.filter(
          storyCard => storyCard.id !== transcriptCard.id
        ),
        transcriptCard
      };
    } else {
      return {
        restCards: storyCards,
        transcriptCard: null
      };
    }
  };

  const storyCardData = findPodcastTranscriptCard();

  return (
    <div styleName="podcast-story-wrapper">
      <div styleName="story-heading">
        <HeaderPlayer story={story} />
      </div>
      <div styleName="story-heading">
        <div styleName="section">
          <Link href={sections["section-url"]}>
            <p styleName="section-badge">
              {sections["display-name"] || sections.name}
            </p>
          </Link>
          {/* <SectionName story={story} /> */}
        </div>
        <Headline story={story} isStoryPage />
        <Subheadline story={story} />
        <div>
          {isStoryPublic || hasActiveSubscription ? (
            <AmazonPolly story={story} />
          ) : null}
        </div>
        <div
          style={{
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            gridGap: "0.5rem",
            gap: "0.5rem"
          }}
        >
          <Authors story={story} />
          &nbsp;
          <span style={{ verticalAlign: "middle" }}>
            &nbsp;&nbsp;&nbsp;
            {/* <SaveButton story={story} /> */}
            &nbsp;
          </span>
          {!isStoryPublic && hasActiveSubscription ? (
            <GiftStoryButton story={story} />
          ) : null}
        </div>
      </div>
      <div styleName="story-content">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "1rem"
          }}
        >
          <div styleName="social-share-icons">
            <div
              styleName="sticky"
              aria-label="Share this article on social media:"
            >
              {socialShareSupport(story)}
            </div>
          </div>
          <StoryPageReadInAppNudge />
        </div>
        {storyCardData.transcriptCard ? (
          <div styleName="story-cards">
            <Tabs defaultValue="details">
              <Tabs.List styleName="tabs-list-wrapper">
                <Tabs.Trigger value="details" className="typography-font-base">
                  Story
                </Tabs.Trigger>
                <Tabs.Trigger
                  value="transcripts"
                  className="typography-font-base"
                >
                  Transcript
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content value="details">
                {isStoryPublic
                  ? showStoryCards(
                      storyCardData.restCards,
                      props.config,
                      story,
                      hasActiveSubscription
                    )
                  : handleAccess(
                      storyCardData.restCards,
                      props.config,
                      story,
                      hasActiveSubscription,
                      props.member
                    )}
              </Tabs.Content>
              <Tabs.Content value="transcripts">
                {isStoryPublic
                  ? showStoryCards(
                      [storyCardData.transcriptCard],
                      props.config,
                      story,
                      hasActiveSubscription
                    )
                  : handleAccess(
                      [storyCardData.transcriptCard],
                      props.config,
                      story,
                      hasActiveSubscription,
                      props.member
                    )}
              </Tabs.Content>
            </Tabs>
          </div>
        ) : (
          <div styleName="story-cards">
            {isStoryPublic
              ? showStoryCards(
                  storyCards,
                  props.config,
                  story,
                  hasActiveSubscription
                )
              : handleAccess(
                  storyCards,
                  props.config,
                  story,
                  hasActiveSubscription,
                  props.member
                )}
          </div>
        )}
        {/* TABS */}
      </div>
    </div>
  );
};

PodcastStoryTemplate.propTypes = {
  story: PropTypes.shape({
    cards: PropTypes.array
  }),
  member: PropTypes.object,
  config: PropTypes.object,
  hasSubscription: PropTypes.bool
};

class PodcastStoryBase extends React.Component {
  render() {
    return <PodcastStoryTemplate {...this.props} />;
  }
}

PodcastStoryBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
});

export const Podcast = connect(mapStateToProps)(PodcastStoryBase);
