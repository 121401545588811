import React from "react";
import PropTypes from "prop-types";

export const ClockIcon = ({ width = "20", height = "20" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
      <g fill="#4C4C4C" fillRule="nonzero">
        <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
        <path d="M10.815 5H9v6.543L11.851 14 13 12.519l-2.185-1.883z" />
      </g>
    </svg>
  );
};
ClockIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
