import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useSelector } from "react-redux";
import {
  createBookmark,
  deleteBookmark,
  fetchBookmarkById
} from "../../../cartoon-mango";
import { LoginPopup } from "../popup/login-popup";

import { MdOutlineBookmarkAdd, MdOutlineBookmarkAdded } from "react-icons/md";

import "./save-button.m.css";

export const SaveButton = ({ story }) => {
  const [isSaved, setIsSaved] = useState(0);
  const [showLoginPopup, setShowLoginPoup] = useState(false);
  const storyId = get(story, ["id"], null);
  const storyDetail = story;

  const member = useSelector(state => {
    return get(state, ["member"], null);
  });

  const tags = story.sections.map(tag => tag.name).join(",");

  const fetchSubscription = useSelector(state => state.fetchSubscription);

  const pushToDataLayer = event => {
    const obj = {
      event: event,
      content_name: story.headline,
      content_creator: story["author-name"],
      content_category: tags,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  useEffect(() => {
    fetchBookmarkById(storyId).then(result =>
      setIsSaved(result.data && result.data.is_bookmarked)
    );
  }, []);

  const handleClick = async () => {
    if (!member) {
      setShowLoginPoup(true);
    } else {
      setIsSaved(!isSaved);
      story && !isSaved && pushToDataLayer("article_bookmarked");

      if (story && !isSaved) {
        await createBookmark(storyId, storyDetail);
      }

      if (isSaved) {
        await deleteBookmark(storyId);
      }
      // story && !isSaved && createBookmark(storyId, storyDetail);
      // isSaved && deleteBookmark(storyId);
    }
  };

  return (
    <React.Fragment>
      {/* <i
          className={
            isSaved !== 1 ? "material-icons-outlined" : "material-icons"
          }
          aria-label={isSaved !== 1 ? "bookmark this story" : "bookmark added"}
        >
          {isSaved !== 1 ? "bookmark_add" : "bookmark_added"}
        </i> */}
      <button
        styleName="wrapper"
        onClick={handleClick}
        aria-label={`${
          member
            ? isSaved
              ? "Remove bookmark"
              : "Bookmark this story"
            : "Login to bookmark this story"
        }`}
      >
        {isSaved ? (
          <MdOutlineBookmarkAdded aria-hidden="true" />
        ) : (
          <MdOutlineBookmarkAdd aria-hidden="true" />
        )}
      </button>
      {showLoginPopup && (
        <LoginPopup
          showModal={showLoginPopup}
          setShowModal={setShowLoginPoup}
        />
      )}
    </React.Fragment>
  );
};

SaveButton.propTypes = {
  story: PropTypes.object
};
