/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import format from "date-fns/format";
import { StoryElement } from "@quintype/components";
import { isDesktopSizeViewport } from "../../../utils";
import { checkIfStoryPublic } from "../../../access-type-utils";
import { SectionName } from "../../atoms/section-tag";
import { Subheadline } from "../../atoms/subheadline";
import { HeroImage } from "../../atoms/hero-image";
import { socialShareSupport } from "../../molecules/social-share";
import { StoryCard } from "../../molecules/story-card";
// import { NewsletterInput } from "../../molecules/newsletter-input";
import { Countdown } from "../../molecules/countdown";
import { CommentWidget } from "../../comment-widget";
import { SubscribePopup } from "../../molecules/popup/subscribe-popup";
import assetify from "@quintype/framework/assetify";
import LoadingIcon from "../../../../assets/images/loader.svg";
import "./live-qa.m.css";

export const LiveQA = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  // const [showEmailInput, setShowEmailInput] = useState(true);
  const [isLive, setIsLive] = useState(
    new Date(props.story.metadata["event-date"]) <= new Date()
  );
  const [isCollapsed, setIsCollapsed] = useState(!isDesktopSizeViewport());

  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const member = useSelector(state => {
    return get(state, ["member"], null);
  });

  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, [isLive, hasActiveSubscription]);

  const isStoryPublic = checkIfStoryPublic(props.story);
  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(story, ["cards"], []);
  // const storyAccessLevel = get(story, ["access"], "");

  const firstVideo =
    (props.story.cards &&
      props.story.cards
        .reduce((acc, element) => {
          acc = acc.concat(element["story-elements"]);
          return acc;
        }, [])
        .find(
          ({ type, subtype }) =>
            type === "youtube-video" ||
            (type === "jsembed" && subtype === "dailymotion-video") ||
            (type === "jsembed" && subtype === "facebook-video") ||
            (type === "jsembed" && subtype === "vimeo-video")
        )) ||
    {};

  const { id: firstVideoId = -1 } = firstVideo;

  const HeaderVideo = props => {
    return (
      <div styleName="header-videos">
        <StoryElement element={firstVideo} key={firstVideo.id} story={story} />
      </div>
    );
  };

  const HeroCountdown = props => {
    return (
      <div styleName="hero-countdown-wrapper">
        <div styleName="count-inner">
          <p>Event starts in</p>
          <Countdown story={story} setIsLive={setIsLive} />
        </div>
        <div
          styleName="header-videos"
          style={{ filter: "brightness(60%) blur(6px)" }}
        >
          {" "}
          <HeroImage story={story} />
        </div>
      </div>
    );
  };

  const showStoryCards = (storyCards, config, story, hasActiveSubscription) => {
    return (
      <React.Fragment>
        {storyCards.map((card, index) => {
          return (
            <StoryCard
              key={index}
              card={card}
              story={story}
              config={config}
              firstVideoElementID={firstVideoId}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const eventDate = format(
    new Date(story.metadata["event-date"]),
    "MMMM DD, YYYY"
  );
  const eventTime = format(new Date(story.metadata["event-date"]), "HH:mm");
  return (
    <div styleName="live-qa-wrapper">
      <div styleName="left-side">
        <div className={`${deviceWidth}`}>
          {deviceWidth !== "full-width" && (
            <React.Fragment>
              <br />
              <br />
              <br /> <br />
            </React.Fragment>
          )}
          {isLive ? <HeaderVideo story={story} /> : <HeroCountdown />}

          {!isCollapsed && (
            <div styleName="event-date">
              {!isLive && "Begins at"} {eventTime} IST, {eventDate}
            </div>
          )}
        </div>

        {!isCollapsed && (
          <div styleName="content-wrapper">
            <div styleName="story-heading">
              <div styleName="section">
                <SectionName story={story} />
              </div>

              <h2>{story.headline}</h2>
              <Subheadline story={story} />

              <div styleName="social-share-icons">
                <div styleName="sticky">{socialShareSupport(story)}</div>
              </div>
            </div>

            <div styleName="story-content">
              <div styleName="story-cards">
                {showStoryCards(
                  storyCards,
                  props.config,
                  story,
                  props.hasSubscription
                )}
              </div>
            </div>
          </div>
        )}
        {deviceWidth === "full-width" && (
          <div
            styleName="mobile-collapsed"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? "Show Info" : "Hide Info"}
            <i className="material-icons">
              {isCollapsed ? "expand_more" : "expand_less"}
            </i>
          </div>
        )}
      </div>

      <div styleName="right-side">
        <CommentWidget story={story} />
        {/* {showEmailInput && !isLive && (
          <NewsletterInput
            story={story}
            setShowEmailInput={setShowEmailInput}
          />
        )} */}
        <br />
        <br />
      </div>
      {hasActiveSubscription === null && member && (
        <div styleName="modal">
          <div styleName="modal-content">
            <img src={assetify(LoadingIcon)} alt="loading" />
          </div>
        </div>
      )}
      {(hasActiveSubscription === false || !member) && !isStoryPublic && (
        <SubscribePopup showModal={true} hideCloseIcon={true} />
      )}
    </div>
  );
};

LiveQA.propTypes = {
  story: PropTypes.shape({
    cards: PropTypes.array
  }),
  member: PropTypes.object,
  config: PropTypes.object,
  hasSubscription: PropTypes.bool
};
