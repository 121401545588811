import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { toWords } from 'number-to-words';
import { AiFillGift } from "react-icons/ai";
import {
  getGiftStoryLink,
  sendGiftStoryEmails,
  getUser,
  getGiftStoryCount,
} from "../../helper/api";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "./gift-story-button.m.css";
import assetify from "@quintype/framework/assetify";
import giftArt from "../../../../assets/images/nl-images/nl-sena-page.png";
import { Modal } from "../modal";
import { Button } from "../../atoms/button";
import { InputField } from "../../atoms/input-field";
import { Link } from "@quintype/components";

const initialState = {
  modal: false,
  data: { token: "", numberOfLinksGeneratedTillNow: 0, limit: 0 },
  loading: false,
};

function replaceNumberWithWords(sentence) {
  return sentence.replace(/\d+/g, (match) => {
    return toWords(parseInt(match));
  });
}

export function GiftStoryButton({ story , isSocialShare = false}) {
  //console.log(story);
  const inputRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [emails, setEmails] = useState([]);
  const [rEmail, setREmail] = useState("");
  const [rName, setRName] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [emailHandlerState, setEmailHandlerState] = useState({
    text: "Share the story on email",
    loading: false,
  });
  const [gift, setGift] = useState(initialState);
  const [tab, setTab] = useState(1);

  const verifyCount = async () => {
    console.log({isSocialShare});
    const { member } = await getUser();
    const gifterEmail = member.email;
    try {
      setGift({ ...gift, loading: true });
      const result = await getGiftStoryCount({
        storySlug: story.slug,
        storyId: story.id,
        gifterEmail: gifterEmail,
      });
      // console.log({ result }, "resultttttttt");
      setGift((state) => ({
        ...state,
        data: result,
        loading: false,
        modal: true,
      }));
    } catch (e) {
      console.log(e);
      setGift((state) => ({ ...state, loading: false }));
      setError("Something went wrong.");
    }
  };
  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  const getGiftLink = async () => {
    setEmailHandlerState({ ...emailHandlerState, text: "Checking limit..." });
    const { member } = await getUser();
    const gifterEmail = member.email;
    try {
      setGift({ ...gift, loading: true });
      // console.log(
      //   {
      //     storySlug: story.slug,
      //     storyId: story.id,
      //     gifterEmail: gifterEmail,
      //   },
      //   "hyhyhy"
      // );
      console.log("hi 1");
      if (rEmail.length && isValidEmail(rEmail)) {
        console.log("hi 2");
        const result = await getGiftStoryLink({
          storySlug: story.slug,
          storyId: story.id,
          gifterEmail: gifterEmail,
          gifteeEmail: rEmail,
        });
        console.log("hi 3");
        // console.log({ result }, "resultttttttt");
        setGift((state) => ({
          ...state,
          data: result,
          loading: false,
          modal: true,
        }));
        // if(gift.data.token && gift.data.numberOfLinksGeneratedTillNow <= gift.data.limit){
        if (
          result.token &&
          result.numberOfLinksGeneratedTillNow <= result.limit
        ) {
          console.log("hi 4");
          sendGiftEmails(result.token,msg, member);
          console.log("hi 5");
        }
      } else {
        setError("Please provide a valid email.");
        // throw new Error();
      }
    } catch (e) {
      setGift((state) => ({ ...state, loading: false }));
      setError("Something went wrong.");
    }
  };
  const HandleWhatsapp = async () => {
    const { member } = await getUser();
    const gifterEmail = member.email;
    try {
      setGift({ ...gift, loading: true });
      // console.log(
      //   {
      //     storySlug: story.slug,
      //     storyId: story.id,
      //     gifterEmail: gifterEmail,
      //   },
      //   "hyhyhy"
      // );
      if (rEmail.length && isValidEmail(rEmail)) {
        const result = await getGiftStoryLink({
          storySlug: story.slug,
          storyId: story.id,
          gifterEmail: gifterEmail,
          gifteeEmail: rEmail,
        });
        // console.log({ result }, "resultttttttt");
        setGift((state) => ({
          ...state,
          data: result,
          loading: false,
          modal: true,
        }));
        // if(gift.data.token && gift.data.numberOfLinksGeneratedTillNow <= gift.data.limit){
        if (
          result.token &&
          result.numberOfLinksGeneratedTillNow <= result.limit
        ) {
          const url = storyURL + "?token=" + result.token;
          const text = `Your friend has gifted you a paywall story to read with a message : "${msg}". Read the story here: ${url}`;
          window.open(`https://api.whatsapp.com/send?text=${text}`, "_blank");
        }
      } else {
        setError("Please enter your friend's email to share the story.");
        // throw new Error();
      }
    } catch (e) {
      setGift((state) => ({ ...state, loading: false }));
      setError("Something went wrong.");
    }
  };

  const sendGiftEmails = async (token, message, member) => {
    // console.log("inside sendGiftEmails");
    setEmailHandlerState({ ...emailHandlerState, text: "Sending Email...." });
    try {
      //if (emails.length > 0 && emailHandlerState.text === "Send Gift Email") {
      if (rEmail) {
        console.log("hi 6");
        //const { member } = await getUser();
        const gifterEmail = member.email;
        const authors = story["author-name"];
        const url = storyURL + "?token=" + token;
        // console.log("inside func gift storyyyy");
        // console.log(
        //   { 
        //     gifteeName: rName,
        //     gifterEmail: gifterEmail,
        //     authors: authors,
        //     emails: [rEmail],
        //     storyLink: url,
        //     storyTitle: story.headline,
        //     storySub: story.subheadline,
        //     message : message
        //   },
        //   "sendrid passed data"
        // );
        const { msg } = await sendGiftStoryEmails({
          gifteeName: rName,
          gifterEmail: gifterEmail,
          authors: authors,
          emails: [rEmail],
          storyLink: url,
          storyTitle: story.headline,
          storySub: story.subheadline,
          message: message

        });
        console.log("hi 7", {msg});
        // console.log({ msg });
        if (msg === "ok") {
          setEmailHandlerState({ ...emailHandlerState, text: "Email Sent!" });
        }
      }
    } catch (e) {
      console.log({e});
      setError("Something went wrong.");
    }
  };

  const storyURL = story.url;
  // gift.data.token
  //   ? story.url + "?token=" + gift.data.token
  //   : "";
  const tokenGranted =
    gift.data.numberOfLinksGeneratedTillNow <= gift.data.limit; //gift.data.token;

  return (
    <div>
      {gift.modal ? (
        <Modal
          onBackdropClick={() => {
            setGift(initialState);
            setCopied(false);
            setREmail("");
            setRName("");
            setError("");
            setMsg("");
            setEmailHandlerState({ text: "Send Gift Email", loading: false });
          }}
        >
          {/* {tab === 1 && (
            <div styleName="text-center" style={{ marginTop: "3rem", padding: "0 1rem" }}>
              {tokenGranted ? <h1>Gift link generated</h1> : null}

              <img
                width="300px"
                styleName="gift-story-artwork"
                src={assetify(giftArt)}
                alt="gift story success artwork"
              />
              <div styleName="link-wrapper mt-1 text-center">
                <div style={{ color: "var(--gray)" }}>
                  {tokenGranted
                    ? `You have ${gift.data.limit -
                        gift.data.numberOfLinksGeneratedTillNow} more stories to gift this month.`
                    : "You have no more stories to gift this month."}
                </div>
                {tokenGranted ? (
                  <div styleName="link-wrapper">
                    <h5 styleName="mt-1">Copy the link below and share it!</h5>
                    <div styleName="link-url-input">
                      <input type={"text"} value={storyURL} readOnly ref={inputRef} styleName="mt-1" />
                    </div>
                    <Button
                      onClick={() => {
                        inputRef.current.select();
                        document.execCommand("copy");
                        setCopied(true);
                      }}
                    >
                      {copied ? "Copied" : "Copy link"}
                    </Button>
                    <Button style={{ marginTop: "1rem" }} onClick={() => setTab(2)}>
                      Email link instead
                    </Button>
                  </div>
                ) : null}

                <div styleName="link-wrapper" style={{ marginTop: "1.5rem" }}>
                  <h5>
                    <small>Would you like to gift a subscription instead?</small>
                  </h5>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Link href={`/subscription?tab=gift-subscription`}>
                      <Button>Gift a subscription</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {tab === 1 && (
            <div
              styleName="text-center"
              style={{ marginTop: "3rem", padding: "0 1rem" }}
            >
              {tokenGranted ? <h1>Gift this story</h1> : null}

              <img
                width="300px"
                styleName="gift-story-artwork"
                src={assetify(giftArt)}
                alt="gift story success artwork"
              />
              <div styleName="link-wrapper mt-1 text-center">
                <div style={{ color: "var(--gray)" }}>
                  {tokenGranted
                    ? replaceNumberWithWords(`You have ${
                        gift.data.limit -
                        gift.data.numberOfLinksGeneratedTillNow + 1
                      } more stories to gift this month.`)
                    : "You have no more stories to gift this month."}
                </div>
                {tokenGranted ? (
                  <div styleName="link-wrapper">
                    <h5 styleName="mt-1">Enter Recipient's information</h5>
                    <br />
                    <div styleName="link-url-input">
                      <form styleName="nl-form">
                        <label htmlFor="name" styleName="form-labels">
                          {/* <span> Add your friend's name</span> */}
                          <InputField
                            name="name"
                            type="text"
                            id="name"
                            styleName="input-box"
                            value={rName}
                            onChange={(e) => setRName(e.target.value)}
                            required
                            placeholder="Add your friend's name"
                          />
                        </label>
                        <label htmlFor="email" styleName="form-labels">
                          {/* <span> Add your friend's email</span> */}
                          <InputField
                            name="email"
                            type="email"
                            id="email"
                            styleName="input-box"
                            value={rEmail}
                            onChange={(e) => setREmail(e.target.value)}
                            required
                            placeholder="Add your friend's email"
                          />
                        </label>
                        <label htmlFor="multiline-input" styleName="form-labels">
                          <span>Add a personal message (optional) :</span>
                          {/* <InputField
                            name="name"
                            type="text"
                            id="name"
                            styleName="input-box"
                            value={rName}
                            onChange={(e) => setRName(e.target.value)}
                            required
                            placeholder="Name"
                          /> */}
                          <textarea
                            id="multiline-input"
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                            rows={4} 
                            cols={30}
                            styleName="input-box" 
                          />
                        </label>
                        <br />
                        {/* <ReactMultiEmail
                        placeholder="You can add upto 3 emails"
                        emails={emails}
                        onChange={(em) => {
                          setEmails(em);
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      /> */}
                      </form>
                    </div>

                    <Button onClick={getGiftLink}>
                      {emailHandlerState.text}
                    </Button>
                    <br />
                    <Button onClick={HandleWhatsapp}>
                      Share on whatsapp{" →"}
                    </Button>
                    <br />
                    {error.length ? (
                      <p style={{ color: "red" }}>{error}</p>
                    ) : (
                      <></>
                    )}
                    {/* <Button style={{ marginTop: "1rem" }} onClick={() => setTab(1)}>
                      Copy link instead
                    </Button> */}
                  </div>
                ) : null}

                <div styleName="link-wrapper" style={{ marginTop: "1.5rem" }}>
                  <h5>
                    <small>
                      Would you like to gift a subscription instead?
                    </small>
                  </h5>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Link href={`/subscription?tab=gift-subscription`}>
                      <Button>Gift a subscription</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : null}

      {!isSocialShare ? <div styleName="btn-div">
        {/* <button disabled={gift.loading} styleName="gift-btn" className="gift-btn" onClick={getGiftLink}> */}
        <button
          disabled={gift.loading}
          styleName="gift-btn"
          className="gift-btn"
          onClick={verifyCount}
        >
          <AiFillGift styleName="gift-btn__icon" className="gift-btn__icon" />
          <span styleName="gift-btn__text" className="gift-btn__text">
            {gift.loading ? "Loading..." : "Gift Story"}
          </span>
        </button>
      </div>:
      <button
        disabled={gift.loading}
        // styleName="gift-btn"
        // className="gift-btn"
        style={{
          appearance: "none",
          border: "none",
          outline: "none",
        }}
        onClick={verifyCount}
      >
        <AiFillGift styleName="gift-btn__icon" className="gift-btn__icon"  />
        {/* <span styleName="gift-btn__text" className="gift-btn__text">
          {gift.loading ? "Loading..." : "Gift Story"}
        </span> */}
      </button>
    }
    </div>
  );
}

GiftStoryButton.propTypes = {
  story: PropTypes.object,
  member: PropTypes.object,
  isSocialShare: PropTypes.bool,
};
