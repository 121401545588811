/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { SectionName } from "../../atoms/section-tag";
import { HeroImage } from "../../atoms/hero-image";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { Authors } from "../../atoms/author";
// import assetify from "@quintype/framework/assetify";
import { isDesktopSizeViewport } from "../../../utils";
import { socialShareSupport } from "../../molecules/social-share";
// import LoadingIcon from "../../../../assets/images/loader.svg";
import {
  checkIfStoryPublic,
  showStoryCards,
  handleAccess
} from "../../../access-type-utils";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import { ImageGallery } from "../../atoms/image-gallery";
import "./photo-story.m.css";
import { GiftStoryButton } from "../../molecules/gift-story-button";
import { StoryPageReadInAppNudge } from "../utils/read-in-app";

const PhotoStoryTemplate = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  const isStoryPublic = checkIfStoryPublic(props.story);
  const story = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(story, ["cards"], []);

  const firstSlideShow =
    (story.cards &&
      story.cards
        .reduce((acc, element) => {
          acc = acc.concat(element["story-elements"]);
          return acc;
        }, [])
        .find(({ metadata }) => metadata.type === "slideshow")) ||
    {};

  const HeaderSlideShow = props => {
    return (
      <div>
        <ImageGallery
          element={firstSlideShow}
          key={firstSlideShow.id}
          story={story}
          slideshowItem={firstSlideShow["story-elements"]}
        />
      </div>
    );
  };
  const { id: firstSlideShowId = -1 } = firstSlideShow;
  const getSlideShowElement = get(firstSlideShow, ["story-elements"], []);
  return (
    <div styleName="photo-story-wrapper">
      {(props.member &&
        hasActiveSubscription &&
        getSlideShowElement.length > 0) ||
      (isStoryPublic && getSlideShowElement.length > 0) ? (
        <div className={`${deviceWidth}`}>
          <HeaderSlideShow story={story} firstSlideShow={getSlideShowElement} />
        </div>
      ) : (
        <div styleName="hero-image" className={`${deviceWidth}`}>
          <HeroImage story={story} />
        </div>
      )}
      <div styleName="story-header">
        <div styleName="section">
          <SectionName story={story} />
        </div>
        <div styleName="headline">
          <Headline story={story} isStoryPage />
        </div>
        <div styleName="subheadline">
          <Subheadline story={story} />
        </div>
        <div>
          {isStoryPublic || hasActiveSubscription ? (
            <AmazonPolly story={story} />
          ) : null}
        </div>
        <div styleName="author">
          <Authors story={story} />
          {!isStoryPublic && hasActiveSubscription ? (
            <GiftStoryButton story={story} />
          ) : null}
        </div>
      </div>

      <div styleName="story-content">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "1rem"
          }}
        >
          <div styleName="social-share">
            <div
              styleName="sticky"
              aria-label="Share this article on social media:"
            >
              {socialShareSupport(story)}
            </div>
          </div>
          <StoryPageReadInAppNudge />
        </div>
        <div styleName="story-cards">
          {isStoryPublic
            ? showStoryCards(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                firstSlideShowId
              )
            : handleAccess(
                storyCards,
                props.config,
                story,
                hasActiveSubscription,
                firstSlideShowId,
                props.member
              )}
        </div>
      </div>
    </div>
  );
};

PhotoStoryTemplate.propTypes = {
  story: PropTypes.shape({
    cards: PropTypes.array
  }),
  config: PropTypes.object,
  member: PropTypes.object,
  hasSubscription: PropTypes.bool
};

class PhotoStoryAccessWrapper extends React.Component {
  render() {
    return (
      <div>
        {/* ((this.props.member && this.hasActiveSubscription=== null) && !checkIfStoryPublic(this.props.story) ) && <div styleName="modal">
              <div styleName="modal-content">
                <img src={assetify(LoadingIcon)} alt="loading" />
              </div>
    </div> */}
        <PhotoStoryTemplate {...this.props} />
      </div>
    );
  }
}
class PhotoStoryBase extends React.Component {
  render() {
    return <PhotoStoryAccessWrapper {...this.props} />;
  }
}

PhotoStoryBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
});

export const PhotoStory = connect(mapStateToProps)(PhotoStoryBase);
