import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { useRelatedStories } from "../../atoms/related-stories";
import CommonStoryCard from "../common-story-card";
import { CollectionName } from "../../atoms/collection-name";

import "./related-stories.m.css";

export function RelatedStories({ story }) {
  const relatedStories = useRelatedStories(story);
  const domainSlug = useSelector(state =>
    get(state, ["qt", "domainSlug"], null)
  );
  const collectionName = domainSlug ? "और पढ़ें" : "You may also like";
  const collection = {
    name: collectionName
  };

  return (
    <div className="full-width" styleName="row-container">
      <div className="container" styleName="row-wrapper">
        <CollectionName collection={collection} />
        <div styleName="wrapper">
          {relatedStories.slice(0, 3).map(story => (
            <CommonStoryCard story={story} key={story.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

RelatedStories.propTypes = {
  story: PropTypes.object
};
