import React from "react";
import assetify from "@quintype/framework/assetify";
import { useSelector } from "react-redux";
import get from "lodash/get";
import PropTypes from "prop-types";
import { SUBSCRIPTION_PAGE_URL } from "../../../../isomorphic/constants";
import subscriptionImage from "../../../../assets/images/nl-images/subscription-CTA.png";
// import { Button } from "../../atoms/button";
import { Button } from "../../../ui/components";

import "./subscription-cta.m.css";

const SubscriptionCTA = ({ url = "" }) => {
  const domainSlug = useSelector(state =>
    get(state, ["qt", "domainSlug"], null)
  );
  const { member, fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      subscription_category: title,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  return !domainSlug ? (
    <div styleName="wrapper">
      <div styleName="image-wrapper">
        <img
          styleName="image"
          src={assetify(subscriptionImage)}
          alt="newslaundry logo"
        />
      </div>
      <div styleName="content-wrapper">
        <h4 styleName="headline">Pay to keep news free </h4>
        <div styleName="content">
          Complaining about the media is easy and often justified. But hey, it’s
          the model that’s flawed.
        </div>
        <div styleName="button">
          <a href={SUBSCRIPTION_PAGE_URL + `?story-url=/${url}`}>
            <Button
              variant="accent"
              onMouseDown={() =>
                pushToDataLayer("nl_article", "self_subscription")
              }
            >
              Subscribe
            </Button>
          </a>
          {/* <Button
            className="nl-btn-large"
            href={SUBSCRIPTION_PAGE_URL + `?story-url=/${url}`}
            onMouseDown={() =>
              pushToDataLayer("nl_article", "self_subscription")
            }
          >
            Subscribe
          </Button> */}
        </div>
      </div>
    </div>
  ) : (
    <div styleName="wrapper">
      <div styleName="image-wrapper">
        <img
          styleName="image"
          src={assetify(subscriptionImage)}
          alt="newslaundry logo"
        />
      </div>
      <div styleName="content-wrapper">
        <h4 styleName="headline"> मेरे खर्च पर आज़ाद हैं ख़बरें </h4>
        <div styleName="content">
          मीडिया से शिकायत करना आसान है और अक्सर सही भी होता है, लेकिन दिक्कत
          इसके मॉडल में है जो खामियों से भरा है.
        </div>
        <div styleName="button">
          <Button
            className="nl-btn-large"
            href={SUBSCRIPTION_PAGE_URL + `?story-url=/${url}`}
            onMouseDown={() =>
              pushToDataLayer("nl_article", "self_subscription")
            }
          >
            सब्सक्राइब
          </Button>
        </div>
      </div>
    </div>
  );
};

SubscriptionCTA.propTypes = {
  url: PropTypes.string
};

export { SubscriptionCTA };
