import React from "react";
import {
  ResponsiveHeroImage,
  Link,
  ResponsiveImage
} from "@quintype/components";
import { useSelector } from "react-redux";
import get from "lodash/get";
import PropTypes from "prop-types";

import "./hero-image.m.css";

export const HeroImage = ({ story }) => {
  // const slug = get(story, ["hero-image-s3-key"], "");
  const slug = get(story, ["slug"], "");
  const config = useSelector(state => get(state, ["qt", "config"], null));
  let heroS3Key =
    get(story, ["hero-image-s3-key"]) ||
    get(config, ["publisher", "publisher", "defaulImgS3key"]);
  const storySlug =
    story["story-template"] === "news-elsewhere"
      ? get(story, ["metadata", "reference-url"], story.url)
      : story.url;

  if (
    heroS3Key ===
    "newslaundry/2021-04/1fb52cec-d559-400e-bb08-2752c1497ec3/nl_fallback.jpg"
  ) {
    heroS3Key =
      "newslaundry/2023-01/a0dd0442-c281-416d-be39-b2d6668f846b/newslaundry_2021_09_4445c6d9_7b5b_4f81_8ee6_2230b2efe882_AI____NL_logo.webp";
  }

  const FallbackImage = () => {
    const caption = story["hero-image-caption"];
    return (
      <div styleName="hero-image-wrapper">
        {!slug ? (
          <div className="image">
            <ResponsiveImage
              slug={heroS3Key}
              metadata={story["hero-image-metadata"]}
              aspectRatio={[16, 9]}
              defaultWidth={480}
              widths={[250, 480, 640]}
              // sizes="(max-width: 500px) 35vw, (max-width: 768px) 48vw, 90vw"
              sizes="(max-width: 500px) 35%, (max-width: 768px) 48%, 90%"
              imgParams={{ auto: ["format", "compress"], fit: "max" }}
              alt={caption || "Article image"}
            />
          </div>
        ) : (
          <figure>
            <ResponsiveHeroImage
              story={story}
              aspectRatio={[16, 9]}
              defaultWidth={480}
              widths={[250, 480, 640]}
              // sizes="(max-width: 500px) 35vw, (max-width: 768px) 48vw, 90vw"
              sizes="(max-width: 500px) 35%, (max-width: 768px) 48%, 90%"
              imgParams={{
                auto: ["format", "compress"],
                fit: "max",
                dpr: "1.0"
              }}
              alt={caption || "Article image"}
            />
          </figure>
        )}
      </div>
    );
  };

  return story ? (
    story["story-template"] === "news-elsewhere" ? (
      <a href={storySlug} target="_blank" rel="noopener noreferrer">
        <FallbackImage />
      </a>
    ) : (
      <Link href={storySlug}>
        <FallbackImage />
      </Link>
    )
  ) : null;
};

HeroImage.propTypes = {
  story: PropTypes.object
};
