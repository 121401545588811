import React from "react";
import PropTypes from "prop-types";

export const LiveIcon = ({ width = "17", height = "17" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="7.5" cy="7.5" r="4.342" fill="#FFF" />
        <circle cx="7.5" cy="7.5" r="7.5" stroke="#FFF" />
      </g>
    </svg>
  );
};

LiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
