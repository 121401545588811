import React from "react";
import { Popup } from "..";
import PropTypes from "prop-types";
import LoginImg from ".././../../../../assets/images/nl-images/login-pop-up.png";
import assetify from "@quintype/framework/assetify";
import { Link } from "@quintype/components";

import "./login-popup.m.css";
import { getAuthUrl } from "../../../helper/hooks";

export const LoginPopup = ({ showModal, setShowModal }) => {
  const url = getAuthUrl();
  return (
    <Popup showModal={showModal} setShowModal={setShowModal}>
      <div styleName="login-content-wrapper">
        <img src={assetify(LoginImg)} alt="birubala using website" />
        <h2>Oops! We need you to login to access this feature</h2>
        <p>Sign in now for a seamless experience</p>
        <Link styleName="redirect-link" href={url}>
          Click to login
          {/* <button>Login</button> */}
        </Link>
      </div>
    </Popup>
  );
};

LoginPopup.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};
