import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import "./quote.m.css";

export const QuoteElement = ({ element }) => {
  const quoteElement = get(element, ["metadata", "content"], "");
  const quoteAttribution = get(element, ["metadata", "attribution"], "");
  return (
    <div styleName="quote-wrapper">
      <figure>
      <blockquote>
      <q styleName="text" dangerouslySetInnerHTML={{ __html: quoteElement }} />
      </blockquote>
      <figcaption>
      <p styleName="attribution">{quoteAttribution}</p>
      </figcaption>
      </figure>
    </div>
  );
};
QuoteElement.propTypes = {
  element: PropTypes.shape({
    quoteElement: PropTypes.string,
    quoteAttribution: PropTypes.string
  })
};