import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import get from "lodash/get";
import "./story-tags.m.css";

export const StoryTags = ({ story }) => {
  const tags = get(story, ["tags"], []);
  if(tags.length < 1) {
    return null;
  }
  return (
    <React.Fragment>
      <h4 styleName="name">TAGS</h4>
    <ul styleName="social-tags-wrapper">
      {tags.map(tag => (
        <li>
        <Link key={tag.id} styleName="tag-items" href={`/topic/${tag.slug}`}>
          {tag.name}
        </Link>
        </li>
      ))}
    </ul>
    </React.Fragment>
  );
};
StoryTags.propTypes = {
  story: PropTypes.shape({
    slug: PropTypes.string
  })
};