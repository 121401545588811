/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { dateFormatterWithoutTime } from "../../../utils";
import TimeAgo from "react-timeago";
import "./author.m.css";

export const Author = ({ story }) => {
  const { name: authorName, slug } = get(story, ["authors", "0"], "");
  return (
    <div
      styleName="authorWithTimeStamp"
      className="authorWithTimeStamp"
      aria-label={`Written by: ${authorName || story["author-name"]} on  `}
    >
      <span styleName="authored">By</span>
      {
        <Link href={`/author/${slug}`}>
          <span styleName="author-name">
            {authorName || story["author-name"]}
          </span>
        </Link>
      }
      <span styleName="date-label">Date:</span>
      <TimeAgo
        styleName="time-ago"
        formatter={dateFormatterWithoutTime}
        date={story["last-published-at"]}
        live={false}
      />
    </div>
  );
};

export const Authors = ({ story }) => {
  function formatDateToEnglishStyle(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
      hour12: true // Use 12-hour clock format
      // timeZoneName: 'short',
    };

    return date.toLocaleString("en-US", options);
  }
  const time = story["last-published-at"];
  const dateObject = new Date(time);
  const formattedDate = formatDateToEnglishStyle(dateObject);
  const getAuthors = get(story, ["authors"], []);
  return (
    <div styleName="authorWithTimeStamp" className="authorWithTimeStamp">
      <span style={{ display: "none" }} aria-label="written">
        Written
      </span>
      <span styleName="authored">By:</span>
      {getAuthors.map((item, index) => {
        return (
          <Link href={`/author/${item.slug}`} key={index}>
            <span
              styleName="author-name"
              aria-label={`${(index ? "& " : "") + item.name.trim()}`}
            >
              {(index ? "& " : "") + item.name.trim()}
            </span>
          </Link>
        );
      })}
      <div aria-label={`Published at: ${formattedDate}`}>
        <span styleName="date-label">Date:</span>
        <TimeAgo
          styleName="time-ago"
          date={story["last-published-at"]}
          formatter={dateFormatterWithoutTime}
          live={false}
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

Author.propTypes = {
  story: PropTypes.object.isRequired
};
