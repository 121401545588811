import React, { useState } from "react";
import "./countdown.m.css";

export const Countdown = ({ story, setIsLive }) => {
  const [day, setDay] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [second, setSecond] = useState(null);

  const countDownDate = new Date(story.metadata["event-date"]).getTime();

  setInterval(function() {
    // Get today's date and time in local timezone
    var d = new Date();
    var localTime = d.getTime();
    var localOffset = d.getTimezoneOffset() * 60000;
    var utc = localTime + localOffset;
    const now = utc + 3600000 * 5.5; // 5.5 is the offset for bombay

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    setDay(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHour(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinute(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSecond(Math.floor((distance % (1000 * 60)) / 1000));
  });

  if (day >= 0 && hour >= 0 && minute >= 0) {
    return (
      <div styleName="countdown-wrapper">
        {day > 0 && (
          <span styleName="count-block">
            <b>{day}</b> Days
          </span>
        )}
        &nbsp;
        {hour > 0 && (
          <span styleName="count-block">
            <b>{hour}</b> Hours
          </span>
        )}
        &nbsp;
        {minute >= 0 && (
          <span styleName="count-block">
            <b>{minute}</b> Minutes
          </span>
        )}
        &nbsp;
        {second >= 0 && (
          <span styleName="count-block">
            <b>{second}</b> Seconds
          </span>
        )}
        &nbsp;
      </div>
    );
  } else {
    setIsLive(true);
    return null;
  }
};
