/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { Link } from "@quintype/components";
import { dateFormatterWithoutTime } from "../../../utils";
// import { SaveButton } from "../../molecules/save-button";
import { socialShareSupport } from "../../molecules/social-share";
import {
  checkIfStoryPublic,
  handleAccessPaywallComics
} from "../../../access-type-utils";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import { GiftStoryButton } from "../../molecules/gift-story-button";

import "./paywall-comics.m.css";
import ReactTimeago from "react-timeago";
// import LoadingIcon from "../../../../assets/images/loader.svg";

const PaywallComicsTemplate = props => {
  // const [deviceWidth, deviceWidthHandler] = useState("");

  // useEffect(() => {
  //   deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  // }, []);

  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  const member = useSelector(state => get(state, ["member"], null));

  const isStoryPublic = checkIfStoryPublic(props.story);

  const story = props.story;

  const storyCards = get(story, ["cards"], []);

  const { name: authorName, slug } = get(story, ["authors", "0"], "");

  return (
    <div styleName="text-story-wrapper">
      <div styleName="story-header">
        <div styleName="section">
          <SectionName story={story} />
        </div>
        <Headline story={story} isStoryPage />
        <Subheadline story={story} />
        {isStoryPublic || hasActiveSubscription ? (
          <AmazonPolly story={story} />
        ) : null}
        <div styleName="author-social-share-wrapper">
          <div styleName="author-date-wrapper">
            <div styleName="author-name-wrapper">
              <p>By</p>
              <Link href={`/author/${slug}`}>
                {authorName || story["author-name"]}
              </Link>
            </div>
            <div styleName="story-date-wrapper">
              <p>Published on</p>
              <p>
                <ReactTimeago
                  formatter={dateFormatterWithoutTime}
                  date={story["last-published-at"]}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div styleName="story-content">
        <div styleName="story-cards">
          {handleAccessPaywallComics(
            storyCards,
            story,
            hasActiveSubscription,
            member
          )}
        </div>
      </div>
      <div
        styleName={`author-social-share-wrapper ${
          !isStoryPublic && hasActiveSubscription ? "" : "x-center"
        }`}
      >
        {!isStoryPublic && hasActiveSubscription ? (
          <div styleName="authorWithTimeStamp">
            <GiftStoryButton story={story} />
          </div>
        ) : null}
        <div styleName="social-share-icons">
          <div styleName="sticky">{socialShareSupport(story)}</div>
        </div>
      </div>
    </div>
  );
};

export { PaywallComicsTemplate };

PaywallComicsTemplate.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  member: PropTypes.object,
  hasSubscription: PropTypes.bool
};

// const mapStateToProps = state => ({
//   config: get(state, ["qt", "config"], {}),
//   member: get(state, ["member"], null),
//   hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
// });
