/* eslint-disable react/prop-types */
import { StoryElement } from "@quintype/components";
import atob from "atob";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { AlsoRead } from "../../atoms/also-read";
import { BigFact } from "../../atoms/big-fact";
import { BlockQuote } from "../../atoms/block-quote";
import { Blurb } from "../../atoms/blurb";
import { ImageElement } from "../../atoms/image";
import { ImageGallery } from "../../atoms/image-gallery";
import { QuestionAnswer } from "../../atoms/question-answer";
import { QuoteElement } from "../../atoms/quote";
// import { SubscriptionAppealCTA } from "../../atoms/subscription-appeal";
import { SubscriptionAppealBottomSheet } from "../../atoms/subscription-appeal-bottom-sheet";
import { Summary } from "../../atoms/summary";
import { VideoPlaylist } from "../../collection-templates/video-playlist";
import { EpisodePlayButton } from "../../molecules/episode-play-button";

import "./style.m.css";
// import { SubscriptionCTA } from "../subscription-cta";
// import { SubscribeNowTemplate } from "../../collection-templates/subscribe-now";

export const getJSEmbedElement = (
  isStoryPublic,
  element,
  index,
  thumbnail = null
) => {
  const elementDecodedString = atob(element["embed-js"]);

  // const [showAVPlayer, setShowAVPlayer] = useState(false);

  // Iframe has episode_id
  if (elementDecodedString.indexOf("episode_id") > -1) {
    const regex = /<iframe.*?src="(.*?)".*?<\/iframe>/;
    // https://player.acast.com/5ec3d9497cef7479d2ef4798/episodes/nl-hafta-ep282?episode_id=5ef7205983254875562d555f
    const match = regex.exec(elementDecodedString);
    const iframeSrc = match[1];

    const showId = iframeSrc.substring(
      iframeSrc.lastIndexOf("com/") + 4,
      iframeSrc.indexOf("/episode")
    );
    const episodeId = iframeSrc.substring(
      iframeSrc.lastIndexOf("episode_id=") + 11,
      iframeSrc.length
    );

    const obj = {
      show_id: showId,
      episode_id: episodeId
    };

    return (
      <div styleName="podcast-embed">
        <EpisodePlayButton data={obj} isStoryPublic={isStoryPublic} />
        {/* <StoryElement element={element} key={index}></StoryElement> */}
      </div>
    );

    // if (showAVPlayer) {
    //   return (
    //     <div styleName="podcast-embed">
    //       <EpisodePlayButton data={obj} isStoryPublic={isStoryPublic} />
    //       {/* <StoryElement element={element} key={index}></StoryElement> */}
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div styleName="load-player-wrapper">
    //       <button
    //         styleName="load-player-button"
    //         onClick={() => setShowAVPlayer(true)}
    //       >
    //         Load Audio/Video player
    //       </button>
    //     </div>
    //   );
    // }
  }

  if (elementDecodedString.indexOf("embed.acast.com") > -1) {
    const regex = /<iframe.*?src="(.*?)".*?<\/iframe>/;
    const match = regex.exec(elementDecodedString);
    const iframeSrc = match[1].split("/");
    const showId = iframeSrc[3];
    const episodeId = iframeSrc[4] && iframeSrc[4].split("?")[0];

    const obj = {
      show_id: showId,
      episode_id: episodeId
    };

    if (episodeId) {
      return (
        <div styleName="podcast-embed">
          <EpisodePlayButton data={obj} isStoryPublic={isStoryPublic} />
          {/* <StoryElement element={element} key={index}></StoryElement> */}
        </div>
      );
    } else {
      return (
        <div styleName="podcast-embed">
          <StoryElement element={element} key={index}></StoryElement>
        </div>
      );
    }

    // if (showAVPlayer) {
    //   if (episodeId) {
    //     return (
    //       <div styleName="podcast-embed">
    //         <EpisodePlayButton data={obj} isStoryPublic={isStoryPublic} />
    //         {/* <StoryElement element={element} key={index}></StoryElement> */}
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div styleName="podcast-embed">
    //         <StoryElement element={element} key={index}></StoryElement>
    //       </div>
    //     );
    //   }
    // } else {
    //   return (
    //     <div styleName="load-player-wrapper">
    //       <button
    //         styleName="load-player-button"
    //         onClick={() => setShowAVPlayer(true)}
    //       >
    //         Load Audio/Video player
    //       </button>
    //     </div>
    //   );
    // }
  }

  if (elementDecodedString.includes("api.gumlet.com")) {
    const regex = /<iframe.*?src="(.*?)".*?<\/iframe>/;
    const match = regex.exec(elementDecodedString);

    const iframeSrc = match[1];

    const src = thumbnail
      ? `${iframeSrc}&thumbnail=${thumbnail}`
      : `${iframeSrc}`;

    return (
      <div style={{ width: "100%" }}>
        <iframe
          style={{ width: "100%", aspectRatio: "16/9", borderRadius: "4px" }}
          src={src}
          title="Gumlet video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  }

  if (elementDecodedString.includes("trinitymedia.ai")) {
    return (
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <StoryElement element={element} key={index}></StoryElement>
      </div>
    );
  }

  return (
    <div styleName="podcast-embed">
      <StoryElement element={element} key={index}></StoryElement>
    </div>
  );
};

const renderVideoPlaylist = videoId => {
  return (
    <React.Fragment>
      <VideoPlaylist collection={null} youtubePlaylistId={videoId} />
    </React.Fragment>
  );
};

function StoryElements(props) {
  const parentCardIndex = get(props, ["parentCardIndex"], -1);

  const isHindi = !!(global.location && global.location.host.includes("hindi"));

  const story = get(props, ["story"], {});
  const access = get(story, ["access"], "subscribed");
  const isAGiftStory = story.isAGiftStory;

  const isStoryPublic = isAGiftStory || access === "public" || access === null;
  const subAppeal = get(props, ["subAppeal"], "independent media");

  const subscriptionStatus = useSelector(state => state.fetchSubscription);
  const isActive = get(subscriptionStatus, ["isActive"], false);

  let count = 0;
  let video = null;

  if (
    props.card &&
    props.card.metadata.attributes &&
    props.card.metadata.attributes.youtubeplaylist
  ) {
    video = props.card.metadata.attributes.youtubeplaylist[0];
  }

  function getElement(element, index, card, thumbnail) {
    // console.log({ index, subAppeal });
    const subType = element.subtype || element.type;
    if (subType === "also-read") {
      count++;
    }

    switch (subType) {
      case "summary":
        return <Summary element={element} key={index} />;
      case "jsembed":
        return getJSEmbedElement(true, element, index, thumbnail);
      case "quote":
        return <QuoteElement element={element} key={index} />;
      case "blockquote":
        return <BlockQuote element={element} key={index} />;
      case "blurb":
        return <Blurb element={element} key={index} />;
      case "bigfact":
        return <BigFact element={element} key={index} />;
      case "also-read":
        return (
          <AlsoRead story={story} element={element} key={index} count={count} />
        );
      case "question":
        return <QuestionAnswer element={element} key={index} type="question" />;
      case "answer":
        return <QuestionAnswer element={element} key={index} type="answer" />;
      case "q-and-a":
        return <QuestionAnswer element={element} key={index} type="q-and-a" />;
      case "image":
        return <ImageElement element={element} key={index} />;
      case "cta":
        return (
          <div styleName="sub-appeal-div">
            {/* {!isActive && (
              <SubscriptionAppealCTA
                element={element}
                key={index}
                isHindi={isHindi}
                story={story}
              />
            )} */}
            {!isActive && (
              <SubscriptionAppealBottomSheet
                element={element}
                key={index}
                isHindi={isHindi}
                story={story}
              />
            )}
          </div>
        );
      case "image-gallery":
        return (
          <ImageGallery
            element={element}
            key={index}
            story={story}
            card={card}
          />
        );
      default:
        if (video) {
          return (
            <>
              <StoryElement element={element} key={index} />
              {renderVideoPlaylist(video)}
            </>
          );
          // } else if (subAppeal && index === 0) {
        } else if (subAppeal) {
          return (
            <>
              <StoryElement element={element} key={index} />
            </>
          );
        }

        return <StoryElement element={element} key={index}></StoryElement>;
    }
  }

  let elements;

  if (props.isGumletVideoCard) {
    elements =
      props.card &&
      props.card["story-elements"].filter(
        element => element.type === "jsembed"
      );
  } else {
    elements =
      props.card &&
      props.card["story-elements"].filter(
        element => element.id !== props.firstVideoElementID
      );
  }

  const toShowCTA = parentCardIndex === 0 && !isActive;

  // console.log({isHindi},{isActive}, {isStoryPublic});

  return (
    <div
      className="story-card"
      styleName={`story-card ${props.isGumletVideoCard ? "span-full" : ""}`}
    >
      {elements.map((element, index) => {
        return (
          <>
            {getElement(element, index, props.card, props.thumbnailURL)}
            {index === 0 && toShowCTA && isStoryPublic && (
              <div styleName="sub-appeal-div">
                {/* <SubscriptionAppealCTA
                  element={element}
                  key={index}
                  subAppeal={subAppeal}
                  isHindi={isHindi}
                  story={story}
                /> */}
                <SubscriptionAppealBottomSheet
                  element={element}
                  key={index}
                  subAppeal={subAppeal}
                  isHindi={isHindi}
                  story={story}
                />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}

StoryElements.propTypes = {
  card: PropTypes.shape({
    "story-elements": PropTypes.array
  }),
  firstVideoElementID: PropTypes.string
};

export const StoryCard = props => {
  const story = get(props, ["story"], {});
  let subAppeal = "";
  const cardIndex = get(props, ["cardIndex"], -1);
  // console.log({ cardIndex });

  story.cards.forEach(card => {
    if (
      card &&
      card.metadata.attributes &&
      card.metadata.attributes.subscriptionappeal
    ) {
      // console.log({ card });
      if (card.metadata.attributes.subscriptionappeal[0]) {
        subAppeal = card.metadata.attributes.subscriptionappeal[0]
          .trim()
          .toLowerCase();
      }

      // console.log(
      //   card.metadata.attributes.subscriptionappeal[0].trim().toLowerCase()
      // );
    }
  });

  return (
    <React.Fragment>
      <StoryElements
        {...props}
        subAppeal={subAppeal}
        parentCardIndex={cardIndex}
      />
    </React.Fragment>
  );
};
