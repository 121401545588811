/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars, no-console, react/jsx-indent-props */
import { WithPreview } from "@quintype/components";
import get from "lodash/get";
import { any, number, object, shape } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { checkIfStoryPublic } from "../../access-type-utils";
import { storyFields } from "../../constants";
import { useVerifyGiftStoryToken } from "../atoms/gift-story/useVerifyGiftStoryToken";
import { HindiLoginBanner } from "../hindi-login-banner";
import { RelatedStories } from "../molecules/related-stories";
import { GumletVideo } from "../story-templates/gumlet-video";
import { Listicle } from "../story-templates/listicle";
import { LiveBlog } from "../story-templates/live-blog/live-blog";
import { LiveQA } from "../story-templates/live-qa";
import { PaywallComicsTemplate } from "../story-templates/paywall-comics";
import { PhotoStory } from "../story-templates/photo-story";
import { Podcast } from "../story-templates/podcast";
import { TextSTory } from "../story-templates/text-story/text-story";
import { VideoStory } from "../story-templates/video-story";
import { fetchDataFromLocalStorage } from "./subscription-checkout/utils";
import { GiftedStoryModal } from "../modals";
import queryString from "query-string";
import { NotFoundPage } from "./not-found";

const blackListedSlugs = [
  "2023/05/19/in-70-days-at-rs-75-lakh-the-curious-case-of-a-9-acre-plot-sold-to-former-khattar-aides-family",
  "2023/05/19/haryana-chief-minister-manoharlal-khattar-principal-osd-neeraj-daftuar-land-scam"
];

function StoryPageBase({ story, params, setLiveQA }) {
  // console.log({ story });
  const { fetchSubscription, member } = useSelector(state => state);
  const [hasUserGivenEmail, setHasUserGivenEmail] = useState(null);
  const [toShowHindiLoginBanner, setToShowHindiLoginBanner] = useState(false);
  const [isHindi, setIsHindi] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isStoryPublic = checkIfStoryPublic(story);
  // console.log({ isStoryPublic });

  const urlParams = ""; //  queryString.parse(window.location.search);
  const isGiftTokenValid = useVerifyGiftStoryToken(story);
  const memoizedStory = useMemo(
    () => ({ ...story, isAGiftStory: isGiftTokenValid }),
    [isGiftTokenValid]
  );

  const storyCopy = Object.assign({}, memoizedStory);
  const getFirstStoryCard = {
    cards: [memoizedStory && memoizedStory.cards && memoizedStory.cards[0]]
  };
  const newStory = Object.assign(storyCopy, getFirstStoryCard);

  const cumulativeContentCategory = story.sections
    .map(sec => sec.name)
    .join(",");

  const pushToDataLayer = () => {
    const obj = {
      event: "article_viewed",
      content_name: story.headline,
      content_category: story.sections[0].name,
      cumulative_content_category: cumulativeContentCategory || "",
      content_creator: story["author-name"],
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  useEffect(() => {
    // console.log(global.location)
    if (global.location.search.includes("token") && !member) {
      console.log("setting true");
      setShowModal(true);
    }
    if (isGiftTokenValid && member) {
      setShowModal(false);
    }
  }, [isGiftTokenValid, member]);

  useEffect(() => {
    const x = document.querySelectorAll(".story-element-text a");
    [...x].forEach(x => x.setAttribute("target", "_blank"));

    const userEmail = fetchDataFromLocalStorage("hasUserGivenEmail");

    // if (userEmail) {
    //   setHasUserGivenEmail(userEmail);
    // } else {
    //   setToShowHindiLoginBanner(true);
    // }

    const hindiCheck = !!(
      global.location && global.location.host.includes("hindi")
    );
    setIsHindi(hindiCheck);

    pushToDataLayer();
  }, []);

  // const defaultFallbackImageURL =
  //   "https://images.assettype.com/newslaundry/2023-01/6cd716aa-303c-4a80-af64-2ccff58edc2a/default_fallback.jpeg";

  const showHindiBanner =
    isHindi && toShowHindiLoginBanner && !hasUserGivenEmail && isStoryPublic;

  if (blackListedSlugs.includes(story.slug)) {
    return <NotFoundPage />;
  }

  // Can switch to a different template based story-template, or only show a spoiler if index > 0
  switch (memoizedStory["story-template"]) {
    case "live-blog":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <LiveBlog
              story={memoizedStory}
              cardId={get(params, ["cardId"])}
              newStory={newStory}
            />{" "}
          </div>
        </>
      );
    case "live-qa":
      setLiveQA(true);
      return (
        <div>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          {showHindiBanner && (
            <HindiLoginBanner
              setToShowHindiLoginBanner={setToShowHindiLoginBanner}
            />
          )}
          <LiveQA story={memoizedStory} newStory={newStory} />
        </div>
      );
    case "listicle":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <Listicle story={memoizedStory} newStory={newStory} />{" "}
          </div>
        </>
      );
    case "video":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <VideoStory story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
    case "gumlet-video":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <GumletVideo story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
    case "podcast":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <Podcast story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
    case "photo":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <PhotoStory story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
    case "paywall-comics":
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <PaywallComicsTemplate story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
    default:
      return (
        <>
          <GiftedStoryModal
            toShowModal={showModal}
            setToShowModal={setShowModal}
          />
          <div className="full-width-with-padding separator">
            {showHindiBanner && (
              <HindiLoginBanner
                setToShowHindiLoginBanner={setToShowHindiLoginBanner}
              />
            )}
            <TextSTory story={memoizedStory} newStory={newStory} />
          </div>
        </>
      );
  }
}

StoryPageBase.propTypes = {
  index: number,
  story: object,
  otherProp: any,
  params: object
};

function storyPageLoadItems(pageNumber) {
  return global
    .wretch("/api/v1/stories")
    .query({
      fields: storyFields,
      limit: 5,
      offset: 5 * pageNumber
    })
    .get()
    .json(response =>
      response.stories.map(story => ({ story, otherProp: "value" }))
    );
}

export function StoryPage(props) {
  const [liveQA, setLiveQA] = React.useState(false);

  if (blackListedSlugs.includes(props.data.story.slug)) {
    return (
      <StoryPageBase {...props.data} setLiveQA={setLiveQA}></StoryPageBase>
    );
  }

  return (
    <>
      <StoryPageBase {...props.data} setLiveQA={setLiveQA}></StoryPageBase>
      {!liveQA ? <RelatedStories story={props.data.story} /> : null}
    </>
  );
}

StoryPage.propTypes = {
  data: shape({
    story: object
  })
};

export const StoryPagePreview = WithPreview(StoryPage, (data, story) =>
  Object.assign({}, data, {
    story,
    relatedStories: Array(5).fill(story)
  })
);
