import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./q-a.m.css";

function Question({ element }) {
  const questionElement = get(element, ["text"], "");
  return (
    <div styleName="question-wrapper">
      <div styleName="question-mark">?</div>
      <div
        styleName="question-text"
        dangerouslySetInnerHTML={{ __html: questionElement }}
      ></div>
    </div>
  );
}

Question.propTypes = {
  element: PropTypes.object
};

function Answer({ element }) {
  const answerElement = get(element, ["text"], "");
  return (
    <div
      styleName="answer-wrapper"
      dangerouslySetInnerHTML={{ __html: answerElement }}
    ></div>
  );
}

Answer.propTypes = {
  element: PropTypes.object
};

function QuestionAnswerElement({ element }) {
  const { question: questions, answer: answers } = get(
    element,
    ["metadata"],
    ""
  );
  return (
    <div styleName="wrapper">
      <div styleName="question">
        <div styleName="question-mark">?</div>
        <div
          styleName="question-element"
          dangerouslySetInnerHTML={{ __html: questions }}
        />
      </div>
      <div
        styleName="answer-element"
        dangerouslySetInnerHTML={{ __html: answers }}
      />
    </div>
  );
}

QuestionAnswerElement.propTypes = {
  element: PropTypes.object
};

export const QuestionAnswer = ({ element, type = "" }) => {
  const selectedTemplate = type => {
    switch (type) {
      case "question":
        return Question({ element });
      case "answer":
        return Answer({ element });
      case "q-and-a":
        return QuestionAnswerElement({ element });
    }
  };
  return <div>{selectedTemplate(type)}</div>;
};
QuestionAnswer.propTypes = {
  element: PropTypes.object,
  type: PropTypes.string
};
