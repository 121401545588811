import React from 'react';
import "./chip.m.css"


export const Chip = ({children, background, color}) => {
    return (
        <span styleName = "wrapper" style={{background:background, color:color}}>
            {children}
        </span>
    )
}