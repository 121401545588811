/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";
import get from "lodash/get";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { SocialShare } from "@quintype/components";
import SocialShareTemplate from "../../story-templates/social-share-template";
import { checkIfStoryPublic } from "../../../access-type-utils";
import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { ElectionFundAppeal } from "../../atoms/election-fund-appeal";
import { HeroImage } from "../../atoms/hero-image";
import { StoryCard } from "../../molecules/story-card";
import { StoryTags } from "../../atoms/social-tags";
// import assetify from "@quintype/framework/assetify";
import TimeAgo from "react-timeago";
import { LiveIcon } from "../../atoms/icons/live-icon";
import { ClockIcon } from "../../atoms/icons/clock-icon";
import { LiveBlogKeyEvents } from "../../story-templates/live-blog/key-events";
import { CommentWidget } from "../../comment-widget";
import {
  // dateFormatterWithoutTime,
  getLiveBlogDateFormat
} from "../../../../isomorphic/utils";
// import { RequestSubscription } from "../../pages/subscription-page/request-subscription";
import { Paywall } from "../../pages/subscription-page/paywall";
import { socialShareSupport } from "../../molecules/social-share";
import { SubscriptionCTA } from "../../molecules/subscription-cta";
import Loader from "../../atoms/loader";
// import LoadingIcon from "../../../../assets/images/loader.svg";
import { AmazonPolly } from "../../molecules/amazon-polly/amazon-polly";
import StopPress from "../../molecules/stop-press";
import "./live-blog.m.css";

export class GetStoryCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stoty: this.props.story
    };
  }

  componentDidMount() {
    const cardId = this.props.cardId;
    if (cardId) {
      setTimeout(function () {
        document.getElementById(cardId) &&
          document.getElementById(cardId).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
      }, 10);
    }

    this.setState({
      story: this.props.story
    });

    this.storyUpdate = setInterval(this.updateStory.bind(this), 30000);
  }

  async updateStory() {
    const response = await window.fetch(
      `/api/v1/stories/${this.props.story.id}`
    );
    const jsonResponse = await response.json();
    this.setState({
      story: jsonResponse.story
    });
  }

  render() {
    const { cards = [], hasUserSubscribed } = this.props;

    return (
      cards &&
      cards.map((card, index) => {
        const getSubscriptionCTA = get(
          card,
          ["metadata", "attributes", "subscriptioncta", 0],
          "No"
        );
        const getNewsletter = get(
          card,
          ["metadata", "attributes", "newsletter", 0],
          null
        );
        if (getSubscriptionCTA === "Yes") {
          return (
            <div key={card.id} id={card.id}>
              <div styleName="main-wrapper">
                <div styleName="lb-card-wrapper" key={index} id={card.id}>
                  <div styleName="share-status">
                    <div styleName="card-status">
                      <span styleName="clock-icon">
                        <ClockIcon />
                      </span>
                      <TimeAgo
                        date={card["card-added-at"]}
                        styleName="card-added-at"
                        formatter={getLiveBlogDateFormat}
                      />
                    </div>
                    <span styleName="share-icons">
                      <SocialShare
                        title={
                          card.metadata["social-share"].title ||
                          this.state.story.headline
                        }
                        services={["facebook", "twitter", "whatsapp"]}
                        fullUrl={
                          this.state.story &&
                          this.state.story.url + "?cardId=" + card.id
                        }
                        template={SocialShareTemplate}
                      />
                    </span>
                  </div>
                  <StoryCard key={index} card={card} story={this.state.story} />
                  {getNewsletter && (
                    <div className="newsletter-grid">
                      <StopPress planName={getNewsletter} />
                    </div>
                  )}
                </div>

                {!hasUserSubscribed ? (
                  <div className="story-subscription">
                    <SubscriptionCTA
                      url={this.state.story && this.state.story.slug}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        }
        return (
          <div styleName="main-wrapper" key={card.id}>
            <div styleName="lb-card-wrapper" key={index} id={card.id}>
              <div styleName="share-status">
                <div styleName="card-status">
                  <span styleName="clock-icon">
                    <ClockIcon />
                  </span>
                  <TimeAgo
                    date={card["card-added-at"]}
                    styleName="card-added-at"
                    formatter={getLiveBlogDateFormat}
                  />
                </div>
                <span styleName="share-icons">
                  <SocialShare
                    title={
                      card.metadata["social-share"].title ||
                      (this.state.story && this.state.story.headline) ||
                      null
                    }
                    services={["facebook", "twitter", "whatsapp"]}
                    fullUrl={
                      this.state.story &&
                      this.state.story.url + "?cardId=" + card.id
                    }
                    template={SocialShareTemplate}
                  />
                </span>
              </div>
              <StoryCard key={index} card={card} story={this.state.story} />
              {getNewsletter && (
                <div className="newsletter-grid">
                  <StopPress planName={getNewsletter} />
                </div>
              )}
            </div>
          </div>
        );
      })
    );
  }
}

export const LiveBlogTemplate = props => {
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const isClosed = get(props, ["story", "metadata", "is-closed"]);
  const isStoryPublic = checkIfStoryPublic(props.story);
  const storyNew = isStoryPublic
    ? props.story
    : hasActiveSubscription
    ? props.story
    : props.newStory;
  const storyCards = get(storyNew, ["cards"], []);
  function showStoryCards(storyCards, story, config, hasActiveSubscription) {
    return (
      <>
        <GetStoryCards
          cardId={props.cardId}
          cards={storyCards}
          story={story}
          config={config}
          hasUserSubscribed={hasActiveSubscription}
          />
         {/* <div style={{ position: "relative" }}>
          <ElectionFundAppeal/>
        </div> */}
        <div className="story-tags" styleName="story-tags">
          <StoryTags story={story} />
        </div>
        {!isStoryPublic && hasActiveSubscription ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "var(--space-2xl)",
              marginBottom: "var(--space-4xl)",
              backgroundColor: "var(--component-background-accent-default)",
              padding: "20px",
              borderRadius: "var(--space-xxs)",
              border: "1px solid var(--border-accent-default)",
              textAlign: "center",
            }}
          >
            <p
              style={{
                lineHeight: "var(--typography-heading-line-height-h4)",
                fontWeight: "var(--typography-font-weight-semibold)",
                letterSpacing: " var(--typography-letter-spacing-2xl)",
                fontSize: "var(--typography-font-size-2xl)",
                color: "inherit",
                marginRight: "var(--space-2xl)",
              }}
            >
              Liked this story? Consider gifting it to a friend.{"  "}
            </p>
            <GiftStoryButton story={story} />
          </div>
        ) : null}
        {/* {!hasActiveSubscription && checkIfStoryPublic(story) && (
          <div className="story-subscription">
            {<SubscriptionCTA url={story.slug} />}
          </div>
        )} */}
        <div className="metype-widget">
          <CommentWidget story={story} />
        </div>
      </>
    );
  }

  function getIfNoAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    const noOfVisibleCards = get(config, [
      "layout",
      "no-of-visible-cards-in-a-blocked-story"
    ]);
    // console.log(member !== null && hasSubscription === null);
    const eligibleCards = storyCards.slice(0, noOfVisibleCards);
    return (
      <>
        <div>
          <GetStoryCards
            cardId={props.cardId}
            cards={eligibleCards}
            story={story}
            config={config}
            hasUserSubscribed={hasActiveSubscription}
          />
        </div>
        {member !== null && hasActiveSubscription === null ? (
          <div
            style={{
              display: "flex",
              height: "100px",
              flexDirection: "row",
              alignContent: "strech",
              justifyContent: "space-around"
            }}
          >
            <Loader color="#ec2227" />
          </div>
        ) : (
          <Paywall url={story.slug} {...props} />
        )}
      </>
    );
  }

  function getIfHasAccess(storyCards, config, story, hasActiveSubscription) {
    return showStoryCards(storyCards, story, config, hasActiveSubscription);
  }

  function handleAccess(
    storyCards,
    config,
    story,
    hasActiveSubscription,
    member
  ) {
    if (hasActiveSubscription) {
      return getIfHasAccess(storyCards, config, story, hasActiveSubscription);
    }
    return getIfNoAccess(
      storyCards,
      config,
      story,
      hasActiveSubscription,
      member
    );
  }

  return (
    <>
      <div styleName="live-blog-wrapper">
        <div styleName="live-blog">
          <div styleName="live-blog-content">
            <div styleName="lb-section">
              <SectionName story={storyNew} />
            </div>
            <div styleName="lb-headline">
              <Headline story={storyNew} isStoryPage />
            </div>
            <div styleName="lb-subheadline">
              <Subheadline story={storyNew} />
            </div>
            <div>
              {isStoryPublic || hasActiveSubscription ? (
                <AmazonPolly story={storyNew} />
              ) : null}
            </div>
            <div styleName="lb-hero-image">
              <HeroImage story={storyNew} />
            </div>
          </div>
          <div styleName="story-share-icons">
            <div styleName="sticky">{socialShareSupport(storyNew)}</div>
          </div>
          {!isClosed && (
            <div styleName="live-wrapper">
              <span styleName="live-icon">
                <LiveIcon />
              </span>
              <span styleName="live-updates">Live Updates</span>
            </div>
          )}
          <div>
            {isStoryPublic
              ? showStoryCards(
                  storyCards,
                  storyNew,
                  props.config,
                  hasActiveSubscription
                )
              : handleAccess(
                  storyCards,
                  props.config,
                  storyNew,
                  hasActiveSubscription,
                  props.member
                )}
          </div>
        </div>
        <div styleName="key-events">
          <LiveBlogKeyEvents story={props.story} />
        </div>
      </div>
    </>
  );
};

LiveBlogTemplate.propTypes = {
  story: PropTypes.object,
  member: PropTypes.object,
  config: PropTypes.object,
  hasSubscription: PropTypes.bool
};

class LiveBlogAccessWrapper extends React.Component {
  render() {
    return (
      <div>
        {/* {(this.props.member && this.props.hasSubscription=== null && !checkIfStoryPublic(this.props.story) ) && <div styleName="modal">
              <div styleName="modal-content">
                <img src={assetify(LoadingIcon)} alt="loading" />
              </div>
            </div>} */}
        <LiveBlogTemplate {...this.props} />
      </div>
    );
  }
}

class LiveBlogBase extends React.Component {
  render() {
    return <LiveBlogAccessWrapper {...this.props} />;
  }
}

LiveBlogBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  member: get(state, ["member"], null),
  hasSubscription: get(state, ["isSubscribedUser", "userHasSubscription"], null)
});

export const LiveBlog = connect(mapStateToProps)(LiveBlogBase);
