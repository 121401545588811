import { useEffect, useState } from "react";

export function useRelatedStories(story) {
  const [relatedStories, setRelatedStories] = useState([]);
  useEffect(() => {
    async function getRelatedStories() {
      try {
        const result = await global
          .wretch()
          .options({ credentials: "same-origin" })
          .url(
            `/api/v1/stories/${story.id}/related-stories?section-id=${story.sections[0].id}&fields=id,metadata,story-template,headline,slug,hero-image-s3-key,hero-image-metadata,author-name,author-id,authors,url,alternative,sections`
          )
          .get()
          .json();
        setRelatedStories(result["related-stories"]);
      } catch (e) {
        console.log("error while fetching related stories ", e);
      }
    }
    getRelatedStories();
  }, [story]);

  return relatedStories;
}
