/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { AccessType } from "@quintype/components";
import { parseUrl } from "query-string";
// import assetify from "@quintype/framework/assetify";
// import PaywallImage from "../../../../../assets/images/nl-hill-sub.png";
// import logo from "../../../../../assets/images/newslaundry_logo.png";
import Loader from "../../../atoms/loader";
import { benefits, GROUPS, hindiBenefits } from "./constants";
import "./paywall.m.css";
import { getAuthUrl } from "../../../helper/hooks";
import IntegrationData from "../../../../../../config/integrations.js";
import {
  PEOPLE_CARRYING_NL_CLIP_UPHILL,
  COMPRESS_PARAMS_MEDIUM
} from "../../../../image-constants.js";
// import Axios from "axios";

export const Paywall = props => {
  const state = useSelector(state => state);
  const [selectedGroup, setSelectedGroup] = useState(GROUPS[0]); // by default disruptor
  const [selectedPlan, setSelectedPlan] = useState(
    selectedGroup.plans[selectedGroup.plans.length - 1]
  ); // by default annual
  // const [hindiPlan, SetHindiPlan] = useState("3 months subscription");
  const [paymentOption, setPaymentOption] = useState("razorpay"); // by default razorpay
  // const [isIndian, setIsIndian] = useState(true);
  // const [completedAmount, setCompletedAmount] = useState(9);
  // const [targetAmount, setTargetAmount] = useState(800);
  // const barWidth = (completedAmount / targetAmount) * 100;
  const [selectedPlanObj, setSelectedPlanObj] = useState({});
  const [isHindi, setIsHindi] = React.useState(false);

  // const IS_HINDI = !!(
  //   global.location && global.location.host.includes("hindi")
  // );

  const userCountry = useSelector(state =>
    get(state, ["fetchUserCountry"], {
      country_code: "",
      country_code_alias: "",
      country_name: "",
      user_timezone: ""
    })
  );

  const toShowPaymentOptions =
    userCountry.country_code === "IN" ? ["razorpay"] : ["stripe", "paypal"];

  const toShowPlanOptions =
    userCountry.country_code === "IN" ? ["Disruptor"] : ["Game Changer"];

  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const { qt } = useSelector(state => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState(null);

  const fetchSubscription = useSelector(state => state.fetchSubscription);

  // Removed trial by setting expired permanently
  const expiredUser = true;

  const member = useSelector(state => {
    return get(state, ["member"], null);
  });
  // const authUrl = getAuthUrl();

  const pushToDataLayer = (event, url, action) => {
    const obj = {
      event: event,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (url) {
      obj.paywall_content_name = url;
    }

    if (action) {
      obj.paywall_user_action = action;
    }

    window.dataLayer.push(obj);
  };

  useEffect(() => {
    pushToDataLayer("story_paywall", props.url, null);

    if (userCountry.country_code === "IN") {
      setPaymentOption("razorpay");
      setSelectedGroup(GROUPS[0]);
    } else {
      setPaymentOption("stripe");
      setSelectedGroup(GROUPS[1]);
    }
  }, []);

  useEffect(() => {
    setSelectedPlan(selectedGroup.plans[selectedGroup.plans.length - 1]);

    // set paywall with trial plan if first time user
    // if (expiredUser === false) {
    //   setSelectedGroup(GROUPS[0]);
    //   setSelectedPlan(GROUPS[0].trialPlans[0]);
    //   if (!isIndian) {
    //     setPaymentOption("stripe");
    //   }
    // }
  }, [selectedGroup]);

  // useEffect(() => {
  //   Axios.get("https://server.newslaundry.com/hindiSubscribersCount/")
  //     .then(response => {
  //       setCompletedAmount(response.data.userCount);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       setCompletedAmount(9);
  //     });
  // }, []);

  useEffect(() => {
    setIsHindi(global.location.host.includes("hindi."));
    if (state.subscriptions && state.subscriptions.length) {
      // console.log(state.subscriptions); //.filter((obj) => obj.name === "Angel Subscriber Plan")[0].subscription_plans.filter((obj) => obj.title === selectedPlan)[0]);

      const planObj = state.subscriptions.filter(
        obj => obj.name === "Hindi subscription plan"
      )[0];
      // console.log({ planObj });
      setSelectedPlanObj(planObj);
      // if( planObj && planObj.subscription_plans)  {
      //   planObj = planObj.subscription_plans.filter((obj) => obj.title === selectedPlan)[0]
      //   setSelectedPlanObj(planObj);}
    }
  }, [state]);
  const successUrl = authHost; // global.location;
  const handleNotMember = async () => {
    const params = parseUrl(qt.currentPath);
    const callbackUrl = get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    // console.log(`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`, "url")
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  };
  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    planName
  ) => {
    // console.log("insssss");
    // console.log("plan name", { planName });
    if (planName === "3 months subscription ") setLoading(true);
    else setLoading2(true);
    // pushToDataLayer("story_paywall_user_action", props.url, "subscribe");

    /* Redirect to login if not logged in */
    if (!member) {
      handleNotMember();
    }

    /* If not active subscriber then Proceed with subscription payment */
    if (hasActiveSubscription === false) {
      // console.log("insiiiideee");
      /* Initiate razorpay */
      if (selectedPlanObj && selectedPlanObj.subscription_plans) {
        const correctPlanObj = selectedPlanObj.subscription_plans.filter(
          obj => obj.title === planName
        )[0];
        const options = {
          argType: "options",
          selectedPlan: correctPlanObj,
          planType: "standard",
          couponCode: "",
          successUrl: successUrl,
          recipientSubscriber: {
            recipient_identity_provider: "email"
          }
        };
        // console.log({ options });
        //  console.log(selectedPlanObj.subscription_plans);
        //   setSelectedPlanObj(planObj);}

        initRazorPayPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            await handlePaymentSuccess(res);
          })
          .catch(error => {
            console.error(`Razorpay error: ${error}`);
            if (error.message === "user closed the payment") {
              setError("User closed the payment.");
            } else {
              setError("Oops! Something went wrong");
            }
            setLoading(false);
            setLoading2(false);
          });
      }
    }
  };

  const handlePaymentSuccess = async res => {
    setLoading(false);
    window.location = successUrl;
  };

  const integrationsConfig = IntegrationData();
  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const email = get(props.member, ["email"], "");
  const phone = get(props.member, ["metadata", "phone"], "");
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  if (isHindi) {
    return (
      <>
        <div styleName="hindi-wrapper" className="full-width-width-padding">
          <div styleName="hindi-paywall">
            <div styleName="content">
              <div styleName="left-container">
                <h1>स्वतंत्र पत्रकारिता यानि नागरिकों की आजादी की गारंटी</h1>
                <p>
                  एक-दो बातें आपसे कहनी हैं. न्यूज़लॉन्ड्री की ये खबर आप तक
                  पहुंचाने के पीछे हमारा मकसद एक सजग, जागरुक नागरिक तैयार करना
                  है. इसका आधार स्वतंत्र और निष्पक्ष पत्रकारिता है,
                  न्यूज़लॉन्ड्री हिंदी ने एक अलग रास्ता चुना है. सब्सक्रिप्शन का
                  रास्ता. हमारी सात सदस्यों की टीम को आपके समर्थन की जरूरत है.{" "}
                </p>
                <p styleName="more-text">
                  हिंदी टीम को आपका इंतजार है. क्या आप यह लक्ष्य पूरा करेंगे?{" "}
                </p>
                {/* <p styleName="more-text">
                  इस महीने हमारा लक्ष्य 800 नए सब्सक्राइबर बनाने का है, ताकि हम
                  व्यावसायिक दबावों से मुक्त होकर जनहित की पत्रकारिता कर सकें.
                  हिंदी टीम को आपका इंतजार है. क्या आप यह लक्ष्य पूरा करेंगे?{" "}
                </p> */}
                {/* <div styleName="hindi-benefits">
                <p>📹 हमारे पत्रकारों से सीधे मिलें</p>
                <p>🔓 प्रीमियम सामग्री खोलें</p>
                <p>✨ सब्सक्राइब के लिए विशेष आयोजन</p>
              </div> */}
              </div>
              <div styleName="right-container">
                {/* <div styleName="bar-wrapper">
                  <div styleName="amount-container">
                    <div styleName="amount-wrapper">
                      <div styleName="amount" style={{ color: "white" }}>
                        {completedAmount}
                      </div>
                      <div styleName="goal">Subscribers so far</div>
                    </div>
                    <div styleName="amount-wrapper">
                      <div
                        styleName="amount select"
                        style={{
                          textAlign: "right",
                          width: "100%",
                          color: "white",
                        }}
                      >
                        {targetAmount}
                      </div>
                      <div styleName="goal">Target subscribers</div>
                    </div>
                  </div>
                  <div styleName="progress-wrapper">
                    <div
                      styleName="progress-bar"
                      style={{
                        width: barWidth + "%",
                        backgroundColor: "var(--nl-red)",
                      }}
                    ></div>
                  </div>
                </div> */}
                <div styleName="option-two">
                  <div styleName="title-price-wrapper">
                    <h1>3 महीने सब्सक्रिप्शन</h1>
                    <div style={{ margin: "auto" }} styleName="price-wrapper">
                      <p
                        style={{
                          textDecoration: "line-through",
                          color: "gray"
                        }}
                      >
                        ₹900
                        {"   "}
                      </p>
                      <p>₹499</p>
                    </div>
                  </div>
                  {/* <button styleName="ctav">सब्सक्राइब करें</button> */}
                  <AccessType
                    enableAccesstype={enableAccesstype}
                    isAccessTypeCampaignEnabled={true}
                    isStaging={false}
                    accessTypeKey={accessTypeKey}
                    accessTypeBkIntegrationId={accessTypeBkIntegrationId}
                    email={email}
                    phone={phone}
                  >
                    {({ initAccessType, initRazorPayPayment }) => {
                      return (
                        <>
                          {loading ? (
                            <button styleName="ctav">
                              <Loader color="white" />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handlePayment(
                                  initAccessType,
                                  initRazorPayPayment,
                                  "3 months subscription "
                                )
                              }
                              styleName="ctav"
                            >
                              सब्सक्राइब करें
                            </button>
                          )}
                        </>
                      );
                    }}
                  </AccessType>
                </div>
                <div styleName="option-one">
                  <div styleName="title-price-wrapper">
                    <h1>6 महीने सब्सक्रिप्शन</h1>
                    <div style={{ margin: "auto" }} styleName="price-wrapper">
                      <p
                        style={{
                          textDecoration: "line-through",
                          color: "gray"
                        }}
                      >
                        ₹1800{"   "}
                      </p>
                      <p>₹999</p>
                    </div>
                  </div>
                  {/* <button styleName="ctav">सब्सक्राइब करें</button> */}
                  <AccessType
                    enableAccesstype={enableAccesstype}
                    isAccessTypeCampaignEnabled={true}
                    isStaging={false}
                    accessTypeKey={accessTypeKey}
                    accessTypeBkIntegrationId={accessTypeBkIntegrationId}
                    email={email}
                    phone={phone}
                  >
                    {({ initAccessType, initRazorPayPayment }) => {
                      return (
                        <>
                          {loading2 ? (
                            <button styleName="ctav">
                              <Loader color="white" />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handlePayment(
                                  initAccessType,
                                  initRazorPayPayment,
                                  "6 months subscription "
                                )
                              }
                              styleName="ctav"
                            >
                              सब्सक्राइब करें
                            </button>
                          )}
                        </>
                      );
                    }}
                  </AccessType>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && <div styleName="error-message">{error}</div>}
      </>
    );
  } else {
    return (
      <>
        <div styleName="cta">
          <h2 styleName="paywall-title">Subscribe now to unlock the story</h2>
          <br />
        </div>
        <div styleName="wrapper">
          <div styleName={expiredUser ? "head-wrapper" : "head-trial-wrapper"}>
            <img
              src={`${PEOPLE_CARRYING_NL_CLIP_UPHILL}${COMPRESS_PARAMS_MEDIUM}`}
              alt="paywall image"
            />
            <h2 styleName="title">Why should I pay for news?</h2>
            <p styleName="description">
              Independent journalism is not possible until you pitch in. We have
              seen what happens in ad-funded models: Journalism takes a backseat
              and gets sacrificed at the altar of clicks and TRPs.
              <br />
              <br />
              Stories like these cost perseverance, time, and resources.
              Subscribe now to power our journalism.
            </p>
          </div>
          <div styleName="body-wrapper">
            <Benefits
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              expiredUser={expiredUser}
              isHindi={false}
              toShowPlanOptions={toShowPlanOptions}
            />
            {expiredUser && (
              <Plans
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
              />
            )}
            {expiredUser && (
              <PaymentMethods
                paymentOption={paymentOption}
                setPaymentOption={setPaymentOption}
                expiredUser={expiredUser}
                toShowPaymentOptions={toShowPaymentOptions}
              />
            )}
            <Subscribe
              member={member}
              hasActiveSubscription={hasActiveSubscription}
              selectedGroup={selectedGroup}
              selectedPlan={selectedPlan}
              paymentOption={paymentOption}
              expiredUser={expiredUser}
              url={props.url}
            />
          </div>
        </div>

        {/* <div styleName="cta">
          <h2 styleName="paywall-title">
            {IS_HINDI
              ? "हमें खबरों के लिए भुगतान क्यों करना चाहिए?"
              : "Subscribe now to unlock the story"}
          </h2>
          <br />
        </div>
        <div styleName="wrapper">
          <div styleName={expiredUser ? "head-wrapper" : "head-trial-wrapper"}>
            <img src={assetify(PaywallImage)} />
            <h2 styleName="title">
              {IS_HINDI ? "" : "Why should I pay for news?"}
            </h2>
            <p styleName="description">
              {IS_HINDI
                ? "स्वतंत्र पत्रकारिता आपके सहयोग के बिना संभव नहीं है. हमने देखा है विज्ञापन से चलने वाले मॉडल में क्या होता है, ज्यादा से ज्यादा क्लिक और टीवी की रेटिंग्स के लिए पत्रकारिता को सूली पर चढ़ा दिया जाता है."
                : "Independent journalism is not possible until you pitch in. We have seen what happens in ad-funded models: Journalism takes a backseat and gets sacrificed at the altar of clicks and TRPs."}
              <br />
              <br />
              {IS_HINDI
                ? "ऐसी पत्रकारिता के लिए धीरज, समय और संसाधनों की जरूरत होती है. हमारी पत्रकारिता को मजबूत करने के लिए न्यूज़लॉन्ड्री को सब्सक्राइब करें."
                : "Stories like these cost perseverance, time, and resources. Subscribe now to power our journalism."}
            </p>
          </div>
          <div styleName="body-wrapper">
            <Benefits
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              expiredUser={expiredUser}
              isHindi={IS_HINDI}
              toShowPlanOptions={toShowPlanOptions}
            />
            {expiredUser && (
              <Plans
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
              />
            )}
            {expiredUser && (
              <PaymentMethods
                paymentOption={paymentOption}
                setPaymentOption={setPaymentOption}
                expiredUser={expiredUser}
                toShowPaymentOptions={toShowPaymentOptions}
              />
            )}
            <Subscribe
              member={member}
              hasActiveSubscription={hasActiveSubscription}
              selectedGroup={selectedGroup}
              selectedPlan={selectedPlan}
              paymentOption={paymentOption}
              expiredUser={expiredUser}
              url={props.url}
            />
          </div>
        </div> */}
      </>
    );
  }
};

const Benefits = props => {
  return (
    <div styleName="benefits-wrapper">
      <div styleName="tab">
        {props.toShowPlanOptions.includes("Disruptor") && (
          <button
            styleName={`${
              props.selectedGroup.name === "Disruptor" ? "tab-btn-active" : ""
            } tab-btn`}
            onClick={() => props.setSelectedGroup(GROUPS[0])}
          >
            NL-TNM Joint Disruptor
          </button>
        )}
        {props.toShowPlanOptions.includes("Game Changer") && (
          <button
            styleName={`${
              props.selectedGroup.name === "Game Changer"
                ? "tab-btn-active"
                : ""
            } tab-btn`}
            onClick={() => props.setSelectedGroup(GROUPS[1])}
          >
            NL-TNM Joint Game Changer
          </button>
        )}
      </div>
      <ul styleName="benefits">
        {props.isHindi
          ? hindiBenefits.map((benefit, index) => {
              return (
                <li
                  styleName={
                    props.selectedGroup.name === "Disruptor" &&
                    (+index === 4 || +index === 5)
                      ? "invalid-benefit"
                      : "valid-benefit"
                  }
                  key={index + 1}
                >
                  {benefit}
                </li>
              );
            })
          : benefits.map((benefit, index) => {
              return (
                <li
                  styleName={
                    props.selectedGroup.name === "Disruptor" &&
                    (+index === 4 || +index === 5)
                      ? "invalid-benefit"
                      : "valid-benefit"
                  }
                  key={index + 1}
                >
                  {benefit}
                </li>
              );
            })}
      </ul>
    </div>
  );
};

const Plans = props => {
  // handle discounted price

  return (
    <div
      styleName={`plan-wrapper grid-col-${props.selectedGroup.plans.length}`}
    >
      {props.selectedGroup.plans.map((plan, index) => {
        return (
          <Fragment key={index}>
            <div
              styleName={
                props.selectedPlan === plan
                  ? "plan-blocks active"
                  : "plan-blocks"
              }
              onClick={() => props.setSelectedPlan(plan)}
            >
              <p>₹ {plan.price}</p>
              <span>{plan.name}</span>
              {plan.discounts ? (
                <div styleName="pricing-plan-tag">₹{plan.discounts} off</div>
              ) : null}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

const PaymentMethods = props => {
  const [error, setError] = useState(null);

  const handleChange = option => {
    if (option !== "razorpay" && !props.expiredUser) {
      setError("Oops! This is not recommended for users based in India");
    } else {
      setError("");
    }

    props.setPaymentOption(option);
  };

  return (
    <div styleName="payment-methods-wrapper">
      {props.toShowPaymentOptions.includes("stripe") && (
        <div onClick={() => handleChange("stripe")} styleName="method">
          <input type="radio" checked={props.paymentOption === "stripe"} />
          <label>Credit/Debit Card</label>
        </div>
      )}
      {props.toShowPaymentOptions.includes("razorpay") && (
        <div onClick={() => handleChange("razorpay")} styleName="method">
          <input type="radio" checked={props.paymentOption === "razorpay"} />
          <label>UPI, Cards, Netbanking</label>
        </div>
      )}
      {props.toShowPaymentOptions.includes("paypal") && (
        <div onClick={() => handleChange("paypal")} styleName="method">
          <input type="radio" checked={props.paymentOption === "paypal"} />
          <label>Paypal</label>
        </div>
      )}
      {error && <small>{error}</small>}
    </div>
  );
};

const Subscribe = props => {
  const authUrl = getAuthUrl();

  const { fetchSubscription, member } = useSelector(state => state);

  const allSubscriptions = useSelector(state =>
    get(state, ["subscriptions"], [])
  );
  const groupDetails =
    allSubscriptions &&
    allSubscriptions.find(item => item.id === props.selectedGroup.id);

  const planDetails =
    groupDetails &&
    groupDetails.subscription_plans &&
    groupDetails.subscription_plans.find(
      plan => plan.id === props.selectedPlan.id
    );

  const splitDescription =
    (planDetails &&
      planDetails.description &&
      planDetails.description.split(",")) ||
    "";

  // function to get USD planDetails for paypal payment method
  const getUsdPlan =
    groupDetails &&
    groupDetails.subscription_plans.find(
      plan => plan.id === +splitDescription[1]
    );

  // function to select Equivalent One time plan for razorpay
  const selectEquivalentOneTimePlan = () => {
    const splitDescription =
      (planDetails.description && planDetails.description.split(",")) || "";

    const getSelectedPlan = groupDetails.subscription_plans.filter(
      plan => plan.id === +splitDescription[0]
    );

    return getSelectedPlan[0];
  };

  const redirectURL = global.location;

  let groupName = "";

  if (planDetails) {
    if ([113, 760, 804, 805].includes(planDetails.subscription_group_id)) {
      groupName = "gamechanger=true&yearly=true";
    } else if ([114, 759].includes(planDetails.subscription_group_id)) {
      groupName = "disruptor=true";

      if (planDetails.id === 338) {
        groupName += "&monthly=true";
      } else if (planDetails.id === 340) {
        groupName += "&yearly=true";
      }
    } else {
      groupName = "";
    }
  }

  const successUrl = `${redirectURL}?subscription-status=success&${groupName}`;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const pushToDataLayer = (event, url, action) => {
    const obj = {
      event: event,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (url) {
      obj.paywall_content_name = url;
    }

    if (action) {
      obj.paywall_user_action = action;
    }

    window.dataLayer.push(obj);
  };

  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    initStripePayment,
    initPaypalPayment
  ) => {
    setLoading(true);
    pushToDataLayer("story_paywall_user_action", props.url, "subscribe");

    /* Redirect to login if not logged in */
    if (!props.member) {
      window.location = authUrl;
    }

    /* If not active subscriber then Proceed with subscription payment */
    if (props.hasActiveSubscription === false) {
      /* Initiate razorpay */
      if (props.paymentOption === "razorpay") {
        const options = {
          argType: "options",
          selectedPlan: !props.expiredUser
            ? planDetails
            : selectEquivalentOneTimePlan(),
          planType: "standard",
          couponCode: ""
        };

        initRazorPayPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            await handlePaymentSuccess(res);
          })
          .catch(error => {
            setLoading(false);
            // const getErrorMessage = get(
            //   error,
            //   ["error", "message", "subscriber", 0],
            //   ""
            // );
            console.error(`Razorpay error: ${error}`);
            if (error.message === "user closed the payment") {
              setError("User closed the payment.");
            } else {
              setError("Oops! Something went wrong");
            }
          });
      }

      /*
                Initiate stripe 
            */
      if (props.paymentOption === "stripe") {
        const options = {
          argType: "options",
          selectedPlan: planDetails,
          planType: "standard",
          couponCode: ""
        };

        initStripePayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            res && handlePaymentSuccess(res);
          })
          .catch(error => {
            setLoading(false);
            // const getErrorMessage = get(
            //   error,
            //   ["error", "message", "subscriber", 0],
            //   ""
            // );
            console.error(`Razorpay error: ${error}`);
            if (error.message === "user closed the payment") {
              setError("User closed the payment.");
            } else {
              setError("Oops! Something went wrong");
            }
          });
      }

      /*
                Initiate paypal
            */
      if (props.paymentOption === "paypal") {
        const options = {
          argType: "options",
          selectedPlan: getUsdPlan,
          planType: "standard",
          couponCode: "",
          returnUrl: "https://newslaundry.com",
          cancelUrl: "https://newslaundry.com"
        };

        initPaypalPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            await handlePaymentSuccess(res);
          })
          .catch(error => {
            setLoading(false);
            // const getErrorMessage = get(
            //   error,
            //   ["error", "message", "subscriber", 0],
            //   ""
            // );
            console.error(`Razorpay error: ${error}`);
            if (error.message === "user closed the payment") {
              setError("User closed the payment.");
            } else {
              setError("Oops! Something went wrong");
            }
          });
      }
    }
  };

  const handlePaymentSuccess = async res => {
    setLoading(false);
    window.location = successUrl;
  };
  const integrationsConfig = IntegrationData();
  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const email = get(props.member, ["email"], "");
  const phone = get(props.member, ["metadata", "phone"], "");
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <div styleName="subscribe-wrapper">
      <AccessType
        enableAccesstype={enableAccesstype}
        isAccessTypeCampaignEnabled={true}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
        email={email}
        phone={phone}
      >
        {({
          initAccessType,
          initRazorPayPayment,
          initStripePayment,
          initPaypalPayment
        }) => {
          return (
            <Fragment>
              {loading ? (
                <button>
                  <Loader color="white" />
                </button>
              ) : (
                <button
                  onClick={() =>
                    handlePayment(
                      initAccessType,
                      initRazorPayPayment,
                      initStripePayment,
                      initPaypalPayment
                    )
                  }
                >
                  <p>
                    {props.expiredUser ? "Subscribe" : "Start your free trial"}
                  </p>
                  {props.expiredUser && (
                    <small>
                      ₹{props.selectedPlan.price} {props.selectedPlan.name}
                    </small>
                  )}
                </button>
              )}
              {!props.expiredUser && (
                <p>
                  <br /> Post your free trial, you’ll be charged ₹300 per month
                </p>
              )}
              {!props.member && (
                <p>
                  Already a subscriber?{" "}
                  <span>
                    <a
                      onClick={() => {
                        pushToDataLayer(
                          "story_paywall_user_action",
                          props.url,
                          "login"
                        );

                        window.location = authUrl;
                      }}
                    >
                      Login
                    </a>
                  </span>
                </p>
              )}
              {error && <div styleName="error-message">{error}</div>}
            </Fragment>
          );
        }}
      </AccessType>
    </div>
  );
};
