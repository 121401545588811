import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { BlockQuoteIcon } from "../icons/block-quote";
import "./blockquote.m.css";

export const BlockQuote = ({ element }) => {
  const blockQuoteText = get(element, ["metadata", "content"], "");
  const blockQuoteAttribution = get(element, ["metadata", "attribution"], "");
  return (
    <div styleName="quote-wrapper">
      <div styleName="quote-with-icon">
        <span>
          <BlockQuoteIcon />
        </span>
        <div styleName="bq-text" dangerouslySetInnerHTML={{ __html: blockQuoteText }} />
      </div>
      <div styleName="bq-attribution">{blockQuoteAttribution}</div>
    </div>
  );
};
BlockQuote.propTypes = {
  element: PropTypes.shape({
    blockQuoteText: PropTypes.string,
    blockQuoteAttribution: PropTypes.string
  })
};
