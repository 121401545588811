export const benefits = [
  // "Access to paywall stories",
  // "Access to NL Chatbox",
  // // "Access to our subscriber-only Discord server",
  // "Access to subscriber-only events, including The Media Rumble and NL Recess",
  // // "Access to podcast RSS links to listen to our paywall podcasts in apps like Apple and Google Podcasts",
  // "Access to NL Baithak"
  "Paywall stories on both Newslaundry and The News Minute",
  "Priority access to all meet ups and events, including The Media Rumble ",
  "All subscriber-only interaction – NL Chatbox and monthly editorial call with the team",
  "Stronger together merch – Fridge magnets and laptop stickers on annual plan",
  // "RSS feed of all paywall podcasts",
];

export const hindiBenefits = [
  "पे-वॉल के पीछे मौजूद कहानियों तक पहुंच",
  "एनएल चैटबॉक्स",
  "केवल सब्सक्राइबर्स के लिए उपलब्ध हमारे डिस्कॉर्ड सर्वर की सदस्यता",
  "मीडिया रंबल और एनएल रीसेस सहित केवल सब्सक्राइबर्स के लिए आयोजित कार्यक्रमों में भागीदारी",
  "पे-वॉल के पीछे मौजूद हमारे पॉडकास्ट्स को सुनने के लिए आरएसएस लिंक की उपलब्धता",
  "एनएल बैठक में भाग लेने की सुविधा"
];

export const GROUPS = [
  {
    id: 114,
    name: "NL-TNM Joint Disruptor",
    plans: [
      {
        id: 4474,
        name: "Monthly",
        price: 500
      },
      {
        id: 4471,
        name: "Annual",
        price: 4999,
        discounts: 1001
      }
    ],
    trialPlans: [
      {
        id: 1149,
        name: "Monthly",
        price: 300
      }
    ]
  },
  {
    id: 113,
    name: "Game Changer",
    plans: [
      {
        id: 4478,
        name: "Monthly",
        price: 1599
      },
      {
        id: 4476,
        name: "Annual",
        price: 15999,
        discounts: 3001
      }
    ]
  }
];
