/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertHMS } from "../../../utils";
import { getAcastEpisode } from "../../helper/api";
import { saveDataToLocalStorage } from "../../pages/subscription-checkout/utils";
import { Loader } from "../../podcast-player/Loader";
import { getGlobalPlayerRef } from "../../podcast-player/utils";
import { LoginPopup } from "../popup/login-popup";
import { SubscribePopup } from "../popup/subscribe-popup";

import "./episode-play-button.m.css";

export function EpisodePlayButton(props) {
  const [track, setTrack] = useState({});
  const [show, setShow] = useState("");
  const [playerState, setPlayerState] = useState({});
  const [loginPopup, setLoginPopup] = useState(false);
  const [subscribePopup, setSubscribePopup] = useState(false);

  const member = useSelector(state => get(state, ["member"], null));
  const fetchSubscription = useSelector(state =>
    get(state, ["fetchSubscription"], null)
  );

  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );

  useEffect(() => {
    // console.log(props.data);
    const playerRef = getGlobalPlayerRef();
    const removeListener = playerRef.addPlayerListener(state => {
      setPlayerState(state);
    });

    async function getAndLoadEpisode() {
      try {
        // eslint-disable-next-line camelcase
        const { episode_id, show_id } = props.data;

        const data = await getAcastEpisode(show_id, episode_id);
        // const showData = await getAcastShow(show_id);

        setShow(data.shows.title);
        setTrack(data.shows);
      } catch (err) {
        console.log({ err });
      }
    }

    getAndLoadEpisode();

    return removeListener;
  }, []);

  const isLoading =
    (playerState.audioStatus === "loading" &&
      playerState.currentTrackId === track._id) ||
    playerState.loadingUserQueue;
  const isPlaying =
    playerState.audioStatus === "playing" &&
    playerState.currentTrackId === track._id;
  const isLoaded = playerState.currentTrackId === track._id;

  const pushToDataLayer = (event, timeStamp) => {
    const obj = {
      event: event,
      podcast_name: track.title,
      podcast_creator: show,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (timeStamp) {
      obj.podcast_completion = Math.ceil(timeStamp);
      obj.max_podcast_duration = Math.ceil(track.duration);
    }

    window.dataLayer.push(obj);
  };

  const playEpisode = async () => {
    const playerRef = getGlobalPlayerRef();
    if (isPlaying) {
      pushToDataLayer("podcast_pause", playerState.currentTime);

      playerRef.pause();
    } else if (isLoaded) {
      pushToDataLayer("podcast_play", playerState.currentTime);

      playerRef.play();
    } else {
      const currentlyPlaying = {
        cover: track.cover,
        title: track.title,
        showTitle: show,
        duration: track.duration,
        subtitle: track.subtitle,
        streamUrl: track.streamUrl,
        audio: track.audio,
        showId: track.owner,
        _id: track._id
      };

      pushToDataLayer("podcast_play", playerRef.currentTime);

      await playerRef.playTrack(currentlyPlaying);

      playerRef.setState({ showPlayer: true });
      saveDataToLocalStorage(true, "showPlayerTray");
    }
  };

  const handleClick = () => {
    if (props.isStoryPublic && !member) {
      setLoginPopup(true);
    } else if (!props.isStoryPublic && !hasActiveSubscription) {
      setSubscribePopup(true);
    } else {
      playEpisode();
    }
  };

  const renderPlayButton = episode => {
    return (
      <div styleName="play-button-wrapper" onClick={handleClick}>
        <div styleName="icon-wrapper">
          {!isLoading ? (
            <React.Fragment>
              <i className="material-icons-outlined">
                {!isPlaying ? "play_circle" : "pause_circle"}
              </i>
              {/* <p>{isPlaying ? "Pause" : "Play"}</p> */}
            </React.Fragment>
          ) : (
            <button
              aria-label="loading"
              title="Loading"
              className="pressable"
              aria-disabled={true}
            >
              <Loader />
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div styleName="button-wrapper">
        {subscribePopup && (
          <SubscribePopup
            showModal={subscribePopup}
            setShowModal={setSubscribePopup}
            source="podcast-page"
          />
        )}
        {loginPopup && (
          <LoginPopup showModal={loginPopup} setShowModal={setLoginPopup} />
        )}
        {track && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: "100%",
              width: "100%"
            }}
          >
            <div styleName="thumbnail">
              <img src={track.cover} alt={track.show} />
            </div>

            <div styleName="track-meta">
              <div styleName="track-title">{track.title}</div>

              <div styleName="track-actions">
                {renderPlayButton()}
                {!track.show && (
                  <div styleName="seekbar-wrapper">
                    <div
                      styleName="seekbar"
                      style={{
                        width:
                          (playerState.currentTime / track.duration) * 100 +
                          "%",
                        backgroundColor: "#ec2227",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px"
                      }}
                    ></div>
                    <div
                      styleName="seekbar"
                      style={{
                        width:
                          ((track.duration - playerState.currentTime) /
                            track.duration) *
                            100 +
                          "%",
                        backgroundColor: "#3c3c3c",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px"
                      }}
                    ></div>
                    <p styleName="time-left">
                      {" "}
                      -{convertHMS(
                        track.duration - playerState.currentTime
                      )}{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div styleName="app-plug">
        <div styleName="app-title-wrapper">
          {/* <p styleName="app-title">For a more personised experience,</p>
          <p styleName="app-title">Download the Newslaundry app now</p> */}
          <p styleName="app-title">
            For a better listening experience, download the Newslaundry app
          </p>
        </div>
        <div styleName="app-icons-wrapper">
          {downloadAppLinks.map(item => (
            <div key={item.key}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img styleName="app-img" src={item.img} alt={item.name}></img>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const downloadAppLinks = [
  {
    key: "apple",
    img: "https://images.assettype.com/newslaundry/2022-07/a744cd32-2479-4c06-8e77-9478a0575bba/Store_App_Store__Style_Brand__Size_lg.png",
    name: "App Store",
    link: "https://apps.apple.com/in/app/newslaundry/id1609227019?mt=8"
  },
  {
    key: "google",
    img: "https://images.assettype.com/newslaundry/2022-07/2f711755-1779-42c1-8543-199b78fe9b45/Store_Google_Play__Style_Brand__Size_lg.png",
    name: "Play Store",
    link: "https://play.google.com/store/apps/details?id=com.newslaundry.android.app"
  }
];

EpisodePlayButton.propTypes = {
  data: PropTypes.object
};
