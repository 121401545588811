import React from "react";
import { Link } from "@quintype/components";
import { isMobileScreen } from "../../helper/hooks";

import "./read-in-app.m.css";

export const StoryPageReadInAppNudge = () => {
  const isMobScreen = isMobileScreen();

  if (!isMobScreen) {
    return null;
  }

  return (
    <Link
      href="/download-app?ref=storypage-read-in-app"
      styleName="redirect-link"
    >
      Read in app
    </Link>
  );
};
