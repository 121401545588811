import React from "react";
import PropTypes from "prop-types";
import { Link, ResponsiveImage } from "@quintype/components";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import { get } from "lodash";

import "./paywall-image.m.css";
import { FaLock } from "react-icons/fa";

export const PaywallImageElement = ({ element, isCarousel }) => {
  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );

  const params = parseUrl(currentPath);
  const callbackUrl = get(
    params,
    ["query", "callback-url"],
    global.location && global.location.origin
  );
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    global && global.location && global.location.href
  );

  const url = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  const redirectToLogin = () => {
    window.location = url;
  };

  const slug = element["image-s3-key"];
  return (
    <div styleName="block-wrapper">
      <ResponsiveImage
        slug={slug}
        metadata={element.metadata}
        // aspect-ratio={[16, 9]}
        // defaultWidth={480}
        // widths={[250, 480, 640, 800]}
        imgParams={{ blur: "100" }}
        alt={element.title}
        styleName="paywall-img"
      />
      <div styleName="popup-div">
        <div></div>
        <div styleName="popup-main-div">
          <FaLock color="#fff" />
          <p>
            We want to revive the fading tradition of political commentary
            through cartoon, a medium that today more than ever needs to be
            rewarded and not censored. So Sketchy is a step in that direction.
          </p>
          <Link href="/subscription?ref=paywall-comic">
            <button styleName="pay-btn">
              {isCarousel
                ? "Subscribe to view the full cartoon"
                : "Subscribe to view the cartoon"}
            </button>
          </Link>
          <p styleName="helper-text">
            Already a subscriber?{" "}
            <a
              style={{ color: "var(--primary-color)", cursor: "pointer" }}
              onClick={redirectToLogin}
            >
              Login
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

PaywallImageElement.propTypes = {
  element: PropTypes.shape({
    "image-s3-key": PropTypes.string,
    metadata: PropTypes.object,
    title: PropTypes.string,
    "image-attribution": PropTypes.string
  }),
  isCarousel: PropTypes.bool
};
