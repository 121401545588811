import React from "react";
import PropTypes from "prop-types";
import "./summary.m.css";

export const Summary = ({ element }) => {
  const text = element.text || "";
  return (
    <div styleName="content" role="summary text">
      <p styleName="summary-text">Summary</p>
      <div styleName="summary-elememt" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
Summary.propTypes = {
  element: PropTypes.shape({
    text: PropTypes.string
  })
};
