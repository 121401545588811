/* eslint-disable react/prop-types */
import React from "react";
import Modal from "react-modal";
import { Button } from "../../ui/components";
import { LuPartyPopper } from "react-icons/lu";
import { FaTriangleExclamation } from "react-icons/fa6";
import { GoCheckCircleFill } from "react-icons/go";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";
import { parseUrl } from "query-string";
import { useSelector } from "react-redux";
import giftArt from "../../../assets/images/nl-images/nl-sena-page.png";

import "./modals.m.css";
import { Link } from "@quintype/components";
import {
  BIRUBAL_GIFTING_NL_CLIP_BOX_SUBSCRIPTION,
  COMPRESS_PARAMS_MEDIUM
} from "../../image-constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "350px",
    bottom: "auto",
    marginRight: "-50%",
    padding: "var(--space-xl)",
    transform: "translate(-50%, -50%)",
    border: "1px solid var(--border-neutral-default)",
    backgroundColor: "var(--background-neutral-muted)",
    maxWidth: "30rem",
    color: "var(--text-neutral-default)"
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "var(--overlay)"
  }
};

export const PaymentConfirmationModal = ({ toShowModal, setToShowModal }) => {
  const shareUrl = "https://www.newslaundry.com/subscription";
  const title =
    "I just got the @Newslaundry-@TheNewsMinute joint subscription! Readers like you and I need to pay to keep news free. Subscribe now:";

  return (
    <Modal
      style={customStyles}
      isOpen={toShowModal}
      contentLabel="Example Modal"
    >
      <div styleName="content-wrapper">
        <div styleName="content-heading-wrapper">
          <LuPartyPopper
            aria-hidden="true"
            style={{ width: "1.25rem", height: "1.25rem" }}
          />
          <h4>Woohoo! Your subscription has been upgraded.</h4>
        </div>
        <p styleName="content-description">
          Your updated subscription should reflect within a few minute. Head to
          My Profile page to see your subscription status. In case you’re still
          seeing the old subscription, give it a few seconds and then refresh
          the page.
        </p>
        <p styleName="content-description">
          If the problem still persists, email us at{" "}
          <span styleName="highlight">subscription@newslaundry.com</span>.
        </p>
        <p styleName="content-description">
          Inspire others to subscribers. Share this on your social media.
        </p>
        <div
          styleName="action-wrapper "
          style={{ display: "flex", justifyContent: "between" }}
        >
          <button
            style={{
              borderRadius: "10px",
              padding: "10px 15px",
              cursor: "pointer",
              border: "2px soild var(--border-neutal-default)"
            }}
            // onClick={handleClick}
          >
            <div style={{ paddingTop: "3px", display: "flex", gap: "10px" }}>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>{" "}
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>{" "}
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>{" "}
              <LinkedinShareButton url={shareUrl} title={title}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>{" "}
            </div>
            {/* )} */}
          </button>
          <br />
          <Link href="/account-details" styleName="redirect-link">
            Go to My Profile page to view
          </Link>
          {/* <Button variant="accent">Proceed</Button> */}
        </div>
      </div>
    </Modal>
  );
};

export const ErrorModal = ({ toShowModal, setToShowModal, errMsg = "" }) => {
  console.log({ errMsg });
  return (
    <Modal style={customStyles} isOpen={toShowModal} contentLabel="Error Modal">
      <div styleName="content-wrapper">
        <div styleName="content-heading-wrapper danger-overrides">
          <FaTriangleExclamation
            aria-hidden="true"
            style={{ width: "1.25rem", height: "1.25rem" }}
          />
          <h4>Oops! Something went wrong.</h4>
        </div>
        {errMsg ? (
          <p styleName="content-description danger-overrides">
            Error : {errMsg}
          </p>
        ) : (
          <></>
        )}
        <p styleName="content-description">
          There was an error while processing your joint subscription. Please
          try again.
        </p>
        <p styleName="content-description">
          You can write to{" "}
          <span styleName="highlight">subscription@newslaundry.com</span> if the
          problemstill persists.
        </p>
        <div styleName="action-wrapper">
          <Button
            onClick={() => {
              setToShowModal(false);
            }}
          >
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ProceedRecurringModal = ({
  toShowModal,
  setToShowModal,
  setDidProceed,
  handleBtnClick
}) => {
  return (
    <Modal style={customStyles} isOpen={toShowModal} contentLabel="Recurring">
      <div styleName="content-wrapper">
        <div styleName="content-heading-wrapper green-text">
          <GoCheckCircleFill
            aria-hidden="true"
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
          <h4>Confirm upgrade to NL-TNM joint subscription </h4>
        </div>
        <p styleName="content-description green-text">
          Your subscription upgrade amount will be debited from the payment
          method you used for your existing subscription.
        </p>
        <div styleName="action-wrapper">
          <Button
            variant="accent"
            onClick={() => {
              setDidProceed(true);
              // setToShowModal(false);
              handleBtnClick(true);
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setToShowModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const GiftedStoryModal = ({ toShowModal, setToShowModal }) => {
  const { qt } = useSelector(state => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  function handleBtnClick() {
    const parameters = parseUrl(qt.currentPath);
    const callbackUrl = get(
      parameters,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      parameters,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    // console.log(`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`, "url")
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  }

  return (
    <Modal style={customStyles} isOpen={toShowModal} contentLabel="Recurring">
      <div styleName="content-wrapper">
        <img
          width="300px"
          styleName="gift-story-artwork"
          src={`${BIRUBAL_GIFTING_NL_CLIP_BOX_SUBSCRIPTION}${COMPRESS_PARAMS_MEDIUM}`}
          alt="gift story success artwork"
        />
        <div styleName="content-heading-wrapper green-text">
          {/* <LuPartyPopper
            aria-hidden="true"
            style={{ width: "5rem", height: "5rem", marginRight: "5px" }}
          /> */}
          <h4>
            You have been gifted to read this subscriber only story. Please
            login first to read.
          </h4>
        </div>
        {/* <p styleName="content-description green-text">
            Your subscription upgrade amount will be debited from the payment
            method you used for your existing subscription.
          </p> */}
        <div styleName="action-wrapper" style={{ margin: "auto" }}>
          <Button
            variant="accent"
            onClick={() => {
              handleBtnClick();
            }}
          >
            <b> Login {"→"}</b>
          </Button>
          {/* <Button
              onClick={() => {
                setToShowModal(false);
              }}
            >
              Cancel
            </Button> */}
        </div>
      </div>
    </Modal>
  );
};
