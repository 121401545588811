import React, { useState } from "react";
import PropTypes from "prop-types";
import { PaywallImageElement } from "../paywall-image";
import { ResponsiveImage } from "@quintype/components";

import "./paywall-image-gallery.m.css";
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";

const PaywallImageGallery = ({ element, toShowPaywall }) => {
  const storyElements = element["story-elements"];
  const totalElements = storyElements && storyElements.length;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleIndexDecrease = () => {
    if (currentImageIndex === 0) {
      return;
    }

    setCurrentImageIndex(index => index - 1);
  };

  const handleIndexIncrease = () => {
    if (currentImageIndex === totalElements - 1) {
      return;
    }

    setCurrentImageIndex(index => index + 1);
  };

  return (
    <div styleName="wrapper">
      {currentImageIndex !== 0 && (
        <button styleName={`left-arrow buttons`} onClick={handleIndexDecrease}>
          <HiArrowCircleLeft />
        </button>
      )}
      {currentImageIndex !== totalElements - 1 && (
        <button styleName={`right-arrow buttons`} onClick={handleIndexIncrease}>
          <HiArrowCircleRight />
        </button>
      )}
      <div styleName={`carousel-wrapper`}>
        {currentImageIndex === 0 ? (
          <img
            src={`https://gumlet.assettype.com/${storyElements[currentImageIndex]["image-s3-key"]}`}
            alt={storyElements[currentImageIndex].title}
            loading="eager"
          />
        ) : // <ResponsiveImage
        //   slug={storyElements[currentImageIndex]["image-s3-key"]}
        //   metadata={storyElements[currentImageIndex].metadata}
        //   // aspect-ratio={[16, 9]}
        //   // defaultWidth={480}
        //   // widths={[250, 480, 640, 800]}
        //   imgParams={{ auto: ["format", "compress"] }}
        //   alt={storyElements[currentImageIndex].title}
        // />
        toShowPaywall ? (
          <PaywallImageElement
            element={storyElements[currentImageIndex]}
            isCarousel={true}
          />
        ) : (
          <img
            src={`https://gumlet.assettype.com/${storyElements[currentImageIndex]["image-s3-key"]}`}
            alt={storyElements[currentImageIndex].title}
            loading="eager"
          />
          // <ResponsiveImage
          //   slug={storyElements[currentImageIndex]["image-s3-key"]}
          //   metadata={storyElements[currentImageIndex].metadata}
          //   // aspect-ratio={[16, 9]}
          //   // defaultWidth={480}
          //   // widths={[250, 480, 640, 800]}
          //   // imgParams={{ auto: ["format", "compress"] }}
          //   alt={storyElements[currentImageIndex].title}
          // />
        )}
      </div>
      <div styleName="slide-number">
        <p>
          Slide {currentImageIndex + 1}/{totalElements}
        </p>
      </div>
    </div>
  );
};

PaywallImageGallery.propTypes = {
  element: PropTypes.shape({
    "story-elements": PropTypes.array,
    metadata: PropTypes.object,
    title: PropTypes.string
  }),
  toShowPaywall: PropTypes.bool
};

export { PaywallImageGallery };
