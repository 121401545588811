import React from "react";
import PropTypes from "prop-types";
import "./blurb.m.css";

export const Blurb = ({ element }) => {
  const BlurbElement = element.text || "";
  return (
    <div styleName="blurb-wrapper">
      <div styleName="blurb-text" dangerouslySetInnerHTML={{ __html: BlurbElement }} />
    </div>
  );
};
Blurb.propTypes = {
  element: PropTypes.shape({
    text: PropTypes.string
  })
};