import React from "react";
import { object } from "prop-types";
import { StoryPage } from "./story";

class StoryPagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.collectStoryData();
  }

  collectStoryData() {
    global.addEventListener("message", event => {
      if (event.data.story) {
        this.setState({
          story: event.data.story,
          relatedStories: Array(4).fill(event.data),
          preview: true,
          index: 0
        });
      }
    });
  }

  render() {
    if (!this.state.story) return <div />;
    return StoryPage({ data: this.state }, this.props.config);
  }
}

export { StoryPagePreview };

StoryPagePreview.propTypes = {
  config: object
};
