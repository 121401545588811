import React, { useEffect, useRef, useState } from "react";
import PropType from "prop-types";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import { get } from "lodash";
import { Link } from "@quintype/components";
import { subscriptionAppeals } from "./constants";

import { Button } from "../../../ui/components/button";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import "./subscription-appeal-bottom-sheet.m.css";
import { COMPRESS_PARAMS_MEDIUM } from "../../../image-constants";

const SubscriptionAppealBottomSheet = ({
  element,
  subAppeal,
  isPressFreedom
  // story
}) => {
  const dialogRef = useRef();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [bottomSheetState, setBottomSheetState] = useState("hidden"); // hidden | collapsed | expanded

  const [isHindi, setIsHindi] = React.useState(false);

  useEffect(() => {
    setIsHindi(global.location.host.includes("hindi."));
  }, []);

  const ctaTitle = get(element, ["metadata", "cta-title"], "");
  const ctaURL = get(element, ["metadata", "cta-url"], "");

  let derivedCTAURL = null;
  let selectedSubscriptionAppeal = null;

  if (ctaURL) {
    derivedCTAURL = ctaURL.split("https://www.newslaundry.com")[1];
  }

  if (subAppeal) {
    selectedSubscriptionAppeal = subscriptionAppeals.find(
      appeal => appeal.name === subAppeal
    );
  } else {
    selectedSubscriptionAppeal = subscriptionAppeals[3];
  }

  const DescriptionText = () => {
    return (
      <p styleName="description-text">
        {(selectedSubscriptionAppeal &&
          (isHindi
            ? selectedSubscriptionAppeal.hindiText
            : selectedSubscriptionAppeal.text)) ||
          ctaTitle}
      </p>
    );
  };

  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );

  const params = parseUrl(currentPath);
  const callbackUrl = get(
    params,
    ["query", "callback-url"],
    global.location && global.location.origin
  );
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    global && global.location && global.location.href
  );

  const url = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  const redirectToLogin = () => {
    window.location = url;
  };

  useEffect(() => {
    if (hasScrolled && dialogRef.current) {
      dialogRef.current.style.bottom = "0";

      setBottomSheetState("expanded");
    }
  }, [hasScrolled]);

  useEffect(() => {
    const totalWindowHeight = window.innerHeight;
    // console.log("Total height", window.innerHeight);

    const scrollHeightLimit = Number(totalWindowHeight / 4);
    // console.log("Scroll height", scrollHeightLimit);

    const handleScrollY = () => {
      if (window.scrollY > scrollHeightLimit) {
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScrollY);

    return () => {
      window.removeEventListener("scroll", handleScrollY);
    };
  }, []);

  const handleCloseBtnClick = () => {
    if (bottomSheetState === "expanded") {
      if (dialogRef.current) {
        dialogRef.current.style.height = "var(--collapsed-sheet-height)";
        setBottomSheetState("collapsed");
      }
    } else if (bottomSheetState === "collapsed") {
      if (dialogRef.current) {
        dialogRef.current.style.height = "300px";
        setBottomSheetState("expanded");
      }
    }
  };

  return (
    <div ref={dialogRef} role="dialog" styleName="sheet-wrapper">
      {bottomSheetState === "expanded" ? (
        <div styleName="sheet-content-wrapper">
          <div styleName="left-container">
            <img
              src={
                (selectedSubscriptionAppeal &&
                  `${selectedSubscriptionAppeal.imageURL}${COMPRESS_PARAMS_MEDIUM}`) ||
                `https://images.assettype.com/newslaundry/2022-08/fb9ec400-b15f-4aee-8c58-0a15d19d147b/NL_HOMEPAGE_1_copy.png${COMPRESS_PARAMS_MEDIUM}`
              }
              alt="subscription-appeal-image"
              styleName="subscription-appeal-image"
            />
          </div>
          <div styleName="right-container">
            <h3>Support Independent Media</h3>
            <DescriptionText />
            <Link
              href={
                derivedCTAURL ||
                "/subscription?ref=subscription-appeal-bottom-sheet"
              }
              styleName="redirect-link"
            >
              Subscribe now
            </Link>
            <p styleName="redirect-login-text">
              Already a subscriber? <span onClick={redirectToLogin}>Login</span>
            </p>
          </div>
        </div>
      ) : bottomSheetState === "collapsed" ? (
        <div styleName="sheet-collapsed-content-wrapper">
          <div styleName="sheet-collapsed-content">
            <h4>Support independent media.</h4>
            <p styleName="collapsed-description-text">
              We serve you, not the advertisers. Pay to keep news free.
            </p>
            <Link
              href={
                derivedCTAURL ||
                "/subscription?ref=subscription-appeal-bottom-sheet"
              }
              styleName="redirect-link redirect-link-sm"
            >
              Subscribe now
            </Link>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Button
        size="sm"
        styleName={`sheet-close-btn ${
          bottomSheetState === "collapsed" ? "sheet-collapsed" : ""
        }`}
        onClick={handleCloseBtnClick}
        aria-label="Hide this dialog"
      >
        <MdOutlineKeyboardArrowDown aria-hidden="true" />
      </Button>
    </div>
  );
};

SubscriptionAppealBottomSheet.propTypes = {
  element: PropType.object,
  subAppeal: PropType.string,
  isHindi: PropType.bool,
  isPressFreedom: PropType.bool
};

export { SubscriptionAppealBottomSheet };
