import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import TimeAgo from "react-timeago";
import { ClockIcon } from "../../atoms/icons/clock-icon";
import { dateFormatterWithoutTime } from "../../../../isomorphic/utils";
import "./key-events.m.css";

const FetchEventTitle = props => {
  return (
    <div>
      {props.element &&
        props.element.map(
          (element, index) =>
            element.type === "title" && (
              <h3 styleName="event-title" key={index}>
                {element.text}
              </h3>
            )
        )}
    </div>
  );
};
FetchEventTitle.propTypes = {
  element: PropTypes.array
};

function KeyEvent(props) {
  return (
    <div>
      <div styleName="key-event-wrapper">
        <span styleName="clock-icon">
          <ClockIcon />
        </span>
        <TimeAgo date={props.card["card-added-at"]} styleName="card-added-at" formatter={dateFormatterWithoutTime} />
      </div>
      <a href={`/${props.slug}#${props.card.id}`}>
        <FetchEventTitle element={props.card["story-elements"]} />
      </a>
    </div>
  );
}
KeyEvent.propTypes = {
  card: PropTypes.shape({
    "card-added-at": PropTypes.number,
    "story-elements": PropTypes.array,
    id: PropTypes.number
  }),
  slug: PropTypes.string
};

function LiveBlogKeyEvents(props) {
  const cards = props.story.cards || [];
  const cardsWithKeyEvents = cards.filter(card => get(card.metadata, ["attributes", "key-event"]), false);

  if (cardsWithKeyEvents.length < 1) {
    return null;
  }

  const keyEvents = cardsWithKeyEvents.map(card => <KeyEvent card={card} key={card.id} slug={props.story.slug} />);

  
  return (
    <div styleName="key-events">
      <h3 styleName="heading"> Key Events</h3>
      <div styleName="events">{keyEvents}</div>
    </div>
  );
}

export { LiveBlogKeyEvents };

LiveBlogKeyEvents.propTypes = {
  story: PropTypes.shape({
    cards: PropTypes.array,
    slug: PropTypes.string
  })
};
