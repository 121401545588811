import React from "react";
import assetify from "@quintype/framework/assetify";
import { object, func } from "prop-types";

import { ImageElement } from "../../atoms/image";
import GlideSlider from "../../molecules/glide-slider";
import Close from "../../../../assets/images/close.svg";

import "./light-box.m.css";

const LightBox = props => {
  return (
    <div styleName="wrapper">
      <div styleName="lightbox" className="lightbox">
        {props.slideshowItems && props.slideshowItems.length > 0 ? (
          <GlideSlider
            story={props.story}
            totalItems={props.slideshowItems.length}
            slideshowItems={props.slideshowItems}
            index={props.index}
          />
        ) : (
          <ImageElement element={props.element} index={props.index} />
        )}
        <button onClick={props.closePopup} styleName="icon-close">
          <img src={`${assetify(Close)}`} alt="close" />
        </button>
      </div>
    </div>
  );
};

LightBox.propTypes = {
  element: object,
  closePopup: func
};

export default LightBox;
