import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./big-fact.m.css";

export const BigFact = ({ element }) => {
  const bigFactText = get(element, ["metadata", "content"], "");
  const bigFactAttribution = get(element, ["metadata", "attribution"], "");
  return (
    <div styleName="bf-wrapper">
      <div styleName="bf-text" dangerouslySetInnerHTML={{ __html: bigFactText }} />
      <div styleName="bf-attribution">{bigFactAttribution}</div>
    </div>
  );
};
BigFact.propTypes = {
  element: PropTypes.shape({
    bigFactText: PropTypes.string,
    bigFactAttribution: PropTypes.string
  })
};