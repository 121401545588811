import { useEffect, useState } from "react";
import { verifyGiftStoryLink } from "../../helper/api";
import queryString from "query-string";
// import get from "lodash/get";
// import { parseUrl } from "query-string";
import { useSelector } from "react-redux";

export function useVerifyGiftStoryToken(story) {
  const [isValid, setIsValid] = useState(false);
  const { member } = useSelector(state => state);
  // const { qt } = useSelector(state => state);
  // const authHost = qt.config.publisher.publisher.auth_host;

  useEffect(() => {
    async function verifyGiftStory() {
      const params = queryString.parse(window.location.search);
      // console.log("indside useverify ", { params });
      if (params.token && member) {
        const token = params.token;
        // console.log({
        //   storyId: story.id,
        //   token,
        // });
        try {
          const res = await verifyGiftStoryLink({
            storyId: story.id,
            token
          });
          // console.log({ res }, res.success);
          if (res.success) {
            setIsValid(true);
          }
        } catch (e) {
          console.log({ e }, "useverify error");
        }
      }
      // else if(params.token && !member){
      //   const parameters = parseUrl(qt.currentPath);
      //   const callbackUrl = get(
      //     parameters,
      //     ["query", "callback-url"],
      //     global.location && global.location.origin
      //   );
      //   const redirectUrl = get(
      //     parameters,
      //     ["query", "redirect-url"],
      //     global && global.location && global.location.href
      //   );
      //   //console.log(`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`, "url")
      //   window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;

      // }
    }
    verifyGiftStory();
  }, [member]);

  return isValid;
}
