import React from "react";
import PropTypes from "prop-types";
import "./image-caption.m.css";

export const ImageCaption = ({ story }) => {
  const caption = story["hero-image-caption"];
  const attribution = story["hero-image-attribution"];

  if (!caption && !attribution) return null;
  return (
    <div styleName="wrapper">
      <span dangerouslySetInnerHTML={{ __html: caption }} />
      {caption && attribution && <span styleName="divider">|</span>}
      <span dangerouslySetInnerHTML={{ __html: attribution }} />
    </div>
  );
};

ImageCaption.propTypes = {
  story: PropTypes.object.isRequired
};
