import React, { useState } from "react";
import { useScrollLock } from "../helper/hooks";
import Axios from "axios";
import PropTypes from "prop-types";
import { saveDataToLocalStorage } from "../pages/subscription-checkout/utils";

import "./hindi-login-banner.m.css";
import {
  COMPRESS_PARAMS_MEDIUM,
  COMPRESS_PARAMS_SMALL,
  NEWSLAUNDRY_SQUARE_LOGO,
  TRAFFIC_POLICE_BIRUBAL_STOP_NUDGE,
} from "../../image-constants";

export const HindiLoginBanner = ({ setToShowHindiLoginBanner = false }) => {
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    try {
      await Axios.post(`/newsletter-api/lists/aa2c780e6c/members`, {
        email,
        tags: ["hindi-story-banner-nl-daak"],
      });

      saveDataToLocalStorage(email, "hasUserGivenEmail");

      setShowLoader(false);
      setPrompt(
        "साइन अप करने के लिए धन्यवाद। जल्द ही आपके इनबॉक्स में मिलते हैं।"
      );

      setTimeout(() => {
        setEmail("");
        setPrompt(null);
        setToShowHindiLoginBanner(false);
      }, 3000);
    } catch (error) {
      console.error({ hindiBannerError: error });
      setError(
        "Something went wrong. Please enter a valid or try a new email address"
      );
      setShowLoader(false);
    }
  };

  useScrollLock();

  return (
    <div styleName="wrapper">
      <main styleName="main-wrapper">
        <div styleName="main-section">
          <div styleName="hero-section">
            <div styleName="hero-image-wrapper">
              <img
                styleName="hero-image"
                src={`${TRAFFIC_POLICE_BIRUBAL_STOP_NUDGE}${COMPRESS_PARAMS_MEDIUM}`}
                alt="birubal email"
              />
            </div>
          </div>
          <div styleName="form-div-wrapper">
            <div styleName="row-wrapper">
              <div styleName="logo-wrapper">
                <img
                  src={`${NEWSLAUNDRY_SQUARE_LOGO}${COMPRESS_PARAMS_SMALL}`}
                  alt="newslaundry logo"
                />
              </div>
              <h1 styleName="primary-heading">
                इस कहानी को आगे पढ़ने के लिए साइन-अप करें
              </h1>
            </div>
            <p styleName="description">
              न्यूज़लॉन्ड्री प्रतिदिन सीमित व बेहतरीन रिपोर्ट, वीडियो और
              पॉडकास्ट ही प्रकाशित करता है। हम आपके समय का मूल्य जानते हैं,
              इसलिए हम आपको अपनी खास कहानियों पर ही ईमेल भेजेंगे।
            </p>
            <div styleName="form-field-wrapper">
              <form styleName="form-input-wrapper" onSubmit={handleFormSubmit}>
                <input
                  type="email"
                  name=""
                  id=""
                  styleName="input"
                  placeholder="कृपया अपना ईमेल पता दर्ज करें"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button styleName="primary-btn">
                  {showLoader ? "कृपया प्रतीक्षा करें" : "हमसे जुड़ें"}
                </button>
              </form>
            </div>
            {error && <p styleName="error-message">{error}</p>}
            {prompt && <p styleName="success-message">{prompt}</p>}
          </div>
        </div>
      </main>
      <div styleName="bg-overlay"></div>
      <div styleName="blur-effect"></div>
    </div>
  );
};

HindiLoginBanner.propTypes = {
  setToShowHindiLoginBanner: PropTypes.func,
};
