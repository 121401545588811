import React from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import close from "../../../../assets/images/regular.svg"
import { connect } from "react-redux";

import "./amazon-polly.m.css";

export const AmazonPollyPopupBase = ({member, storyData}) => {
  const dispatch = useDispatch();
  const config = useSelector(state => get(state, ["qt", "config"], {}));
  const amazonPolly = useSelector(state => get(state, ["amazonPolly"], {}));
  const { isOpen } = amazonPolly;
  const caption = get(storyData, ["headline"], "Press play to listen to this story");
  const audioS3Key = get(storyData, ["story-audio", "s3-key"], "");
  const cdn = get(config, ["cdn-image"]);
  const audioSrc = `//${cdn}/${audioS3Key}`;
  const audioAttributes = { controls: true, autoPlay: true, preload: "metadata", controlsList: "nodownload" };
  const closeHandler = () => {
    batch(() => {
      dispatch({ type: "CLOSE_AMAZON_POLLY" });
    });
  };

  return (
    isOpen ? (
      <div styleName="amazon-polly-bar-wrapper">
        <figure styleName="amazon-polly-content">
          <figcaption styleName="amazon-polly-caption">{caption}</figcaption>
          <div styleName="amazon-polly-audio-wrapper">
            <audio styleName="amazon-polly-audio" src={audioSrc} {...audioAttributes}>
              Your browser does not support the <code>audio</code> element.
            </audio>
          </div>
        </figure>
        <div styleName="amazon-polly-close-wrapper" onClick={closeHandler}>
        <img  src={assetify(close)} alt="amazone polly" className="amazon-polly-close" /> 
        </div>
      </div>
    ): null
  );
};


const mapStateToProps = state => ({
  member: get(state, ["member"], null),

});

export const AmazonPollyPopup = connect(mapStateToProps)(AmazonPollyPopupBase);