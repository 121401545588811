/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  useInfiniteQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
  useQueryClient
} from "react-query";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Chip } from "../atoms/chip";
import { SubAvatar } from "../atoms/sub-avatar";
import Loader from "../atoms/loader";
import { parseUrl } from "query-string";

import {
  // getFreshComments,
  createCMUser,
  fetchComments,
  postComment,
  deleteComment,
  editComment,
  updateLike,
  updateFeature,
  reportComment,
  fetchReplies
} from "../../cartoon-mango";
import { Link } from "@quintype/components";
// import assetify from "@quintype/framework/assetify";
// import AvatarIcon from "./icons/avatar.png";
// import LikeIcon from "./icons/like.png";
// import LikedIcon from "./icons/liked.png";
// import ReplyIcon from "./icons/reply.png";
// import MenuIcon from "./icons/menu.png";
// import EditIcon from "./icons/edit.png";
// import DeleteIcon from "./icons/delete.png";
// import ReportIcon from "./icons/report.png";
// import EmptyArt from "./icons/empty.svg";
// import EmptyArt from "./icons/no_comments.png";
import {
  MdMoreVert,
  MdEdit,
  MdDelete,
  MdThumbUp,
  MdOutlineThumbUp,
  MdReply,
  MdOutlineReportProblem
} from "react-icons/md";
import { FaUser } from "react-icons/fa6";

import "./comment-widget.m.css";
import { SubscribePopup } from "../molecules/popup/subscribe-popup";
import {
  BIRUBAL_NO_COMMENTS,
  COMPRESS_PARAMS_EXTRA_SMALL
} from "../../image-constants";

// in miliseconds
var units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
};

const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

function getTimestamp(commentTime, currentTime) {
  var elapsed = commentTime - currentTime;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (var u in units)
    if (Math.abs(elapsed) > units[u] || u === "second")
      return rtf.format(Math.round(elapsed / units[u]), u);
}

function CommentWidgetBase({ story, member, activeSubscription }) {
  // const [showComments, setShowComments] = useState(false);

  const [count, setCount] = useState(0);
  const [userDetails, setUserDetails] = useState(null);

  React.useEffect(() => {
    const handleCreateUser = async () => {
      if (member && activeSubscription) {
        const res = await createCMUser(member, activeSubscription);

        setUserDetails(res.data);
      }
    };

    handleCreateUser();
    // member &&
    //   activeSubscription &&
    //   createCMUser(member, activeSubscription).then(
    //     res => res && res.data && setUserDetails(res.data)
    //   );
  }, [count, member, activeSubscription]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // bandwidth optimisation changes
        staleTime: Infinity,
        refetchInterval: false,
        refetchIntervalInBackground: false
        // staleTime: 0,
        // refetchInterval: 10000,
        // refetchIntervalInBackground: true
      }
    }
  });

  // if (showComments) {
  //   if (true) {
  //   return (
  //     <QueryClientProvider client={queryClient}>
  //       <Comments
  //         story={story}
  //         member={member}
  //         activeSubscription={activeSubscription}
  //         count={count}
  //         setCount={setCount}
  //         userDetails={userDetails}
  //       />
  //     </QueryClientProvider>
  //   );
  // } else {
  //   return (
  //     <div styleName="load-comments-wrapper">
  //       <button
  //         styleName="load-comments-button"
  //         onClick={() => setShowComments(true)}
  //       >
  //         Load comments
  //       </button>
  //     </div>
  //   );
  // }
  return (
    <QueryClientProvider client={queryClient}>
      <Comments
        story={story}
        member={member}
        activeSubscription={activeSubscription}
        count={count}
        setCount={setCount}
        userDetails={userDetails}
      />
    </QueryClientProvider>
  );
}

const Comments = ({
  story,
  member,
  activeSubscription,
  count,
  setCount,
  userDetails
}) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    status
  } = useInfiniteQuery(["comments", { storyID: story.id }], fetchComments, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage.data.items.length === 5)
        return lastPage.data.next_page_cursor;
    }
  });

  if (status === "success" && data.pages[0]) {
    setCount(data.pages[0].data.items.length);
  }

  const [showReplyfor, setShowReplyFor] = useState(null);

  return (
    <div styleName="wrapper">
      <h3 styleName="section-title">Comments</h3>

      <InputForm
        refetch={refetch}
        story={story}
        member={member}
        activeSubscription={activeSubscription}
      />

      <hr />

      {count === 0 && status !== "loading" && (
        <div styleName="empty-state">
          <img
            src={`${BIRUBAL_NO_COMMENTS}${COMPRESS_PARAMS_EXTRA_SMALL}`}
            alt="No comments on this story"
          />
          <p>Be the first one to comment</p>
        </div>
      )}

      {status === "loading" ? (
        <span styleName="loader">
          <br />
          <Loader color="#ec2227" />
        </span>
      ) : status === "error" ? (
        <p>Error : {error.message}</p>
      ) : (
        <React.Fragment>
          {data.pages.map((page, i) => {
            return (
              <React.Fragment key={i}>
                {page &&
                  page.data.items.map(item => {
                    return (
                      !item.is_deleted && (
                        <CommentBody
                          showReplyfor={showReplyfor}
                          setShowReplyFor={setShowReplyFor}
                          story={story}
                          item={item}
                          member={member}
                          userSubscription={activeSubscription}
                          reply={false}
                          userDetails={userDetails}
                          key={item.id}
                        />
                      )
                    );
                  })}
              </React.Fragment>
            );
          })}
          <div styleName="load-more">
            {hasNextPage && !isFetchingNextPage && (
              <button onClick={() => fetchNextPage()}>
                Load more comments
              </button>
            )}
            {isFetchingNextPage && (
              <span styleName="loader">
                <br />
                <Loader color="#ec2227" />
              </span>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const CommentBody = ({
  story,
  item,
  member,
  userSubscription,
  reply,
  showReplyfor,
  setShowReplyFor,
  userDetails
}) => {
  const queryClient = useQueryClient();
  const { data, hasNextPage, status, refetch } = useInfiniteQuery(
    ["replies", { commentID: item.id }],
    fetchReplies,
    {
      getNextPageParam: (lastPage, pages) => {
        if (hasNextPage !== false) {
          return lastPage.data.next_page;
        } else {
          return false;
        }
      },
      enabled: false
    }
  );

  const [isReply, setIsReply] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const deleteMutation = useMutation(deleteComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(["comments"]);
    }
  });

  const likeMutation = useMutation(updateLike, {
    onSuccess: () => {
      queryClient.invalidateQueries(["comments"]);
    }
  });

  const handleDelete = id => {
    item.is_deleted = 1;
    deleteMutation.mutate({ id: id });
    // deleteComment(id)
  };

  const handleLike = () => {
    likeMutation.mutate({ id: item.id });
    item.liked_count = item.is_liked
      ? item.liked_count - 1
      : item.liked_count + 1;
    item.is_liked = !item.is_liked;
  };
  const handleReport = () => {
    reportComment(item.id);
  };

  const handleFeature = id => {
    updateFeature(item.id, item.is_featured);
    item.is_featured = !item.is_featured;
  };

  const handleReplyPagination = () => {
    setShowReplyFor(item.id);
    refetch();
  };

  return (
    !item.is_deleted && (
      <div
        styleName={`comment-wrapper ${
          item.is_featured ? "feature" : item.is_author ? "author" : ""
        }`}
        key={item.id}
      >
        {((!item.is_deleted &&
          !isEdit &&
          member &&
          item.user_id === member.id) ||
          (userDetails && userDetails.is_author === 1)) && (
          <Dropdown
            item={item}
            member={member}
            handleDelete={handleDelete}
            handleReport={handleReport}
            handleFeature={handleFeature}
            setIsEdit={setIsEdit}
            userDetails={userDetails}
            key={item.id}
          />
        )}

        {!item.is_deleted && !isEdit && (
          <div styleName="comment-body">
            {item.avatar_url ? (
              <img
                src={item.avatar_url}
                styleName="comment-avatar"
                style={!item.avatar_url || isValid ? { padding: "8px" } : null}
                // onError={event => {
                //   event.target.src = assetify(AvatarIcon);
                //   setIsValid(true);
                // }}
                alt="user image"
                aria-label="user image"
              />
            ) : (
              <FaUser styleName="comment-avatar" />
            )}
            {/* <img
              src={
                item.avatar_url
                  ? assetify(item.avatar_url)
                  : assetify(AvatarIcon)
              }
              styleName="comment-avatar"
              style={!item.avatar_url || isValid ? { padding: "8px" } : null}
              onError={event => {
                event.target.src = assetify(AvatarIcon);
                setIsValid(true);
              }}
              alt="user image"
              aria-label="user image"
            /> */}
            <div styleName="text-wrapper">
              <h4 styleName="comment-name">
                {item.user_name || `NL User ${item.user_id}`}
                {item.subscription_details &&
                  item.subscription_details.length > 0 && (
                    <SubAvatar
                      groupId={
                        item.subscription_details[0].subscription_group_id
                      }
                    />
                  )}
                <small styleName="comment-time">
                  {getTimestamp(new Date(item.created_at), new Date())}
                </small>
              </h4>

              {item.is_author === 1 && (
                <>
                  <Chip background="#ec2227" color="white">
                    NL Team
                  </Chip>
                  &nbsp;
                </>
              )}
              {item.is_featured === 1 && (
                <Chip background="#663399" color="white">
                  Featured{" "}
                </Chip>
              )}
              {/* {item.user_email && includes("newslaundry") && <Chip>NL Team</Chip>} */}

              <p styleName="comment-text">{item.comments}</p>
            </div>
          </div>
        )}

        {isEdit && (
          <InputForm
            member={member}
            activeSubscription={userSubscription}
            commentID={item.id}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editText={item.comments}
            story={story}
            item={item}
          />
        )}

        {!item.is_deleted && !isEdit && (
          <div styleName="actions">
            <p
              styleName="action"
              onClick={() => {
                handleLike();
              }}
            >
              {/* <img
                styleName="action-logo"
                aria-label="like button"
                alt="like button"
                src={assetify(item.is_liked ? LikedIcon : LikeIcon)}
              /> */}
              {item.liked_count}
              {item.is_liked ? (
                <MdThumbUp styleName="action-logo" aria-hidden="true" />
              ) : (
                <MdOutlineThumbUp styleName="action-logo" aria-hidden="true" />
              )}
            </p>
            {!reply && (
              <small
                styleName="action"
                onClick={() => {
                  setIsReply(!isReply);
                }}
                aria-label="reply button"
              >
                {/* <img styleName="action-logo" src={assetify(ReplyIcon)} /> */}
                <MdReply styleName="action-logo" aria-hidden="true" />
                <b>Reply</b>
              </small>
            )}
          </div>
        )}

        {isReply && (
          <div styleName="reply-input-wrapper">
            <InputForm
              item={item}
              story={story}
              commentID={item.id}
              isReply={true}
              setShowReplyFor={setShowReplyFor}
              setIsReply={setIsReply}
              member={member}
              activeSubscription={userSubscription}
              key={item.id}
            />
          </div>
        )}

        <div styleName="reply-wrapper">
          {/* {
						showReplyfor!==item.id && item.replies && item.replies.map(reply=>{
	
							return(
								!reply.is_deleted && <CommentBody 
										item={reply}  
										member = { member }
										userSubscription={userSubscription}
										key={reply.id}
										reply={true}
										userDetails={userDetails}
									/>
							)
						})
					} */}
          {data && showReplyfor === item.id && (
            <React.Fragment>
              {data.pages.map((page, i) => {
                return (
                  <React.Fragment key={i}>
                    {page.data.items.map(reply => {
                      return (
                        !reply.is_deleted && (
                          <CommentBody
                            item={reply}
                            member={member}
                            userSubscription={userSubscription}
                            key={reply.id}
                            reply={true}
                          />
                        )
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}

          {item.reply_count > 0 && showReplyfor !== item.id && (
            <div styleName="load-more" onClick={handleReplyPagination}>
              <span>
                Show {item.reply_count > 1 && item.reply_count}{" "}
                {item.reply_count > 1 ? "replies" : "reply"}
                <i className="material-icons-outlined">expand_more</i>
              </span>
            </div>
          )}
          {showReplyfor === item.id && status !== "loading" && (
            <div styleName="load-more" onClick={() => setShowReplyFor(null)}>
              <span>
                Hide replies
                <i className="material-icons-outlined">expand_less</i>
              </span>
            </div>
          )}
          {status === "loading" && (
            <span styleName="loader">
              <br />
              <Loader color="#ec2227" />
            </span>
          )}
        </div>
      </div>
    )
  );
};

/*
Input form component 
*/
const InputForm = props => {
  const [text, setText] = useState(props.editText || "");
  const [error, setError] = useState(null);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const queryClient = useQueryClient();
  const textAreaRef = React.useRef(null);

  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const params = parseUrl(currentPath);
  const callbackUrl = get(
    params,
    ["query", "callback-url"],
    global.location && global.location.origin
  );
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    global && global.location && global.location.href
  );
  const url = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  const redirectToLogin = () => {
    window.location = url;
  };

  const postMutation = useMutation(postComment, {
    onSuccess: (data, variables) => {
      setText("");
      props.setIsReply && props.setIsReply(false);
      queryClient.invalidateQueries(["comments"]);
      !props.setIsReply &&
        props.refetch({ refetchPage: (page, index) => index === 0 });
    }
  });

  const editMutation = useMutation(editComment, {
    onSuccess: data => {
      queryClient.invalidateQueries(["comments"]);
      setText("");
      props.setIsEdit(false);
    }
  });

  // Handle edit cancellation on esc key press
  const handleKeyDown = e => {
    if (e.keyCode === 27 && props.isEdit) {
      props.setIsEdit(false);
    }
  };

  const handleSubmit = async (e, text, setText) => {
    e.preventDefault();

    if (!props.activeSubscription) {
      setShowSubscribePopup(true);
      return;
    } else if (text.trim().length <= 2 || text.trim() === "") {
      setError("Oops! Text must be longer");
      return;
    }
    if (props.isReply) {
      props.setShowReplyFor(null);
      props.item.reply_count = props.item.reply_count + 1;
    }

    postMutation.mutate({
      inputText: text,
      storyID: props.story.id,
      parentID: props.isReply ? props.commentID : null
    });
  };

  const handleEdit = async (e, text) => {
    e.preventDefault();
    props.item.comments = text;
    editMutation.mutate({ inputText: text.trim(), id: props.commentID });
  };

  return (
    <React.Fragment>
      <form
        styleName="upper"
        onSubmit={e =>
          props.isEdit ? handleEdit(e, text) : handleSubmit(e, text, setText)
        }
      >
        {/* <img
          src={
            props.member && props.member["avatar-url"]
              ? assetify(props.member["avatar-url"])
              : assetify(AvatarIcon)
          }
          styleName="comment-input-avatar"
          alt
          style={
            props.member && props.member["avatar-url"]
              ? null
              : { padding: "8px" }
          }
          aria-hidden="true"
        /> */}

        <textarea
          rows={1}
          placeholder="Enter feedback / question / suggestion"
          onChange={e => {
            setText(e.target.value);
            const target = e.target;
            textAreaRef.current.style.height = "40px";
            textAreaRef.current.style.height = `${target.scrollHeight}px`;
            // this.parentNode.dataset.replicatedValue = this.value
          }}
          onKeyDown={handleKeyDown}
          ref={textAreaRef}
          value={text}
          styleName="inp"
        >
          {props.isEdit && <button>cancel</button>}
        </textarea>

        {/* <input type="file" id="actual-input" name="filename" hidden/>
				<label for="actual-input" styleName="upload">
					<img src="https://img.icons8.com/material-outlined/48/000000/image.png"/>
				</label> */}

        <button styleName="btn" aria-label="Click to post your comment">
          {!postMutation.isLoading && !editMutation.isLoading ? (
            <i className="material-icons-outlined">
              {props.activeSubscription ? "send" : "lock"}
            </i>
          ) : (
            <Loader color="white" />
          )}
          {/* {isSubscriber?
						<img src={assetify(LockIcon)} onClick={()=>alert('Oops! \nYou need to be a subscriber to use NL comments')}/>
					:
					} 
					*/}
        </button>
      </form>
      {error && <small styleName="err-msg">{error}</small>}
      {!(props.subscription || props.member) && (
        <div styleName="prompt-text">
          {!props.activeSubscription && (
            <small styleName="err-cta">
              We take comments from subscribers only! &nbsp;
              <a>
                <Link href="/subscription?ref=comment">Subscribe now</Link>
              </a>{" "}
              to post comments!
            </small>
          )}
          &nbsp;
          {!props.member && (
            <small styleName="err-cta">
              <br />
              Already a subscriber? &nbsp;<a onClick={redirectToLogin}>Login</a>
            </small>
          )}
        </div>
      )}

      {showSubscribePopup && (
        <SubscribePopup
          showModal={showSubscribePopup}
          setShowModal={setShowSubscribePopup}
        />
      )}
    </React.Fragment>
  );
};

/*
Dropdown menu component 
*/
const Dropdown = props => {
  const [isDropdown, setIsDropdown] = useState(false);
  const dropdownMenu = React.useRef(null);

  // Handle drop down state
  React.useEffect(() => {
    // Function for click event
    function handleOutsideClick(event) {
      if (
        dropdownMenu.current &&
        !dropdownMenu.current.contains(event.target)
      ) {
        setIsDropdown(false);
      }
    }

    // Adding click event listener
    document.addEventListener("click", handleOutsideClick);
  }, [dropdownMenu]);

  return (
    <span styleName="dropdown" ref={dropdownMenu}>
      {/* <img
        styleName="drop-btn"
        src={assetify(MenuIcon)}
        onClick={() => setIsDropdown(true)}
      /> */}
      <button
        styleName="drop-btn"
        onClick={() => setIsDropdown(true)}
        aria-label="comments option dropdown"
      >
        <MdMoreVert aria-hidden="true" />
      </button>
      {isDropdown && props.item && props.member && (
        <div styleName="dropdown-content">
          {props.item.user_id === props.member.id && (
            <a
              onClick={() => props.setIsEdit(true)}
              aria-label="edit comment button"
            >
              {/* <img src={assetify(EditIcon)} /> */}
              <MdEdit aria-hidden="true" />
              <span>Edit</span>
            </a>
          )}

          {/* <a onClick={handleReport}>
							<img  src={assetify(ReportIcon)}/>
							<span>Report</span>
						</a> */}

          {props.item.user_id === props.member.id && (
            <a
              onClick={() => props.handleDelete(props.item.id)}
              aria-label="click button to delete"
            >
              {/* <img src={assetify(DeleteIcon)} /> */}
              <MdDelete aria-hidden="true" />
              <span>Delete</span>
            </a>
          )}
          {props.userDetails && props.userDetails.is_author === 1 && (
            <a
              onClick={() => {
                props.handleFeature();
                setIsDropdown(false);
              }}
            >
              <MdOutlineReportProblem aria-hidden="true" />
              {/* <img src={assetify(ReportIcon)} /> */}
              <span>Feature</span>
            </a>
          )}
        </div>
      )}
    </span>
  );
};

CommentWidgetBase.propTypes = {
  story: PropTypes.object,
  member: PropTypes.object,
  activeSubscription: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    member: get(state, ["member"], null),
    isSubscriber: get(state, ["isSubscribedUser", "userHasSubscription"], null),
    activeSubscription: get(state, ["fetchSubscription", "isActive"], null)
  };
};
export const CommentWidget = connect(mapStateToProps, {})(CommentWidgetBase);
