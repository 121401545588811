import React from "react";
import PropTypes from "prop-types";

export const BlockQuoteIcon = ({ width = "28", height = "28" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28">
      <path
        fill="#EC2227"
        d="M6.498 12.918v2.363h5.63V28H0V12.918c0-4.8 1.317-8.364 3.914-10.593C5.711.782 7.985 0 10.674 0v6.813c-1.46 0-4.176 0-4.176 6.105zm20.048-6.105V0c-2.689 0-4.963.782-6.76 2.325-2.597 2.23-3.914 5.793-3.914 10.593V28H28V15.281h-5.631v-2.363c0-6.105 2.717-6.105 4.177-6.105z"
      />
    </svg>
  );
};

BlockQuoteIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
