import React, { useState } from "react";
import { useDispatch, batch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import headset from "../../../../assets/images/headset.svg";
import { parseUrl } from "query-string";

import { AmazonPollyPopup } from "./amazon-polly-popup"

import "./amazon-polly.m.css";

export const AmazonPolly = ({ story = {}, customClass = "" }) => {

  const dispatch = useDispatch();
  const storyDataKeys = ["slug", "summary", "headline", "access", "story-audio", ];
  const storyData = storyDataKeys.reduce((acc, key) => ({ ...acc, [key]: story[key] }), {});
  const audioS3Key = get(story, ["story-audio", "s3-key"], "");
  const member = useSelector(state=>get(state, ["member"], null))
  const authHost= useSelector(state => get(state,["qt", "config", "publisher", "publisher", "auth_host"], null));
	const currentPath = useSelector(state => get(state, ["qt", "currentPath"], ""));
	const params = parseUrl(currentPath);
	const callbackUrl = get(params, ["query", "callback-url"], global.location && global.location.origin);
	const redirectUrl = get(params, ["query", "redirect-url"], global && global.location && global.location.href);
	const url =`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
	const redirectToLogin = () => {
		window.location=url
	};
  const activeSubscription = useSelector(state =>get(state, ["isSubscribedUser", "userSubscription"], null)&& get(state, ["isSubscribedUser", "userSubscription"], null).filter(item=>item.active===true).length>0?get(state, ["isSubscribedUser", "userSubscription"], null).filter(item=>item.active===true):null);
  const [error, setError] = useState(false)
  
  const onClickHandler = () => {
    if(activeSubscription){
      setError(false)
      batch(() => {
        dispatch({ type: "UPDATE_AMAZON_POLLY_DATA", payload: { storyData } });
        dispatch({ type: "OPEN_AMAZON_POLLY" });
      });
    }
    else {
      setError(true) 
    }
  };

  if (!audioS3Key) return null;

  return (
    <div styleName="amazon-polly-wrapper">
      <div
        styleName="amazon-polly-text-wrapper"
        className={customClass}
        onClick={onClickHandler}
      >
        <div styleName="amazon-polly-headset-wrapper">
          <img src={assetify(headset)} alt="amazone polly" className="amazon-polly-headset" /> 
        </div>
        <p styleName="amazon-polly-text">Listen to story</p>
      </div>
      {error && 
        <React.Fragment>
          <span> <a href = "/subscription">Subscribe</a> to listen to this story.</span>&nbsp;
          {!member && <span>Already a subscriber? <a onClick={redirectToLogin}>Login</a></span> }
        </React.Fragment>
      }
      <AmazonPollyPopup storyData={storyData}/>
    </div>
  );
};

AmazonPolly.propTypes = {
  story: PropTypes.object,
  customClass: PropTypes.string
};
