/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { StoryElement } from "@quintype/components";
import { AlsoRead } from "../../atoms/also-read";
import { BigFact } from "../../atoms/big-fact";
import { BlockQuote } from "../../atoms/block-quote";
import { Blurb } from "../../atoms/blurb";
import { VideoPlaylist } from "../../collection-templates/video-playlist";
import { PaywallImageElement } from "../../atoms/paywall-image";
import { PaywallImageGallery } from "../../atoms/paywall-image-gallery";
import { QuestionAnswer } from "../../atoms/question-answer";
import { QuoteElement } from "../../atoms/quote";
import { Summary } from "../../atoms/summary";
import { ImageElement } from "../../atoms/image";

import "./photo-paywall.m.css";

const renderVideoPlaylist = videoId => {
  return (
    <Fragment>
      <VideoPlaylist collection={null} youtubePlaylistId={videoId} />
    </Fragment>
  );
};

const CalculatedImage = ({ toShowPaywall, element, index }) => {
  if (toShowPaywall) {
    return <PaywallImageElement element={element} key={index} />;
  }

  return <ImageElement element={element} key={index} />;
};

const CalculatedImageGallery = ({ toShowPaywall, element }) => {
  if (toShowPaywall) {
    return (
      <PaywallImageGallery element={element} toShowPaywall={toShowPaywall} />
    );
  }

  return (
    <PaywallImageGallery element={element} toShowPaywall={toShowPaywall} />
  );
};

function StoryCardElement({
  element,
  index,
  card,
  subType,
  story,
  toShowPaywall
}) {
  let count = 0;
  let video = null;

  if (subType === "also-read") {
    count++;
  }

  if (
    card &&
    card.metadata.attributes &&
    card.metadata.attributes.youtubeplaylist
  ) {
    video = card.metadata.attributes.youtubeplaylist[0];
  }

  switch (subType) {
    case "summary":
      return <Summary element={element} key={index} />;
    // case "jsembed":
    //   return getJSEmbedElement(true, element, index);
    case "quote":
      return <QuoteElement element={element} key={index} />;
    case "blockquote":
      return <BlockQuote element={element} key={index} />;
    case "blurb":
      return <Blurb element={element} key={index} />;
    case "bigfact":
      return <BigFact element={element} key={index} />;
    case "also-read":
      return (
        <AlsoRead story={story} element={element} key={index} count={count} />
      );
    case "question":
      return <QuestionAnswer element={element} key={index} type="question" />;
    case "answer":
      return <QuestionAnswer element={element} key={index} type="answer" />;
    case "q-and-a":
      return <QuestionAnswer element={element} key={index} type="q-and-a" />;
    case "image":
      return (
        <CalculatedImage
          element={element}
          key={index}
          toShowPaywall={toShowPaywall}
        />
      );
    case "image-gallery":
      return (
        <CalculatedImageGallery
          element={element}
          toShowPaywall={toShowPaywall}
        />
      );
    default:
      if (video) {
        return (
          <>
            <StoryElement element={element} key={index} />
            {renderVideoPlaylist(video)}
          </>
        );
      } else {
        return <StoryElement element={element} key={index}></StoryElement>;
      }
  }
}

const PhotoPaywall = ({ story, member, storyCards, hasSubscription }) => {
  const toShowPaywall = !member || !hasSubscription;

  return (
    <div styleName="story-cards-wrapper">
      {storyCards.map((card, i) => {
        const storyElements = card["story-elements"];

        return (
          <Fragment key={card.id}>
            {storyElements.map(elem => {
              const subType = elem.subtype || elem.type;

              return (
                <StoryCardElement
                  key={elem.id}
                  element={elem}
                  index={i}
                  card={card}
                  story={story}
                  subType={subType}
                  toShowPaywall={toShowPaywall}
                />
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
};

export { PhotoPaywall };
