/* eslint-disable max-len */
export const subscriptionAppeals = [
  {
    name: "ground reports",
    text: "This is not an ad. An ad would never fund a story like this. Would you? Pay for free, fair, robust journalism that isn’t influenced by advertisers.",
    hindiText:
      "यह एक विज्ञापन नहीं है. कोई विज्ञापन ऐसी रिपोर्ट को फंड नहीं कर सकता, लेकिन आप कर सकते हैं, क्या आप करेंगे? विज्ञापनदाताओं के दबाव में न आने वाली आज़ाद व ठोस पत्रकारिता के लिए अपना योगदान दें.",
    imageURL:
      "https://images.assettype.com/newslaundry/2022-05/7f7922fa-a1a3-474e-9349-fe65ac7b25f7/NL_Subscription_5.png"
  },
  {
    name: "podcasts",
    text: "Producing a quality journalism podcast is an expensive enterprise. Subscribe now to help us bring you more award-winning podcasts.",
    hindiText:
      "एक अच्छे स्तर की प्रमाणिक पॉडकास्ट बनाना, परिश्रम के साथ-साथ महंगा काम भी है. हम आपके लिए और कई अवार्ड जीतने वाली और रोचक पॉडकास्ट ला सकें, इसमें हमारा सहयोग करें. न्यूज़लॉन्ड्री को सब्सक्राइब करें.",
    imageURL:
      "https://images.assettype.com/newslaundry/2022-06/ad6a561d-1c0c-46a0-8397-0d29f861afda/show.png"
  },
  {
    name: "stories on media acquisitions",
    text: "Supporting independent media has never been more important. We need you to step up. For we depend on you for support, not on government or corporate advertisers.",
    hindiText:
      "स्वतंत्र मीडिया का समर्थन और सहयोग करना आज बहुत जरूरी हो गया है. हमें आपके सहयोग की जरूरत है. क्योंकि हम सरकारों या कॉरपोरेट विज्ञापन दाताओं के सहयोग पर नहीं, बल्कि आपके सहयोग पर निर्भर हैं.",
    imageURL:
      "https://images.assettype.com/newslaundry/2022-08/fb9ec400-b15f-4aee-8c58-0a15d19d147b/NL_HOMEPAGE_1_copy.png"
  },
  {
    name: "independent media",
    text: "The media must be free and fair, uninfluenced by corporate or state interests. That's why you, the public, need to pay to keep news free.",
    hindiText:
      "मीडिया को कॉरपोरेट या सत्ता के हितों से अप्रभावित, आजाद और निष्पक्ष होना चाहिए. इसीलिए आपको, हमारी जनता को, पत्रकारिता को आजाद रखने के लिए खर्च करने की आवश्यकता है. आज ही सब्सक्राइब करें.",
    imageURL:
      "https://images.assettype.com/newslaundry/2022-08/938f8262-4fed-42ba-a67f-e63ab4b261ec/NL_Subscription_1_copy.png"
  },
  {
    name: "so sketchy",
    text: "We want to revive the fading tradition of political commentary through cartoon, a medium that today more than ever needs to be rewarded and not censored. So Sketchy is a step in that direction. We want to do much more. We are counting on your support.",
    hindiText: "",
    imageURL:
      "https://images.assettype.com/newslaundry/2022-08/fb9ec400-b15f-4aee-8c58-0a15d19d147b/NL_HOMEPAGE_1_copy.png"
  },
  {
    name: "joint campaign subscription",
    text: "We are joining hands with The News Minute for the upcoming assembly elections in five states. Over the last ten years, you, our readers, have made ground reportage possible. Its now more important than ever to have facts from the ground. Help us get boots on the ground that will bring you reports, interviews and shows.",
    hindiText:
      "We are joining hands with The News Minute for the upcoming assembly elections in five states. Over the last ten years, you, our readers, have made ground reportage possible. Its now more important than ever to have facts from the ground. Help us get boots on the ground that will bring you reports, interviews and shows.",
    imageURL:
      "https://images.assettype.com/newslaundry/2023-10/11139650-deef-4da6-ace5-048535437a8d/joint_subscription_png.png"
  }
];
