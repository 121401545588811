/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { number, array, object } from "prop-types";
import assetify from "@quintype/framework/assetify";

import LightBox from "../lightbox";
import Carousel from "./carousel";
import Arrow from "../../../../assets/images/chevron-right-solid.svg";
import { ImageElement } from "../../atoms/image";

import "./glide-slider.m.css";

class GlideSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: this.props.index || 1,
      showPopup: false
    };
  }

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  next = () => {
    this.state.carouselIndex !== this.props.totalItems
      ? this.setState({ carouselIndex: this.state.carouselIndex + 1 })
      : this.setState({ carouselIndex: 1 });
  };

  previous = () => {
    this.state.carouselIndex === 1
      ? this.setState({ carouselIndex: this.props.totalItems })
      : this.setState({ carouselIndex: this.state.carouselIndex - 1 });
  };

  render() {
    const { story, slideshowItems, totalItems } = this.props;
    const { carouselIndex, showPopup } = this.state;

    if (slideshowItems.length < 1) {
      return null;
    }
    return (
      <div styleName="carousel-container" className="carousel-container">
        <Carousel
          carouselName={`carousel-${story.id}`}
          options={{
            type: "slider",
            perView: 1,
            animationDuration: 600,
            animationTimingFunc: "linear",
            gap: 0,
            breakpoints: { 480: { perView: 1, peek: { before: 0, after: 0 } } }
          }}
          totalItems={totalItems}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={this.previous} className="slider-btn">
              <img
                src={`${assetify(Arrow)}`}
                alt="notification"
                styleName="arrow-left"
              />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={this.next} className="slider-btn">
              <img src={`${assetify(Arrow)}`} alt="notification" />
            </button>
          )}
        >
          {this.props.index
            ? [
                <div
                  styleName="image-element"
                  className="image-element"
                  key={slideshowItems[carouselIndex - 1].id}
                >
                  <ImageElement
                    element={slideshowItems[carouselIndex - 1]}
                    key={slideshowItems[carouselIndex - 1].id}
                  />
                </div>
              ]
            : [
                <div
                  styleName="image-element"
                  className="image-element"
                  onClick={this.togglePopup}
                  key={slideshowItems[carouselIndex - 1].id}
                >
                  <ImageElement
                    element={slideshowItems[carouselIndex - 1]}
                    key={slideshowItems[carouselIndex - 1].id}
                  />
                </div>
              ]}
        </Carousel>
        {showPopup && (
          <LightBox
            story={story}
            slideshowItems={slideshowItems}
            element={slideshowItems[carouselIndex - 1]}
            closePopup={this.togglePopup}
            index={this.state.carouselIndex}
          />
        )}
      </div>
    );
  }
}

GlideSlider.propTypes = {
  totalItems: number,
  slideshowItems: array,
  story: object
};

export default GlideSlider;
