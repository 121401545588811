/* eslint-disable max-len */
import React from "react";

import { Link } from "@quintype/components";
import PropType from "prop-types";
import "./election-fund-appeal.m.css";
import {
  COMPRESS_PARAMS_SMALL,
  PEOPLE_CARRYING_NL_CLIP_UPHILL
} from "../../../image-constants";

const ElectionFundAppeal = story => {
  const [isHindi, setIsHindi] = React.useState(false);
  // console.log({story});
  // const isStoryPublic = checkIfStoryPublic(story);

  // const member = useSelector(state => {
  //   return get(state, ["member"], null);
  // });
  // const hasActiveSubscription = useSelector(state =>
  //   get(state, ["fetchSubscription", "isActive"], null)
  // );

  React.useEffect(() => {
    setIsHindi(global.location.host.includes("hindi."));
  }, []);

  const DescriptionText = () => {
    return (
      <span>
        General elections are around the corner, and Newslaundry and The News
        Minute have ambitious plans together to focus on the issues that really
        matter to the voter. From political funding to battleground states,
        media coverage to 10 years of Modi, choose a project you would like to
        support and power our journalism.
        <br />
        <br />
        Ground reportage is central to public interest journalism. Only readers
        like you can make it possible. Will you?
      </span>
    );
  };

  if (!isHindi) {
    return (
      <div styleName="div-container">
        <div styleName="wrapper" className="full-width">
          <div styleName="left-container">
            <img
              src={`${PEOPLE_CARRYING_NL_CLIP_UPHILL}${COMPRESS_PARAMS_SMALL}`}
              alt="subscription-appeal-image"
              styleName="subscription-appeal-image"
            />
          </div>
          <div styleName="middle-container">
            <div styleName="row-wrapper">
              <h1 styleName="text-container">
                <span>Power NL-TNM Election Fund</span>
              </h1>
            </div>
            <div styleName="appeal-wrapper">
              <p styleName="appeal-text">
                <DescriptionText />
              </p>
              <Link href={"/subscription"} styleName="redirect-link">
                Support now
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
  // }
};

ElectionFundAppeal.propTypes = {
  story: PropType.object
};

export { ElectionFundAppeal };
